import React from 'react';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import SuccessOutlinedIcon from '@material-ui/icons/CheckCircleOutline';
import { user as userActions } from '../../actions';

import makeClasses from './styles';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import Markdown from 'react-markdown';

const Notification = ({ data: { id, enrichedTitle, canBeClosed = false, level, summary } }) => {
  const classes = makeClasses();
  const dispatch = useDispatch();

  const goToNotificationDetails = () => {
    if (canBeClosed) {
      dispatch(userActions.closeNotification(id))
    }
    dispatch(push(`/notificaciones/${id}`));
  };

  const getIcon = () => {
    if (level === 'INFO') {
      return <InfoOutlinedIcon solor="inherit" className={classes.icon} />;
    }
    if (level === 'WARNING') {
      return <WarningOutlinedIcon solor="inherit" className={classes.icon} />;
    }
    if (level === 'SUCCESS') {
      return <SuccessOutlinedIcon solor="inherit" className={classes.icon} />;
    }
    if (level === 'ERROR') {
      return <ErrorOutlinedIcon solor="inherit" className={classes.icon} />;
    }
    return null;
  };

  const getClassName = () => {
    if (level === 'INFO') {
      return classes.rootInfo;
    }
    if (level === 'WARNING') {
      return classes.rootWarning;
    }
    if (level === 'SUCCESS') {
      return classes.rootSuccess;
    }
    if (level === 'ERROR') {
      return classes.rootError;
    }
    return null;
  };

  const titleCropped = enrichedTitle.length > 50 ? `${enrichedTitle.substring(0, 50)}...` : enrichedTitle;
  const summaryCropped = summary.length > 90 ? `${summary.substring(0, 90)}...` : summary;

  return (
    <div className={clsx(classes.root, getClassName())} onClick={goToNotificationDetails}>
      {/* {getIcon()} */}
      <div className={classes.contentContainer}>
        <Markdown className={classes.contentTitle}>{titleCropped}</Markdown>
        <Markdown className={classes.contentSummary}>{summaryCropped}</Markdown>
      </div>
    </div>
  );
};

export default Notification;
