import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BoxSeparator,
  ForbiddenSection,
  Page,
  TitleBox,
  ContentBox,
  StatusPill,
} from "../../../../../components";
import ROUTES from "../../../../../routes";
import { Language } from "../../../../../utils";
import { business as businessActions } from "../../../../../actions";
import { goBack } from "connected-react-router";
import makeClasses from "./styles";
import { Grid } from "@material-ui/core";

import { Policies } from "../../../../../utils/Policies";
import moment from "moment";

const ProvidersSubscriptionsManagePromotionDetailsScreen = ({ match }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const promotionTraceId = match && match.params ? match.params.traceId : null;

  const [promotion, setPromotion] = useState(null);
  const [forbiddenSection, setForbbidenSection] = useState(false);

  useEffect(() => {
    if (!policies || !policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_PROMOTIONS)) {
      setForbbidenSection(true);
    }
  }, [policies]);

  useEffect(() => {
    if (!forbiddenSection) {
      loadData();
    }
  }, [forbiddenSection]);

  const loadData = () => {
    if (promotionTraceId) {
      dispatch(
        businessActions.getProvidersSubscriptionsPromotion(
          promotionTraceId,
          (data) => {
            setPromotion(data);
          }
        )
      );
    }
  };

  const handleOnGoBackClick = () => {
    dispatch(goBack());
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get(
          "ProvidersSubscriptionsManagePromotionDetails.Title"
        )}
        withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get(
        "ProvidersSubscriptionsManagePromotionDetails.Title"
      )}
      withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
    >
      <TitleBox
        title={i18n.get(
          "ProvidersSubscriptionsManagePromotionDetails.BoxTitle"
        )}
        back
        backTitle={i18n.get(
          "ProvidersSubscriptionsManagePromotionDetails.BackButton"
        )}
        onBackClick={handleOnGoBackClick}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      {promotion ? (
        <ContentBox
          title={i18n.get(
            "ProvidersSubscriptionsManagePromotionDetails.SubscriptionBoxDataLabel"
          )}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>
                  {i18n.get(
                    "ProvidersSubscriptionsManagePromotionDetails.PromotionBoxNameLabel"
                  )}
                  :
                </div>

                {promotion.name || "-"}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>
                  {i18n.get(
                    "ProvidersSubscriptionsManagePromotionDetails.PromotionBoxPlanLabel"
                  )}
                  :
                </div>
                {promotion.subscriptionPlan
                  ? promotion.subscriptionPlan?.code ? 
                    `${promotion.subscriptionPlan.name} - ${promotion.subscriptionPlan.code}`
                    : promotion.subscriptionPlan?.name
                  : "-"}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>
                  {i18n.get(
                    "ProvidersSubscriptionsManagePromotionDetails.PromotionBoxTypeLabel"
                  )}
                  :
                </div>
                {promotion.subscriptionPlan
                  ? promotion.promotionPeriod?.percentage > 0
                    ? i18n.get("ProvidersSubscriptionsManagePromotionDetails.PromotionBoxTypeValue_Period")
                    : promotion.promotionPostpone?.amountDays > 0
                      ? i18n.get("ProvidersSubscriptionsManagePromotionDetails.PromotionBoxTypeValue_Postpone")
                      : '-'
                  : "-"}
              </div>
            </Grid>
            {promotion.promotionPeriod?.percentage > 0 ||
            promotion.promotionPostpone?.amountDays > 0 ? (
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {promotion.promotionPeriod?.percentage > 0
                      ? i18n.get(
                          "ProvidersSubscriptionsManagePromotionDetails.PromotionBoxTypePeriodLabel"
                        )
                      : i18n.get(
                          "ProvidersSubscriptionsManagePromotionDetails.PromotionBoxTypePostponeLabel"
                        )
                    }
                    :
                  </div>
                  {promotion.promotionPeriod?.percentage > 0
                    ? `${promotion.promotionPeriod?.percentage}%`
                    : promotion.promotionPostpone?.amountDays}
                </div>
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>
                  {i18n.get(
                    "ProvidersSubscriptionsManagePromotionDetails.PromotionBoxAppliesLabel"
                  )}
                  :
                </div>
                  {promotion.appliesTo ? i18n.get(
                    `ProvidersSubscriptionsManagePromotionDetails.AppliesTo_${promotion.appliesTo}`
                  ) : '-'}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>
                  {i18n.get(
                    "ProvidersSubscriptionsManagePromotionDetails.PromotionBoxStartDateLabel"
                  )}
                  :
                </div>
                {moment(promotion.startDate).format(i18n.get("DateFormat")) ||
                  "-"}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>
                  {i18n.get(
                    "ProvidersSubscriptionsManagePromotionDetails.PromotionBoxEndDateLabel"
                  )}
                  :
                </div>
                {moment(promotion.endDate).format(i18n.get("DateFormat")) ||
                  "-"}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>
                  {" "}
                  {i18n.get(
                    "ProvidersSubscriptionsManagePromotionDetails.PromotionBoxStatusLabel"
                  )}
                  :
                </div>
                {promotion.promotionStatus ? (
                  <StatusPill data={promotion.promotionStatus} female />
                ) : (
                  "-"
                )}
              </div>
            </Grid>
          </Grid>
        </ContentBox>
      ) : null}
    </Page>
  );
};

ProvidersSubscriptionsManagePromotionDetailsScreen.id =
  "com.Handy.ProvidersSubscriptionsManagePromotionDetails";

export default ProvidersSubscriptionsManagePromotionDetailsScreen;
