import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push, replace } from 'connected-react-router';
import moment from 'moment';
import qs from 'query-string';
import _ from 'lodash';
import { Pie, Line } from 'react-chartjs-2';

import ROUTES from '../../routes';
import {
  Page,
  OperationsTable,
  BranchesSelector,
  FiltersDateSelector,
  TitleBox,
  BoxSeparator,
  ContentBox,
  TitleContentBox,
  FiltersContainer,
  FiltersSeparator,
  EmptyMessage,
  FiltersSelector,
  FiltersText,
  ForbiddenSection,
} from '../../components';
import { getDateRangeForFilter, CUSTOM_FILTER } from '../../components/FiltersDateSelector';
import { business as businessActions } from '../../actions';
import { Colors, Utils, Language } from '../../utils';

import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import makeClasses from './styles';
import { Policies } from '../../utils/Policies';
import clsx from 'clsx';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { toISOString } from '../../utils/Utils';

const GRAPH_COLORS = [
  '#4F0BD9',
  '#F09330',
  '#00F026',
  '#08A321',
  '#A39A00',
  '#F0E318',
  '#F05B30',
  '#0CF0B1',
  '#5B438A',
  '#1844F0',
  '#F2B955',
  '#BD6200',
];

const FILTERS_OPTIONS = {
  MOVEMENT_TYPE: [
    {
      id: 1,
      name: 'Ventas',
    },
    {
      id: 2,
      name: 'Devoluciones',
    },
    {
      id: 3,
      name: 'Anulaciones',
    },
    {
      id: 5,
      name: 'Reversas',
    },
    {
      id: 11,
      name: 'Reversos administrativos',
    },
    {
      id: 10,
      name: 'Contracargos',
    },
  ],
  CURRENCY: [
    {
      id: 'UYU',
      name: 'UYU',
    },
    {
      id: 'USD',
      name: 'USD',
    },
  ],
  ACQUIRER: [
    {
      id: 'AMEX',
      name: 'Amex',
    },
    {
      id: 'ANDA',
      name: 'Anda',
    },
    {
      id: 'SISTARBANC',
      name: 'Bancos',
    },
    {
      id: 'CABAL',
      name: 'Cabal',
    },
    {
      id: 'CLUB_DEL_ESTE',
      name: 'Club Del Este',
    },
    {
      id: 'EDENRED',
      name: 'Edenred',
    },
    {
      id: 'MASTER',
      name: 'Master',
    },
    {
      id: 'OCA',
      name: 'Oca',
    },
    {
      id: 'PAGO_DESPUES',
      name: 'Pago Después',
    },
    {
      id: 'PASSCARD',
      name: 'PassCard',
    },
    {
      id: 'REDPAGOS',
      name: 'Redpagos',
    },
    {
      id: 'VISA',
      name: 'Visa',
    },
  ],
};

const ActivityScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const searchParams = qs.parse(history.location.search);
  let amountTimeoutHandler = useRef(null);

  const [ selectedBranch, setSelectedBranch ] = useState(parseInt(searchParams.bid) || 'all');
  const [ selectedPos, setSelectedPos ] = useState(
    searchParams.pid && searchParams.pid !== 'all' ? parseInt(searchParams.pid) : 'all'
  );
  const [ posFilterOptions, setPosFilterOptions ] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(searchParams.fid ? searchParams.fid === 'custom' ? searchParams.fid : parseInt(searchParams.fid) : 3);
  const [ selectedCustomFilter, setSelectedCustomFilter ] = useState(
    searchParams.fid === CUSTOM_FILTER ? { startDate: searchParams.fsd, endDate: searchParams.fed } : {}
  );
  const [ selectedAcquirer, setSelectedAcquirer ] = useState(searchParams.aid || 'all');
  const [ acquirerFilterOptions ] = useState(FILTERS_OPTIONS.ACQUIRER);
  const [ selectedCurrency, setSelectedCurrency ] = useState(searchParams.cid || 'all');
  const [ currencyFilterOptions ] = useState(FILTERS_OPTIONS.CURRENCY);
  const [ selectedMovementType, setSelectedMovementType ] = useState(searchParams.mid || 'all');
  const [ movementTypeFilterOptions ] = useState(FILTERS_OPTIONS.MOVEMENT_TYPE);
  const [ selectedAmount, setSelectedAmount ] = useState(searchParams.amount ? parseFloat(searchParams.amount) : '');
  const [ paginationPage, setPaginationPage ] = useState(searchParams.page ? parseInt(searchParams.page) : 1);
  const [ columnOrder, setColumnOrder ] = useState(
    searchParams.columnNumber ? parseInt(searchParams.columnNumber) : ''
  );
  const [ columnOrderType, setColumnOrderType ] = useState(searchParams.sortType ? searchParams.sortType : '');
  const [ forbiddenSection, setForbbidenSection ] = useState(false);
  const [ paginationPageSize ] = useState(15);
  const [ showAdvancedFilters, setShowAdvancedFilters ] = useState(false);
  const { policies, branches, activity, languageTexts } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    branches: state.business.branches || [],
    activity: state.business.activity || {},
    languageTexts: state.language.texts || {},
  }));
  const prevPaginationPage = useRef(null);
  const i18n = Language(languageTexts);
  const hasActivity = activity && activity.gridHelper && activity.gridHelper.rowsColumnAndSale.length > 0;
  const canExportActivity =
    policies && policies.includes(Policies.types.ACTIVITY) && policies.includes(Policies.types.SUPER_READ);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(
    () => {
      if (!policies || !policies.includes(Policies.types.ACTIVITY)) {
        setForbbidenSection(true);
      } else if (policies.includes(Policies.types.ACTIVITY)) {
        dispatch(
          businessActions.getPosAliases(posAliases => {
            setPosFilterOptions(posAliases);
          })
        );
      }
    },
    [ policies ]
  );

  useEffect(
    () => {
      if (!forbiddenSection) {
        if (paginationPage > 1 && prevPaginationPage.current === paginationPage) {
          _handleOnPaginationChange(1);
          prevPaginationPage.current = null;
        } else {
          loadData();
          prevPaginationPage.current = paginationPage;
        }
      }
    },
    [
      forbiddenSection,
      selectedBranch,
      selectedFilter,
      selectedCustomFilter,
      selectedPos,
      selectedAmount,
      selectedCurrency,
      selectedAcquirer,
      selectedMovementType,
      paginationPage,
      paginationPageSize,
      columnOrder,
      columnOrderType,
    ]
  );

  const loadData = () => {
    if (policies && policies.includes(Policies.types.ACTIVITY)) {
      const branchId = selectedBranch !== 'all' ? selectedBranch : null;
      const dateFilter = getDateRangeForFilter(
        selectedFilter,
        selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null
      );
      const posFilter = selectedPos !== 'all' ? posFilterOptions.find(p => p.networkIdentifier === selectedPos) : null;
      const startDate = toISOString(dateFilter.startDate);
      const endDate = toISOString(dateFilter.endDate);

      dispatch(
        businessActions.getActivity({
          branchId,
          start: startDate,
          end: endDate,
          page: paginationPage,
          count: paginationPageSize,
          columnOrder,
          columnOrderType,
          networkIdentifier: posFilter ? posFilter.networkIdentifier : null,
          networkKey: posFilter ? posFilter.networkKey : null,
          acquirer: selectedAcquirer && selectedAcquirer !== 'all' ? selectedAcquirer : null,
          currency: selectedCurrency && selectedCurrency !== 'all' ? selectedCurrency : null,
          movementType: selectedMovementType && selectedMovementType !== 'all' ? selectedMovementType : null,
          amount: selectedAmount || null,
        })
      );
    }
  };

  const _goToOperationDetails = operationId => e => {
    e.preventDefault();

    dispatch(push(`/operacion/${operationId}`));
  };

  const _handleOnBranchSelected = branch => {
    setSelectedBranch(branch);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.bid = branch;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnFilterSelected = filterType => filterValue => {
    const searchParams = qs.parse(history.location.search) || {};

    if (filterType === 'pos') {
      setSelectedPos(filterValue);
      searchParams.pid = filterValue;
    }
    if (filterType === 'acquirer') {
      setSelectedAcquirer(filterValue);
      searchParams.aid = filterValue;
    }
    if (filterType === 'currency') {
      setSelectedCurrency(filterValue);
      searchParams.cid = filterValue;
    }
    if (filterType === 'movementType') {
      setSelectedMovementType(filterValue);
      searchParams.mid = filterValue;
    }
    if (filterType === 'amount') {
      if (amountTimeoutHandler.current) {
        clearTimeout(amountTimeoutHandler.current);
        amountTimeoutHandler.current = null;
      }
      amountTimeoutHandler.current = setTimeout(() => {
        setSelectedAmount(filterValue);
      }, 350);
      searchParams.amount = filterValue;
    }

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnDateFilterSelected = (filter, startDate, endDate) => {
    setSelectedFilter(filter);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.fid = filter;

    if (filter === CUSTOM_FILTER) {
      setSelectedCustomFilter({ startDate, endDate });
      searchParams.fsd = startDate;
      searchParams.fed = endDate;
    }

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnPaginationChange = nextPage => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnTableSortChange = (columnNumber, sortType) => {
    setColumnOrder(columnNumber);
    setColumnOrderType(sortType);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.columnNumber = columnNumber;
    searchParams.sortType = sortType;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnTableExport = () => {
    const branchId = selectedBranch !== 'all' ? selectedBranch : null;
    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null
    );
    const posFilter = selectedPos !== 'all' ? posFilterOptions.find(p => p.networkIdentifier === selectedPos) : null;

    dispatch(
      businessActions.exportTable(
        'activity',
        {
          branchTraceId: branchId,
          start: toISOString(dateFilter.startDate),
          end: toISOString(dateFilter.endDate),
          columnOrder,
          columnOrderType,
          networkIdentifier: posFilter ? posFilter.networkIdentifier : null,
          networkKey: posFilter ? posFilter.networkKey : null,
          brands: selectedAcquirer && selectedAcquirer !== 'all' ? selectedAcquirer : null,
          currency: selectedCurrency && selectedCurrency !== 'all' ? selectedCurrency : null,
          movementType: selectedMovementType && selectedMovementType !== 'all' ? selectedMovementType : null,
          amount: selectedAmount || null,
        },
        file =>
          Utils.downloadFile(
            file,
            'Actividad_' +
              dateFilter.startDate.format(i18n.get('ExportDateFormat')) +
              '_' +
              dateFilter.endDate.format(i18n.get('ExportDateFormat')) +
              '.xlsx'
          )
      )
    );
  };

  const _toggleAdvancedFilters = () => {
    setShowAdvancedFilters(!showAdvancedFilters);
  };

  const renderBranchesSelector = () => (
    <BranchesSelector branches={branches} selectedBranch={selectedBranch} onBranchSelected={_handleOnBranchSelected} />
  );

  const renderPosSelector = () => (
    <FiltersSelector
      idField="networkIdentifier"
      options={posFilterOptions}
      selected={selectedPos}
      onSelected={_handleOnFilterSelected('pos')}
      showDefaultOption
      defaultOption={i18n.get('Activity.FiltersPosAliasesDefaultOption')}
    />
  );

  const renderAcquirerSelector = () => {
    return (
      <FiltersSelector
        idField="id"
        options={acquirerFilterOptions}
        selected={selectedAcquirer}
        onSelected={_handleOnFilterSelected('acquirer')}
        showDefaultOption
        defaultOption={i18n.get('Activity.FiltersAcquirerDefaultOption')}
      />
    );
  };

  const renderCurrencySelector = () => {
    return (
      <FiltersSelector
        idField="id"
        options={currencyFilterOptions}
        selected={selectedCurrency}
        onSelected={_handleOnFilterSelected('currency')}
        showDefaultOption
        defaultOption={i18n.get('Activity.FiltersCurrencyDefaultOption')}
      />
    );
  };

  const renderMovementTypeSelector = () => {
    return (
      <FiltersSelector
        idField="id"
        options={movementTypeFilterOptions}
        selected={selectedMovementType}
        onSelected={_handleOnFilterSelected('movementType')}
        showDefaultOption
        defaultOption={i18n.get('Activity.FiltersMovementTypeDefaultOption')}
      />
    );
  };

  const renderAmountFilter = () => {
    return (
      <FiltersText
        value={selectedAmount}
        placeholder={i18n.get('Activity.FiltersAmountPlaceholder')}
        inputType="number"
        onChange={_handleOnFilterSelected('amount')}
      />
    );
  };

  const renderFiltersDateSelector = () => (
    <FiltersDateSelector
      selectedFilter={selectedFilter}
      startDate={selectedCustomFilter.startDate}
      endDate={selectedCustomFilter.endDate}
      onFilterSelected={_handleOnDateFilterSelected}
      dateFormat={i18n.get('DateFormat', false)}
    />
  );

  const renderOperationsTable = () => {
    const headers = activity.gridHelper.columnsNameAndType;
    const body = activity.gridHelper.rowsColumnAndSale;

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={body.length === paginationPageSize}
        onClick={_goToOperationDetails}
        onPaginationChange={_handleOnPaginationChange}
        onTableSortChange={_handleOnTableSortChange}
      />
    );
  };

  const renderPieChart = (chart, index) => {
    const backgroundColors = GRAPH_COLORS;
    const hoverBackgroundColors = GRAPH_COLORS.map(color => Colors.rgba(color, 90));

    return (
      <React.Fragment key={`${index}_sm_${isSm}`}>
        <Pie
          height={isSm ? 300 : 180}
          options={{
            legend: {
              display: false,
            },
          }}
          data={{
            labels: chart.dataSet.length === 0 ? [ '' ] : chart.labels,
            datasets: [
              {
                data: chart.dataSet.length === 0 ? [ 100 ] : chart.dataSet,
                backgroundColor: chart.dataSet.length === 0 ? [ '#ddd' ] : backgroundColors,
                hoverBackgroundColor: chart.dataSet.length === 0 ? [ '#ccc' ] : hoverBackgroundColors,
              },
            ],
          }}
        />
        {chart.dataSet.length > 0 ? (
          <div className={classes.pieChartLabelsWrapper}>
            {chart.labels.map((label, labelIndex) => (
              <div className={classes.pieChartLabel} key={`label_${index}_${labelIndex}`}>
                <div className={classes.pieChartLabelIndicator} style={{ background: backgroundColors[labelIndex] }} />
                {label}
              </div>
            ))}
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  const renderLineChart = (chart, index) => {
    const hasNoData = chart.dataSet.length === 0;
    const hasEnoughData = chart.dataSet.length >= 2;
    const stepSize = !hasEnoughData ? 100 : Math.round(_.max(chart.dataSet) / 5);

    return (
      <div key={`${index}_sm_${isSm}`}>
        <div className={classes.chartTitle}>
          <div>{chart.currency}</div>
        </div>
        <div className={classes.chartContainer}>
          <Line
            height={isSm ? 120 : 100}
            options={{
              legend: {
                display: false,
              },
              scales: {
                xAxes: [
                  {
                    scaleLabel: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      stepSize,
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }}
            data={{
              labels: hasNoData
                ? [ 0, 0 ]
                : !hasEnoughData
                  ? [ moment(chart.labels[0]).format('DD/MM'), moment(chart.labels[0]).format('DD/MM') ]
                  : chart.labels.map(date => moment(date).format('DD/MM')),
              datasets: [
                {
                  label: '',
                  fill: false,
                  lineTension: 0.1,
                  borderColor: Colors.rgb.primary,
                  borderCapStyle: 'butt',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  pointBorderColor: Colors.rgb.white,
                  pointBackgroundColor: Colors.rgb.secondary,
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: Colors.rgb.white,
                  pointHoverBorderColor: Colors.rgb.secondary,
                  pointHoverBorderWidth: 2,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  data: hasNoData ? [ 0, 0 ] : !hasEnoughData ? [ chart.dataSet[0], chart.dataSet[0] ] : chart.dataSet,
                },
              ],
            }}
          />
        </div>
      </div>
    );
  };

  const renderTotals = (chart, hideDecimals = false, isLast = false) => () => {
    if (chart.pieChart) {
      return null;
    }

    const totalUYU = chart.graphs.find(g => g.currency === 'UYU').total || 0;
    const totalUSD = chart.graphs.find(g => g.currency === 'USD').total || 0;

    return (
      <div className={classes.chartTotalsWrapper}>
        <div className={classes.chartTotalTitle}>{isLast ? i18n.get('Activity.LineChartAvgLabel') : i18n.get('Activity.LineChartTotalLabel')}</div>
        <div className={classes.chartTotals}>
          <div
            className={clsx(
              classes.chartTotal,
              totalUYU >= 0 ? classes.chartTotalPositive : classes.chartTotalNegative
            )}
          >
            <span>{i18n.get('Currency.UYU')}</span> {hideDecimals ? totalUYU : Utils.formatCurrency(totalUYU)}
          </div>
          <div
            className={clsx(
              classes.chartTotal,
              totalUSD >= 0 ? classes.chartTotalPositive : classes.chartTotalNegative
            )}
          >
            <span>{i18n.get('Currency.USD')}</span> {hideDecimals ? totalUSD : Utils.formatCurrency(totalUSD)}
          </div>
        </div>
      </div>
    );
  };

  const renderChart = (chart, index) => {
    if (chart.graphs.length === 0) {
      return null;
    }

    const isLast = index === 2;

    return (
      <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
        <TitleContentBox
          title={chart.graphTitle}
          customRender={renderTotals(chart, chart.graphTitle === 'Cantidad de ventas', isLast)}
        >
          <div className={classes.chartsContainer}>
            {chart.pieChart ? chart.graphs.map(renderPieChart) : chart.graphs.map(renderLineChart)}
          </div>
        </TitleContentBox>
      </Grid>
    );
  };

  const renderToggleFilters = () => {
    return (
      <div
        className={clsx(classes.toggleFiltersWrapper, showAdvancedFilters ? classes.toggleFiltersWrapperActive : null)}
        onClick={_toggleAdvancedFilters}
      >
        <div>
          {showAdvancedFilters ? isSm ? (
            i18n.get('Activity.HideAdvancedFiltersMobile')
          ) : (
            i18n.get('Activity.HideAdvancedFilters')
          ) : isSm ? (
            i18n.get('Activity.ShowAdvancedFiltersMobile')
          ) : (
            i18n.get('Activity.ShowAdvancedFilters')
          )}
        </div>
        <KeyboardArrowDownIcon />
      </div>
    );
  };

  if (forbiddenSection) {
    return (
      <Page withHeader withSidebar withHeaderTitle={i18n.get('Activity.Title')} withActivePage={ROUTES.ACTIVITY.id}>
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page withHeader withSidebar withHeaderTitle={i18n.get('Activity.Title')} withActivePage={ROUTES.ACTIVITY.id}>
      <TitleBox title={i18n.get('Activity.BoxTitle')} />
      <BoxSeparator />
      <ContentBox title={i18n.get('Activity.BoxFiltersTitle')} titleBold customRightAction={renderToggleFilters}>
        <FiltersContainer>
          {renderBranchesSelector()}
          <FiltersSeparator />
          {renderPosSelector()}
          <FiltersSeparator />
          {renderFiltersDateSelector()}
          {showAdvancedFilters ? (
            <React.Fragment>
              <FiltersSeparator />
              {renderAcquirerSelector()}
              <FiltersSeparator />
              {renderCurrencySelector()}
              <FiltersSeparator />
              {renderMovementTypeSelector()}
              <FiltersSeparator />
              {renderAmountFilter()}
            </React.Fragment>
          ) : null}
        </FiltersContainer>
      </ContentBox>
      <BoxSeparator size="small" />
      {hasActivity ? (
        <React.Fragment>
          <Grid container spacing={2} className={classes.chartsWrapper}>
            {activity.graphs.map(renderChart)}
          </Grid>
          <BoxSeparator size="small" />
        </React.Fragment>
      ) : null}
      <ContentBox
        title={i18n.get('Activity.GridTitle')}
        titleBold
        button={hasActivity && canExportActivity ? activity.gridHelper.exportable : false}
        buttonText={i18n.get('Activity.GridExportButton')}
        buttonOnClick={_handleOnTableExport}
        buttonRightIcon={() => <SaveAltIcon fontSize="small" />}
      >
        {hasActivity ? (
          <React.Fragment>{renderOperationsTable()}</React.Fragment>
        ) : (
          <EmptyMessage textOnly>{i18n.get('Activity.GridEmptyMessage')}</EmptyMessage>
        )}
      </ContentBox>
    </Page>
  );
};

ActivityScreen.id = 'com.Handy.Activity';

export default ActivityScreen;
