import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push, replace } from 'connected-react-router';
import qs from 'query-string';

import ROUTES from '../../../routes';
import {
  BoxMenu,
  BoxSeparator,
  Button,
  Confirm,
  ContentBox,
  EmptyMessage,
  FiltersContainer,
  FiltersSelector,
  FiltersSeparator,
  FiltersText,
  ForbiddenSection,
  OperationsTable,
  Page,
  TitleBox,
} from '../../../components';
import { business as businessActions } from '../../../actions';
import { Language, Utils } from '../../../utils';

import ListItemText from '@material-ui/core/ListItemText';
import {
  Visibility as VisisbilityIcon,
  Close as InactiveIcon,
  Check as ActiveIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import MoreVerticalIcon from '../../../static/images/icons/more_vertical.svg';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import makeClasses from './styles';
import { IconButton, ListItemIcon, Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import { Policies } from '../../../utils/Policies';
import moment from 'moment';
import clsx from 'clsx';
import { Alert, AlertTitle } from '@material-ui/lab';
import { toast } from 'react-toastify';
import _ from 'lodash';

const STATUS_FILTER_OPTIONS = [
  { id: 'ACTIVE', name: 'Statuses.ACTIVE_F' },
  { id: 'INACTIVE', name: 'Statuses.INACTIVE_F' },
];

const TYPE_FILTER_OPTIONS = [
  { id: 'POSTPONE', name: 'ProvidersSubscriptionsManagePromotions.PromotionType_POSTPONE' },
  { id: 'PERIOD', name: 'ProvidersSubscriptionsManagePromotions.PromotionType_PERIOD' },
];

const APPLIES_TO_OPTIONS = [ 
  { id: "ALL", name: "ProvidersSubscriptionsManagePromotions.NewSubscription_ALL" },
  { id: "NEW", name: "ProvidersSubscriptionsManagePromotions.NewSubscription_NEW" },
  { id: "ALREADY_SUBSCRIBED", name: "ProvidersSubscriptionsManagePromotions.NewSubscription_ALREADY_SUBSCRIBED" },
];

const ProvidersSubscriptionsManagePromotionsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const searchParams = qs.parse(history.location.search);

  const [promotions, setPromotions] = useState({});

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(-1);
  const [paginationPage, setPaginationPage] = useState(searchParams.page ? parseInt(searchParams.page) : 1);
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(searchParams.pid || 'all');
  const [selectedPromotionType, setSelectedPromotionType] = useState(searchParams.pt || 'all');
  const [selectedAppliesTo, setSelectedAppliesTo] = useState(searchParams.at || 'all');
  const [selectedStatus, setSelectedStatus] = useState(searchParams.tid ? searchParams.tid : 'all');
  const [showTogglePromotionStatusDialog, setShowTogglePromotionStatusDialog] = useState(false);
  const [promotionToToggleStatus, setPromotionToToggleStatus] = useState(null);
  const [selectedPromotionName, setSelectedPromotionName] = useState(searchParams.pn ? searchParams.pn : '');
  const [plansOptions, setPlansOptions] = useState([]);
  const [filtersLoaded, setFiltersLoaded] = useState(false);

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const hasPromotions = promotions && promotions.elements && promotions.elements.length > 0;
  const canExport = policies.includes(Policies.types.SUPER_READ);
  const canManage = policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_PROMOTIONS_WRITE);
  const canSeeTransactions = policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (policies && policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_PROMOTIONS)) {
      loadFiltersData();
      loadPromotions();
    } else {
      setForbbidenSection(true);
    }
  }, [policies, paginationPage, selectedPlan, selectedPromotionType, selectedAppliesTo, selectedStatus, selectedPromotionName]);

  const loadFiltersData = () => {
    if (filtersLoaded) {
      return;
    }

    dispatch(
      businessActions.getProvidersSubscriptionsPlans(null, null, null, null, null, 100, null, (data) => {
        if (data) {
          const options = data.elements
            ? _.orderBy(data.elements
                .filter((e) => e)
                .map((e) => ({
                  id: e.subscriptionPlanTraceId,
                  name: e.name,
                })), ['name'], ['asc'])
            : [];
          setPlansOptions(options);
        }
      })
    );

    setFiltersLoaded(true);
  };

  const loadPromotions = () => {
    dispatch(
      businessActions.getProvidersSubscriptionsPromotions(
        selectedPlan !== 'all' ? selectedPlan : null,
        selectedStatus !== 'all' ? selectedStatus : null,
        selectedPromotionType !== 'all' ? selectedPromotionType : null,
        selectedAppliesTo !== 'all' ? selectedAppliesTo : null,
        selectedPromotionName,
        15,
        paginationPage,
        (data) => {
          setPromotions(data);
        }
      )
    );
  };

  const toggleMenu = (index) => (e) => {
    if (menuAnchorEl) {
      setMenuAnchorEl(null);
      setMenuOpenIndex(-1);
    } else {
      setMenuAnchorEl(e.target);
      setMenuOpenIndex(index);
    }
  };

  const handleOnViewPromotion = (promotion) => () => {
    toggleMenu()();
    dispatch(
      push(ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS_VIEW.path.replace(':traceId', promotion.promotionTraceId))
    );
  };

  const handleOnPaginationChange = (nextPage) => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const renderPromotions = () => {
    if (!promotions || !promotions.elements || promotions.elements.length === 0) {
      return (
        <EmptyMessage textOnly>{i18n.get('ProvidersSubscriptionsManagePromotions.GridEmptyMessage')}</EmptyMessage>
      );
    }

    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get('ProvidersSubscriptionsManagePromotions.PromotionsGrid.Column1'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get('ProvidersSubscriptionsManagePromotions.PromotionsGrid.Column2'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get('ProvidersSubscriptionsManagePromotions.PromotionsGrid.Column3'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 4,
        name: i18n.get('ProvidersSubscriptionsManagePromotions.PromotionsGrid.Column4'),
        sortable: false,
        type: 'date',
      },
      {
        canHide: false,
        columnNumber: 5,
        name: i18n.get('ProvidersSubscriptionsManagePromotions.PromotionsGrid.Column5'),
        sortable: false,
        type: 'date',
      },
      {
        canHide: false,
        columnNumber: 6,
        name: i18n.get('ProvidersSubscriptionsManagePromotions.PromotionsGrid.Column6'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 7,
        name: i18n.get('ProvidersSubscriptionsManagePromotions.PromotionsGrid.Column7'),
        sortable: false,
        type: 'status_f',
      },
      {
        canHide: false,
        columnNumber: 8,
        name: i18n.get('ProvidersSubscriptionsManagePromotions.PromotionsGrid.Column8'),
        sortable: false,
        type: 'custom',
        show: !isSm,
        align: 'right'
      },
    ];
    const body = (promotions.elements || []).map((item, index) => ({
      rowId: item.traceId,
      columns: [
        {
          columnNumber: 1,
          data: item.name,
        },
        {
          columnNumber: 2,
          data:
            item.promotionPeriod?.percentage > 0
              ? i18n.get('ProvidersSubscriptionsManagePromotions.PromotionType_PERIOD')
              : item.promotionPostpone?.amountDays > 0
              ? i18n.get('ProvidersSubscriptionsManagePromotions.PromotionType_POSTPONE')
              : '-',
        },
        {
          columnNumber: 3,
          data: item.subscriptionPlan?.code ? `${item.subscriptionPlan?.name} - ${item.subscriptionPlan?.code}` : item.subscriptionPlan?.name,
        },
        {
          columnNumber: 4,
          data: item.startDate,
        },
        {
          columnNumber: 5,
          data: item.endDate,
        },
        {
          columnNumber: 6,
          data: i18n.get(`ProvidersSubscriptionsManagePromotions.NewSubscription_${item.appliesTo}`)
        },
        {
          columnNumber: 7,
          data: item.promotionStatus,
        },
        {
          columnNumber: 8,
          data: () => {
            return (
              <div className={classes.listItemActions}>
                <IconButton onClick={toggleMenu(index)}>
                  <img src={MoreVerticalIcon} alt="More" />
                </IconButton>
                <Menu
                  anchorEl={menuAnchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  onClose={toggleMenu()}
                  open={menuOpenIndex === index}
                  className={classes.listItemMenu}
                >
                  <MenuItem onClick={handleOnViewPromotion(item)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <VisisbilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('ProvidersSubscriptionsManagePromotions.ActionViewDetails')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                  {canManage ? (
                    <MenuItem onClick={handleOnTogglePromotionStatus(item)} className={classes.listItemMenuItem}>
                      <ListItemIcon className={classes.menuItemIcon}>
                        {item.promotionStatus === 'ACTIVE' ? (
                          <InactiveIcon fontSize="small" />
                        ) : (
                          <ActiveIcon fontSize="small" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          item.promotionStatus === 'ACTIVE'
                            ? i18n.get('ProvidersSubscriptionsManagePromotions.ActionToggleStatusInactive')
                            : i18n.get('ProvidersSubscriptionsManagePromotions.ActionToggleStatusActive')
                        }
                        className={classes.menuItemText}
                      />
                    </MenuItem>
                  ) : null}
                </Menu>
              </div>
            );
          },
        },
      ],
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={promotions.hasNext}
        onPaginationChange={handleOnPaginationChange}
        emptyMessage={i18n.get('ProvidersSubscriptionsManagePromotionsView.GridEmptyMessage')}
        showDetails={false}
      />
    );
  };

  const handleOnExport = () => {
    dispatch(
      businessActions.exportProvidersSubscriptionsPromotions(
        selectedPlan !== 'all' ? selectedPlan : null,
        selectedStatus !== 'all' ? selectedStatus : null,
        selectedPromotionType !== 'all' ? selectedPromotionType : null,
        selectedPromotionName,
        15,
        paginationPage,
        (file) =>
          Utils.downloadFile(
            file,
            'Cobranzas_Suscripciones_Promociones_' + moment().format(i18n.get('ExportDateFormat')) + '.xlsx'
          )
      )
    );
  };

  const handleOnPlanSelected = (plan) => {
    setSelectedPlan(plan);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.pid = plan;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnTypeSelected = (promotionType) => {
    setSelectedPromotionType(promotionType);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.pt = promotionType;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnAppliesToSelected = (appliesTo) => {
    setSelectedAppliesTo(appliesTo);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.at = appliesTo;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnStatusSelected = (status) => {
    setSelectedStatus(status);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.tid = status;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnChangePromotionName = (promotionName) => {
    setSelectedPromotionName(promotionName);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.pn = promotionName;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnMenuItemClick = (item) => {
    dispatch(push(item.path));
  };

  const handleOnTogglePromotionStatus = (promotion) => () => {
    toggleMenu()();
    toggleTogglePromotionStatusDialog();
    setPromotionToToggleStatus(promotion);
  };

  const toggleTogglePromotionStatusDialog = () => {
    setShowTogglePromotionStatusDialog(!showTogglePromotionStatusDialog);
  };

  const confirmTogglePromotionStatus = () => {
    toggleTogglePromotionStatusDialog();
    const newPromotionStatus = promotionToToggleStatus.promotionStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
    dispatch(
      businessActions.updateProvidersSubscriptionsPromotion(
        promotionToToggleStatus.promotionTraceId,
        promotionToToggleStatus.subscriptionPlanTraceId,
        newPromotionStatus,
        (result) => {
          if (result) {
            toast.success(
              newPromotionStatus === 'ACTIVE'
                ? i18n.get('ProvidersSubscriptionsManagePromotions.SuccessTogglePromotionStatusMessageActive')
                : i18n.get('ProvidersSubscriptionsManagePromotions.SuccessTogglePromotionStatusMessageInactive')
            );
            loadPromotions();
          } else {
            toast.error(
              newPromotionStatus === 'ACTIVE'
                ? i18n.get('ProvidersSubscriptionsManagePromotions.ErrorTogglePromotionStatusMessageActive')
                : i18n.get('ProvidersSubscriptionsManagePromotions.ErrorTogglePromotionStatusMessageInactive')
            );
          }
        }
      )
    );
  };

  const handleOnClickNewPromotion = () => {
    dispatch(push(ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS_ADD.path));
  };

  const renderPlansSelector = () => {
    return (
      <FiltersSelector
        options={plansOptions}
        selected={selectedPlan}
        onSelected={handleOnPlanSelected}
        defaultOption={i18n.get('ProvidersSubscriptionsManagePromotions.FilterPlansDefaultOption')}
      />
    );
  };

  const renderTypeSelector = () => {
    return (
      <FiltersSelector
        options={TYPE_FILTER_OPTIONS.map((s) => ({ ...s, name: i18n.get(s.name) }))}
        selected={selectedPromotionType}
        onSelected={handleOnTypeSelected}
        defaultOption={i18n.get('ProvidersSubscriptionsManagePromotions.FilterTypeDefaultOption')}
      />
    );
  };

  const renderAppliesToSelector = () => {
    return (
      <FiltersSelector
        options={APPLIES_TO_OPTIONS.map((s) => ({ ...s, name: i18n.get(s.name) }))}
        selected={selectedAppliesTo}
        onSelected={handleOnAppliesToSelected}
        defaultOption={i18n.get('ProvidersSubscriptionsManagePromotions.FilterAppliesToDefaultOption')}
      />
    );
  };

  const renderStatusesSelector = () => {
    return (
      <FiltersSelector
        options={STATUS_FILTER_OPTIONS.map((s) => ({ ...s, name: i18n.get(s.name) }))}
        selected={selectedStatus}
        onSelected={handleOnStatusSelected}
        defaultOption={i18n.get('ProvidersSubscriptionsManagePromotions.FilterStatusesDefaultOption')}
      />
    );
  };

  const renderPromotionNameFilter = () => {
    return (
      <FiltersText
        value={selectedPromotionName}
        placeholder={i18n.get('ProvidersSubscriptionsManagePromotions.FiltersPromotionNamePlaceholder')}
        onChange={handleOnChangePromotionName}
      />
    );
  };

  const renderExportButton = () => {
    if (!canExport) {
      return null;
    }
    return (
      <Button
        rightIcon={() => <SaveAltIcon fontSize="small" />}
        onClick={handleOnExport}
        disabled={!promotions || !promotions.elements || promotions.elements.length === 0}
      >
        {isSm ? null : i18n.get('ProvidersSubscriptionsManagePromotions.ExportButton')}
      </Button>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ProvidersSubscriptionsManagePromotions.Title')}
        withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ProvidersSubscriptionsManagePromotions.Title')}
      withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
    >
      <BoxMenu
        items={_.compact([
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PLANS,
            label: i18n.get('ProvidersSubscriptionsManagePromotions.BoxMenu.Item1'),
          },
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS,
            label: i18n.get('ProvidersSubscriptionsManagePromotions.BoxMenu.Item3'),
          },
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS,
            label: i18n.get('ProvidersSubscriptionsManagePromotions.BoxMenu.Item2'),
          },
          canSeeTransactions ? {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS,
            label: i18n.get('ProvidersSubscriptionsManagePromotions.BoxMenu.Item4'),
          } : null,
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS,
            label: i18n.get('ProvidersSubscriptionsManagePromotions.BoxMenu.Item5'),
          },
        ])}
        selectedItem={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS.id}
        onClickItem={handleOnMenuItemClick}
      />
      <TitleBox
        title={i18n.get('ProvidersSubscriptionsManagePromotions.BoxTitle')}
        buttonRight={canManage}
        buttonRightIcon={() => <AddIcon fontSize="small" />}
        buttonRightText={isSm ? null : i18n.get('ProvidersSubscriptionsManagePromotions.BoxTitleButtonText')}
        buttonRightOnClick={handleOnClickNewPromotion}
      />
      <BoxSeparator size="small" />
      <ContentBox title={i18n.get('ProvidersSubscriptionsManagePromotions.BoxFiltersTitle')}>
        <FiltersContainer>
          {renderPlansSelector()}
          <FiltersSeparator />
          {renderPromotionNameFilter()}
          <FiltersSeparator />
          {renderTypeSelector()}
          <FiltersSeparator />
          {renderAppliesToSelector()}
          <FiltersSeparator />
          {renderStatusesSelector()}
        </FiltersContainer>
      </ContentBox>
      <BoxSeparator size="small" />
      <ContentBox
        title={i18n.get('ProvidersSubscriptionsManagePromotions.SubscribersGridTitle')}
        titleBold
        customRightAction={renderExportButton}
      >
        {hasPromotions ? (
          renderPromotions()
        ) : (
          <div className={classes.noDataMessage}>
            {i18n.get('ProvidersSubscriptionsManagePromotions.NoSubscribersMessage')}
          </div>
        )}
      </ContentBox>
      <Confirm
        open={showTogglePromotionStatusDialog}
        onClose={toggleTogglePromotionStatusDialog}
        title={
          promotionToToggleStatus && promotionToToggleStatus.promotionStatus === 'ACTIVE'
            ? i18n.get('ProvidersSubscriptionsManagePromotions.TogglePromotionStatusDialogTitleDeactivate')
            : i18n.get('ProvidersSubscriptionsManagePromotions.TogglePromotionStatusDialogTitleActivate')
        }
        confirmText={i18n.get('Buttons.CONFIRM')}
        onConfirm={confirmTogglePromotionStatus}
        cancelText={i18n.get('Buttons.CANCEL')}
        onCancel={toggleTogglePromotionStatusDialog}
      >
        <Alert severity="error" icon={false} className={clsx(classes.alert, classes.alertDanger)}>
          <div>
            <ErrorOutlineOutlinedIcon className={classes.alertIcon} />
          </div>
          <div>
            <AlertTitle className={classes.alertTitle}>
              {promotionToToggleStatus && promotionToToggleStatus.promotionStatus === 'ACTIVE'
                ? i18n.get('ProvidersSubscriptionsManagePromotions.TogglePromotionStatusDialogContentTitleDeactivate')
                : i18n.get('ProvidersSubscriptionsManagePromotions.TogglePromotionStatusDialogContentTitleActivate')}
            </AlertTitle>
            {i18n.get('ProvidersSubscriptionsManagePromotions.TogglePromotionStatusDialogContentText')}
          </div>
        </Alert>
      </Confirm>
    </Page>
  );
};

ProvidersSubscriptionsManagePromotionsScreen.id = 'com.Handy.ProvidersSubscriptionsManagePromotions';

export default ProvidersSubscriptionsManagePromotionsScreen;
