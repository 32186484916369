import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push, replace } from 'connected-react-router';
import qs from 'query-string';

import ROUTES from '../../../routes';
import {
  BoxMenu,
  BoxSeparator,
  Button,
  ContentBox,
  EmptyMessage,
  FiltersContainer,
  FiltersDateSelector,
  FiltersSelector,
  FiltersSeparator,
  FiltersText,
  ForbiddenSection,
  OperationsTable,
  Page,
  TitleBox,
} from '../../../components';
import { business as businessActions } from '../../../actions';
import { Language, Utils } from '../../../utils';

import ListItemText from '@material-ui/core/ListItemText';
import { Add as AddIcon, Visibility as VisisbilityIcon } from '@material-ui/icons';
import MoreVerticalIcon from '../../../static/images/icons/more_vertical.svg';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import makeClasses from './styles';
import { IconButton, ListItemIcon, Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import { Policies } from '../../../utils/Policies';
import { CUSTOM_FILTER, getDateRangeForFilter } from '../../../components/FiltersDateSelector';
import { toISOString } from '../../../utils/Utils';
import { toast } from 'react-toastify';

const STATUS_FILTER_OPTIONS = [
  { id: 'ACTIVE', name: 'ProvidersManageSubscribers.ValueStatusActive' },
  { id: 'INACTIVE', name: 'ProvidersManageSubscribers.ValueStatusInactive' },
];

const ProvidersManageSubscribersScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const searchParams = qs.parse(history.location.search);

  const [subscribers, setSubscribers] = useState({});

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(-1);
  const [paginationPage, setPaginationPage] = useState(searchParams.page ? parseInt(searchParams.page) : 1);
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [selectedService, setSelectedService] = useState(searchParams.sid || 'all');
  const [providersServicesOptions, setProvidersServicesOptions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(searchParams.fid ? searchParams.fid === 'custom' ? searchParams.fid : parseInt(searchParams.fid) : 0);
  const [selectedCustomFilter, setSelectedCustomFilter] = useState(
    searchParams.fid === CUSTOM_FILTER ? { startDate: searchParams.fsd, endDate: searchParams.fed } : {}
  );
  const [selectedStatus, setSelectedStatus] = useState(searchParams.tid ? searchParams.tid : 'all');
  const [selectedUser, setSelectedUser] = useState(searchParams.u ? searchParams.u : '');
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [selectedUserDocument, setSelectedUserDocument] = useState(searchParams.ud ? searchParams.ud : '');
  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const hasSubscribers = subscribers && subscribers.elements && subscribers.elements.length > 0;
  const canExport = policies.includes(Policies.types.PROVIDER) && policies.includes(Policies.types.SUPER_READ);
  const canRequestCharge = policies.includes(Policies.types.PROVIDER_CREATE_PAYMENT);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (policies && policies.includes(Policies.types.PROVIDER)) {
      loadSubscribers();
    } else {
      setForbbidenSection(true);
    }
  }, [policies, paginationPage, selectedFilter, selectedCustomFilter, selectedService, selectedStatus, selectedUser, selectedUserDocument]);

  const loadSubscribers = () => {
    if (!filtersLoaded) {
      loadFiltersData();
    }

    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null,
      true
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.getSubscribers(
        paginationPage,
        startDate,
        endDate,
        selectedService !== 'all' ? selectedService : null,
        selectedStatus === 'ACTIVE' ? true : selectedStatus === 'INACTIVE' ? false : null,
        selectedUser,
        selectedUserDocument,
        (data) => {
          setSubscribers(data);
        }
      )
    );
  };

  const loadFiltersData = () => {
    dispatch(
      businessActions.getProviderServices((data) => {
        const options = data.elements
          ? data.elements.filter((e) => e).map((e) => ({ id: e.traceId, name: e.name }))
          : [];
        setProvidersServicesOptions(options);
      })
    );
    setFiltersLoaded(true);
  };

  const toggleMenu = (index) => (e) => {
    if (menuAnchorEl) {
      setMenuAnchorEl(null);
      setMenuOpenIndex(-1);
    } else {
      setMenuAnchorEl(e.target);
      setMenuOpenIndex(index);
    }
  };

  const handleOnViewSubscriber = (service) => () => {
    toggleMenu()();
    dispatch(push(ROUTES.MANAGE_PROVIDERS_SUBSCRIBERS_VIEW.path.replace(':traceId', service.traceId)));
  };

  const handleOnAddCharge = (service) => () => {
    toggleMenu()();
    dispatch(push(ROUTES.MANAGE_PROVIDERS_SUBSCRIBERS_NEW_CHARGE.path.replace(':traceId', service.traceId)));
  };

  const handleOnPaginationChange = (nextPage) => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const renderSubscribers = () => {
    if (!subscribers || !subscribers.elements || subscribers.elements.length === 0) {
      return <EmptyMessage textOnly>{i18n.get('ProvidersManageSubscribers.GridEmptyMessage')}</EmptyMessage>;
    }

    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get('ProvidersManageSubscribers.SubscribersGrid.Column1'),
        sortable: false,
        type: 'date',
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get('ProvidersManageSubscribers.SubscribersGrid.Column2'),
        sortable: false,
        type: 'status',
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get('ProvidersManageSubscribers.SubscribersGrid.Column3'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 4,
        name: i18n.get('ProvidersManageSubscribers.SubscribersGrid.Column4'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 5,
        name: i18n.get('ProvidersManageSubscribers.SubscribersGrid.Column5'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 6,
        name: i18n.get('ProvidersManageSubscribers.SubscribersGrid.Column6'),
        sortable: false,
        type: 'custom',
        align: 'right',
        show: !isSm,
      },
    ];
    const body = (subscribers.elements || []).map((item, index) => ({
      rowId: item.traceId,
      columns: [
        {
          columnNumber: 1,
          data: item.createdAt,
        },
        {
          columnNumber: 2,
          data: item.isActive,
        },
        {
          columnNumber: 3,
          data: item.productName,
        },
        {
          columnNumber: 4,
          data: `${item.merchantName} (${item.merchantDocument})`,
        },
        {
          columnNumber: 5,
          data: item.branchName,
        },
        {
          columnNumber: 6,
          data: () => {
            return (
              <div className={classes.listItemActions}>
                <IconButton onClick={toggleMenu(index)}>
                  <img src={MoreVerticalIcon} alt="More" />
                </IconButton>
                <Menu
                  anchorEl={menuAnchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  onClose={toggleMenu()}
                  open={menuOpenIndex === index}
                  className={classes.listItemMenu}
                >
                  <MenuItem onClick={handleOnViewSubscriber(item)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <VisisbilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('ProvidersManageSubscribers.ActionViewDetails')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                  {canRequestCharge && item.isActive ? (
                    <MenuItem onClick={handleOnAddCharge(item)} className={classes.listItemMenuItem}>
                      <ListItemIcon className={classes.menuItemIcon}>
                        <AddIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={i18n.get('ProvidersManageSubscribers.ActionAdd')}
                        className={classes.menuItemText}
                      />
                    </MenuItem>
                  ) : null}
                </Menu>
              </div>
            );
          },
        },
      ],
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={subscribers.hasNext}
        onPaginationChange={handleOnPaginationChange}
        emptyMessage={i18n.get('ProvidersManageSubscribers.GridEmptyMessage')}
        showDetails={false}
      />
    );
  };

  const handleOnExport = () => {
    if (selectedFilter === 0) {
      toast.error(i18n.get('ProvidersManageSubscribersView.ExportErrorNoDateFilterSelected'));
      return;
    }

    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null,
      true
    );
    
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.exportProviderSubscribers(
        paginationPage,
        selectedService !== 'all' ? selectedService : null,
        startDate,
        endDate,
        selectedStatus === 'ACTIVE' ? true : selectedStatus === 'INACTIVE' ? false : null,
        selectedUser,
        (file) =>
          Utils.downloadFile(
            file,
            'Cobranzas_Suscriptores_' +
              dateFilter.startDate.format(i18n.get('ExportDateFormat')) +
              '_' +
              dateFilter.endDate.format(i18n.get('ExportDateFormat')) +
              '.xlsx'
          )
      )
    );
  };

  const handleOnFilterSelected = (filter, startDate, endDate) => {
    setSelectedFilter(filter);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.fid = filter;

    if (filter === CUSTOM_FILTER) {
      setSelectedCustomFilter({ startDate, endDate });
      searchParams.fsd = startDate;
      searchParams.fed = endDate;
    }

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnServiceSelected = (service) => {
    setSelectedService(service);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.sid = service;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnStatusSelected = (status) => {
    setSelectedStatus(status);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.tid = status;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnChangeUser = (user) => {
    setSelectedUser(user);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.u = user;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnChangeUserDocument = (userDocument) => {
    setSelectedUserDocument(userDocument);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.ud = userDocument;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnMenuItemClick = (item) => {
    dispatch(push(item.path));
  };

  const renderFiltersDateSelector = () => (
    <FiltersDateSelector
      selectedFilter={selectedFilter}
      startDate={selectedCustomFilter.startDate}
      endDate={selectedCustomFilter.endDate}
      onFilterSelected={handleOnFilterSelected}
      align="right"
      dateFormat={i18n.get('DateFormat', false)}
      showNoFilterOption
    />
  );

  const renderServicesSelector = () => {
    return (
      <FiltersSelector
        options={providersServicesOptions}
        selected={selectedService}
        onSelected={handleOnServiceSelected}
        defaultOption={i18n.get('ProvidersManageSubscribers.FilterServicesDefaultOption')}
      />
    );
  };

  const renderStatusesSelector = () => {
    return (
      <FiltersSelector
        options={STATUS_FILTER_OPTIONS.map((s) => ({ ...s, name: i18n.get(s.name) }))}
        selected={selectedStatus}
        onSelected={handleOnStatusSelected}
        defaultOption={i18n.get('ProvidersManageSubscribers.FilterStatusesDefaultOption')}
      />
    );
  };

  const renderUserFilter = () => {
    return (
      <FiltersText
        value={selectedUser}
        placeholder={i18n.get('ProvidersManageSubscribers.FiltersUserPlaceholder')}
        onChange={handleOnChangeUser}
      />
    );
  };

  const renderUserDocumentFilter = () => {
    return (
      <FiltersText
        value={selectedUserDocument}
        placeholder={i18n.get('ProvidersManageSubscribers.FiltersUserDocumentPlaceholder')}
        onChange={handleOnChangeUserDocument}
      />
    );
  };

  const renderExportButton = () => {
    if (!canExport) {
      return null;
    }
    return (
      <Button
        rightIcon={() => <SaveAltIcon fontSize="small" />}
        onClick={handleOnExport}
        disabled={!subscribers || !subscribers.elements || subscribers.elements.length === 0}
      >
        {isSm ? null : i18n.get('ProvidersManageSubscribers.ExportButton')}
      </Button>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ProvidersManageSubscribers.Title')}
        withActivePage={ROUTES.MANAGE_PROVIDERS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ProvidersManageSubscribers.Title')}
      withActivePage={ROUTES.MANAGE_PROVIDERS.id}
    >
      <BoxMenu
        items={[
          { ...ROUTES.MANAGE_PROVIDERS_SERVICES, label: i18n.get('ProvidersManageSubscribers.BoxMenu.Item1') },
          { ...ROUTES.MANAGE_PROVIDERS_SUBSCRIBERS, label: i18n.get('ProvidersManageSubscribers.BoxMenu.Item2') },
          { ...ROUTES.MANAGE_PROVIDERS_CHARGES, label: i18n.get('ProvidersManageSubscribers.BoxMenu.Item3') },
        ]}
        selectedItem={ROUTES.MANAGE_PROVIDERS_SUBSCRIBERS.id}
        onClickItem={handleOnMenuItemClick}
      />
      <TitleBox title={i18n.get('ProvidersManageSubscribers.BoxTitle')} />
      <BoxSeparator />
      <ContentBox title={i18n.get('ProvidersManageSubscribers.BoxFiltersTitle')}>
        <FiltersContainer>
          {renderServicesSelector()}
          <FiltersSeparator />
          {renderStatusesSelector()}
          <FiltersSeparator />
          {renderUserFilter()}
          <FiltersSeparator />
          {renderUserDocumentFilter()}
          <FiltersSeparator />
          {renderFiltersDateSelector()}
        </FiltersContainer>
      </ContentBox>
      <BoxSeparator size="small" />
      <ContentBox
        title={i18n.get('ProvidersManageSubscribers.SubscribersGridTitle')}
        titleBold
        customRightAction={renderExportButton}
      >
        {hasSubscribers ? (
          renderSubscribers()
        ) : (
          <div className={classes.noDataMessage}>{i18n.get('ProvidersManageSubscribers.NoSubscribersMessage')}</div>
        )}
      </ContentBox>
    </Page>
  );
};

ProvidersManageSubscribersScreen.id = 'com.Handy.ProvidersManageSubscribers';

export default ProvidersManageSubscribersScreen;
