import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BoxSeparator,
  ForbiddenSection,
  Page,
  TitleBox,
  ContentBox,
  EmptyMessage,
  OperationsTable,
  Button,
  Confirm,
  StatusPill,
} from "../../../../../components";
import ROUTES from "../../../../../routes";
import { Language, Utils } from "../../../../../utils";
import { business as businessActions, user as userActions } from "../../../../../actions";
import { goBack, push, replace } from "connected-react-router";
import makeClasses from "./styles";
import {
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import qs from "query-string";

import { Policies } from "../../../../../utils/Policies";
import moment from "moment";
import MoreVerticalIcon from "../../../../../static/images/icons/more_vertical.svg";
import { Visibility as VisisbilityIcon } from "@material-ui/icons";
import clsx from "clsx";
import { Alert, AlertTitle } from "@material-ui/lab";
import { toast } from "react-toastify";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { getPhoneAreaFromCountry } from "../../../../../utils/Utils";

const CURRENCY_FILTER_OPTIONS = [
  { id: "URUGUAYAN_PESO", name: "Currency.UYU" },
  { id: "DOLLAR", name: "Currency.USD" },
];

const ProvidersSubscriptionsManageSubscriptionDetailsScreen = ({
  history,
  match,
}) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const searchParams = qs.parse(history.location.search);

  const [subscription, setSubscription] = useState(null);
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(-1);
  const [paginationPage, setPaginationPage] = useState(
    searchParams.page ? parseInt(searchParams.page) : 1
  );
  const [countries, setCountries] = useState([]);
  const [
    showToggleSubscriptionStatusDialog,
    setShowToggleSubscriptionStatusDialog,
  ] = useState(false);
  const [subscriptionStatusToToggle, setSubscriptionStatusToToggle] =
    useState(null);

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const subscriptionTraceId =
    match && match.params ? match.params.traceId : null;
  const canManage = policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS_WRITE);

  useEffect(() => {
    if (!policies || !policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS)) {
      setForbbidenSection(true);
    }
  }, [policies]);

  useEffect(() => {
    if (!forbiddenSection) {
      loadData();
    }
  }, [forbiddenSection, paginationPage]);

  const loadData = () => {
    if (subscriptionTraceId) {
      dispatch(
        businessActions.getProvidersSubscriptionsSubscription(
          subscriptionTraceId,
          (data) => {
            setSubscription(data);
          }
        )
      );
      dispatch(
        businessActions.getProvidersSubscriptionsTransactions(
          null,
          subscriptionTraceId,
          null,
          null,
          null,
          paginationPage,
          15,
          (data) => {
            setTransactions(data);
          }
        )
      );
      dispatch(
        userActions.getCountries((data) => {
          setCountries(data)
    })
      )
    }
  };

  const handleOnGoBackClick = () => {
    dispatch(goBack());
  };

  const toggleMenu = (index) => (e) => {
    if (menuAnchorEl) {
      setMenuAnchorEl(null);
      setMenuOpenIndex(-1);
    } else {
      setMenuAnchorEl(e.target);
      setMenuOpenIndex(index);
    }
  };

  const handleOnViewTransaction = (transaction) => () => {
    toggleMenu()();
    dispatch(
      push(
        ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS_VIEW.path.replace(
          ":traceId",
          transaction.transactionTraceId
        )
      )
    );
  };

  const handleOnPaginationChange = (nextPage) => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnToggleSubscriptionStatus = (status) => () => {
    toggleToggleSubscriptionStatusDialog();
    setSubscriptionStatusToToggle(status);
  };

  const toggleToggleSubscriptionStatusDialog = () => {
    setShowToggleSubscriptionStatusDialog(!showToggleSubscriptionStatusDialog);
  };

  const confirmToggleSubscriptionStatus = () => {
    toggleToggleSubscriptionStatusDialog();
    dispatch(
      businessActions.toggleProvidersSubscriptionsSubscriptionStatus(
        subscription.subscriptionTraceId,
        subscriptionStatusToToggle,
        (result) => {
          if (result) {
            toast.success(
              subscriptionStatusToToggle === "REACTIVATE"
                ? i18n.get(
                    "ProvidersSubscriptionsManageSubscriptionDetails.SuccessToggleSubscriptionStatusMessageActive"
                  )
                : subscriptionStatusToToggle === "PAUSED"
                ? i18n.get(
                    "ProvidersSubscriptionsManageSubscriptionDetails.SuccessToggleSubscriptionStatusMessagePause"
                  )
                : i18n.get(
                    "ProvidersSubscriptionsManageSubscriptionDetails.SuccessToggleSubscriptionStatusMessageCancel"
                  )
            );
            loadData();
          } else {
            toast.error(
              subscriptionStatusToToggle === "REACTIVATE"
                ? i18n.get(
                    "ProvidersSubscriptionsManageSubscriptionDetails.ErrorToggleSubscriptionStatusMessageActive"
                  )
                : subscriptionStatusToToggle === "PAUSED"
                ? i18n.get(
                    "ProvidersSubscriptionsManageSubscriptionDetails.ErrorToggleSubscriptionStatusMessagePause"
                  )
                : i18n.get(
                    "ProvidersSubscriptionsManageSubscriptionDetails.ErrorToggleSubscriptionStatusMessageCancel"
                  )
            );
          }
        }
      )
    );
  };

  const renderTransactions = () => {
    if (
      !transactions ||
      !transactions.elements ||
      transactions.elements.length === 0
    ) {
      return (
        <EmptyMessage textOnly>
          {i18n.get(
            "ProvidersSubscriptionsManageSubscriptionDetails.GridEmptyMessage"
          )}
        </EmptyMessage>
      );
    }

    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get(
          "ProvidersSubscriptionsManageSubscriptionDetails.TransactionsGrid.Column1"
        ),
        sortable: false,
        type: "date",
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get(
          "ProvidersSubscriptionsManageSubscriptionDetails.TransactionsGrid.Column2"
        ),
        sortable: false,
        type: "status",
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get(
          "ProvidersSubscriptionsManageSubscriptionDetails.TransactionsGrid.Column3"
        ),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 4,
        name: i18n.get(
          "ProvidersSubscriptionsManageSubscriptionDetails.TransactionsGrid.Column4"
        ),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 5,
        name: i18n.get(
          "ProvidersSubscriptionsManageSubscriptionDetails.TransactionsGrid.Column5"
        ),
        sortable: false,
        type: 'custom',
        show: !isSm,
        align: 'right'
      },
    ];
    const body = (transactions.elements || []).map((item, index) => ({
      rowId: item.traceId,
      columns: [
        {
          columnNumber: 1,
          data: item.createdAt,
        },
        {
          columnNumber: 2,
          data: item.transactionStatus,
        },
        {
          columnNumber: 3,
          data: item.instrument?.maskedPan,
        },
        {
          columnNumber: 4,
          data: `${i18n.get(
            CURRENCY_FILTER_OPTIONS.find((o) => o.id === item.currency)?.name
          )} ${Utils.formatCurrency(item.amount)}`,
        },
        {
          columnNumber: 5,
          data: () => {
            return (
              <div className={classes.listItemActions}>
                <IconButton onClick={toggleMenu(index)}>
                  <img src={MoreVerticalIcon} alt="More" />
                </IconButton>
                <Menu
                  anchorEl={menuAnchorEl}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  onClose={toggleMenu()}
                  open={menuOpenIndex === index}
                  className={classes.listItemMenu}
                >
                  <MenuItem
                    onClick={handleOnViewTransaction(item)}
                    className={classes.listItemMenuItem}
                  >
                    <ListItemIcon className={classes.menuItemIcon}>
                      <VisisbilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get(
                        "ProvidersSubscriptionsManageSubscriptionDetails.ActionViewDetails"
                      )}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                </Menu>
              </div>
            );
          },
        },
      ],
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={transactions.hasNext}
        onPaginationChange={handleOnPaginationChange}
        emptyMessage={i18n.get(
          "ProvidersSubscriptionsManageSubscriptionDetails.GridEmptyMessage"
        )}
        showDetails={false}
      />
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get(
          "ProvidersSubscriptionsManageSubscriptionDetails.Title"
        )}
        withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get(
        "ProvidersSubscriptionsManageSubscriptionDetails.Title"
      )}
      withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
    >
      <TitleBox
        title={i18n.get(
          "ProvidersSubscriptionsManageSubscriptionDetails.BoxTitle"
        )}
        back
        backTitle={i18n.get(
          "ProvidersSubscriptionsManageSubscriptionDetails.BackButton"
        )}
        onBackClick={handleOnGoBackClick}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      {subscription ? (
        <React.Fragment>
          <ContentBox
            title={i18n.get(
              "ProvidersSubscriptionsManageSubscriptionDetails.SubscriptionBoxDataLabel"
            )}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageSubscriptionDetails.SubscriptionBoxDateLabel"
                    )}
                    :
                  </div>
                  {subscription.createdAt
                    ? moment(subscription.createdAt).format(
                        `${i18n.get("DateFormat")} ${i18n.get("TimeFormat")}`
                      )
                    : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageSubscriptionDetails.SubscriptionBoxPlanLabel"
                    )}
                    :
                  </div>
                  {subscription.subscriptionPlan
                    ? subscription.subscriptionPlan?.code ? 
                      `${subscription.subscriptionPlan?.name} - ${subscription.subscriptionPlan?.code}`
                      : subscription.subscriptionPlan?.name
                    : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageSubscriptionDetails.SubscriptionBoxBranchLabel"
                    )}
                    :
                  </div>
                  {subscription.branch ? subscription.branch?.name || "-" : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageSubscriptionDetails.SubscriptionBoxSubscriberLabel"
                    )}
                    :
                  </div>
                  {subscription.subscriber
                    ? `${subscription.subscriber?.document || "-"} - ${
                        subscription.subscriber?.firstName || "-"
                      } ${subscription.subscriber?.lastName || "-"}`
                    : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageSubscriptionDetails.SubscriptionBoxEmailLabel"
                    )}
                    :
                  </div>
                  {subscription.subscriber
                    ? subscription.subscriber?.email || "-"
                    : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageSubscriptionDetails.SubscriptionBoxPhoneLabel"
                    )}
                    :
                  </div>
                  {subscription.subscriber
                    ? `(${getPhoneAreaFromCountry(subscription.subscriber?.phoneCountry, countries)}) ${subscription.subscriber?.phoneNumber}` || "-"
                    : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageSubscriptionDetails.SubscriptionBoxStatusLabel"
                    )}
                    :
                  </div>
                  {subscription.subscriptionStatus ? (
                    <StatusPill data={subscription.subscriptionStatus} female />
                  ) : (
                    "-"
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageSubscriptionDetails.SubscriptionBoxPlanAmountLabel"
                    )}
                    :
                  </div>
                  {subscription.amount
                    ? `${
                        i18n.get(
                          CURRENCY_FILTER_OPTIONS.find(
                            (c) => c.id === subscription.currency
                          )?.name
                        ) || ""
                      } ${Utils.formatCurrency(subscription.amount)}`
                    : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageSubscriptionDetails.SubscriptionBoxCollectedAmountLabel"
                    )}
                    :
                  </div>
                  {subscription.collectedAmount
                    ? `${
                        i18n.get(
                          CURRENCY_FILTER_OPTIONS.find(
                            (c) => c.id === subscription.currency
                          )?.name
                        ) || ""
                      } ${Utils.formatCurrency(subscription.collectedAmount)}`
                    : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageSubscriptionDetails.SubscriptionBoxLastChargeStatusLabel"
                    )}
                    :
                  </div>
                  {subscription.lastPaymentStatus
                    ? <StatusPill data={subscription.lastPaymentStatus} female />
                    : "-"}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>
                    {i18n.get(
                      "ProvidersSubscriptionsManageSubscriptionDetails.SubscriptionBoxExternalIdLabel"
                    )}
                    :
                  </div>
                  {subscription.externalId || "-"}
                </div>
              </Grid>
            </Grid>
            {canManage ? (
              <div className={classes.actionsContainer}>
                {subscription.subscriptionStatus !== "CANCELLED" ? (
                  <Button
                    variant="outlined"
                    onClick={handleOnToggleSubscriptionStatus("CANCELLED")}
                  >
                    {i18n.get("Buttons.CANCEL")}
                  </Button>
                ) : null}
                {subscription.subscriptionStatus === "ACTIVE" ? (
                  <Button
                    onClick={handleOnToggleSubscriptionStatus("PAUSED")}
                  >
                    {i18n.get(
                      "ProvidersSubscriptionsManageSubscriptionDetails.ButtonPause"
                    )}
                  </Button>
                ) : subscription.subscriptionStatus === "PAUSED" ? (
                  <Button
                    onClick={handleOnToggleSubscriptionStatus("REACTIVATE")}
                  >
                    {i18n.get(
                      "ProvidersSubscriptionsManageSubscriptionDetails.ButtonResume"
                    )}
                  </Button>
                ) : null}
              </div>
          ) : null}
          </ContentBox>
          {subscription?.additionalFields &&
          subscription?.additionalFields.length > 0 ? (
            <React.Fragment>
              <BoxSeparator size="small" />
              <ContentBox
                title={i18n.get(
                  "ProvidersSubscriptionsManageSubscriptionDetails.SubscriptionBoxAdditionalDataLabel"
                )}
              >
                {subscription?.additionalFields.map(
                  (field, key) => (
                    <Grid key={key} container spacing={4}>
                      <Grid item xs={12} sm={12} md={4}>
                        <div className={classes.suscriptionDetailsItem}>
                          <div>
                            {i18n.get(
                              "ProvidersSubscriptionsManageSubscriptionDetails.SubscriptionBoxAdditionalDataNameLabel"
                            )}
                            :
                          </div>
                          {field.name || "-"}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <div className={classes.suscriptionDetailsItem}>
                          <div>
                            {i18n.get(
                              "ProvidersSubscriptionsManageSubscriptionDetails.SubscriptionBoxAdditionalDataInternalNameLabel"
                            )}
                            :
                          </div>
                          {field.key || "-"}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <div className={classes.suscriptionDetailsItem}>
                          <div>
                            {i18n.get(
                              "ProvidersSubscriptionsManageSubscriptionDetails.SubscriptionBoxAdditionalDataValueLabel"
                            )}
                            :
                          </div>
                          {subscription?.additionalFieldsValues?.find(f => f.additionalFieldTraceId === field.additionalFieldTraceId)?.value || '-'}
                        </div>
                      </Grid>
                    </Grid>
                  )
                )}
              </ContentBox>
            </React.Fragment>
          ) : null}
          <BoxSeparator size="small" />
          <ContentBox
            title={i18n.get(
              "ProvidersSubscriptionsManageSubscriptionDetails.SubscriptionTransactionsGridBoxLabel"
            )}
          >
            {renderTransactions()}
          </ContentBox>
          {canManage ? (
            <Confirm
              open={showToggleSubscriptionStatusDialog}
              onClose={toggleToggleSubscriptionStatusDialog}
              title={
                subscriptionStatusToToggle === "PAUSED"
                  ? i18n.get(
                      "ProvidersSubscriptionsManageSubscriptionDetails.ToggleSubscriptionStatusDialogTitlePause"
                    )
                  : subscriptionStatusToToggle === "REACTIVATE"
                  ? i18n.get(
                      "ProvidersSubscriptionsManageSubscriptionDetails.ToggleSubscriptionStatusDialogTitleActivate"
                    )
                  : i18n.get(
                      "ProvidersSubscriptionsManageSubscriptionDetails.ToggleSubscriptionStatusDialogTitleCancel"
                    )
              }
              confirmText={i18n.get("Buttons.CONFIRM")}
              onConfirm={confirmToggleSubscriptionStatus}
              cancelText={i18n.get("Buttons.CANCEL")}
              onCancel={toggleToggleSubscriptionStatusDialog}
            >
              <Alert
                severity="error"
                icon={false}
                className={clsx(classes.alert, classes.alertDanger)}
              >
                <div>
                  <ErrorOutlineOutlinedIcon className={classes.alertIcon} />
                </div>
                <div>
                  <AlertTitle className={classes.alertTitle}>
                    {subscriptionStatusToToggle === "PAUSED"
                      ? i18n.get(
                          "ProvidersSubscriptionsManageSubscriptionDetails.ToggleSubscriptionStatusDialogContentTitlePause"
                        )
                      : subscriptionStatusToToggle === "REACTIVATE"
                      ? i18n.get(
                          "ProvidersSubscriptionsManageSubscriptionDetails.ToggleSubscriptionStatusDialogContentTitleActivate"
                        )
                      : i18n.get(
                          "ProvidersSubscriptionsManageSubscriptionDetails.ToggleSubscriptionStatusDialogContentTitleCancel"
                        )}
                  </AlertTitle>
                  {i18n.get(
                    "ProvidersSubscriptionsManageSubscriptionDetails.ToggleSubscriptionStatusDialogContentText"
                  )}
                </div>
              </Alert>
            </Confirm>
          ) : null}
        </React.Fragment>
      ) : null}
    </Page>
  );
};

ProvidersSubscriptionsManageSubscriptionDetailsScreen.id =
  "com.Handy.ProvidersSubscriptionsManageSubscriptionDetails";

export default ProvidersSubscriptionsManageSubscriptionDetailsScreen;
