import { makeStyles } from "@material-ui/core";
import { Colors } from "../../utils";

const makeClasses = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  label: {
    fontSize: theme.fontSize.M,
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentText,
    marginRight: theme.spacing(2),
    borderBottom: `1px solid ${Colors.rgb.contentTextSoft}`,

    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginBottom: theme.spacing(1),
    },
  },
  selectContainer: {
    height: 35,
    textAlign: "left",
    fontSize: theme.fontSize.M,
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentText,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexWrap: "wrap",
      height: "auto",
    },
  },
  selectContainerColumn: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  selectOption: {
    fontSize: theme.fontSize.M,
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.contentText,
    fontWeight: "normal",
    textTransform: "none",
    background: `${Colors.rgb.clickElement} !important`,
    marginRight: `2px !important`,
    border: "none",

    "&:last-child": {
      marginRight: `0 !important`,
    },

    [theme.breakpoints.down("sm")]: {
      marginTop: `2px !important`,
      height: 35,
      padding: theme.spacing(2),
      fontSize: theme.fontSize.S,
      borderRadius: '5px !important'
    },
  },
  selectOptionFlex: {
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      display: "flex",
    },
  },
  selectOptionSelected: {
    background: `${Colors.rgb.clickElementHighlighted} !important`,
    color: `${Colors.rgb.white} !important`,
  },
}));

export default makeClasses;
