import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  dialog: {
    boxShadow: 'none',
    width: '40%',
    maxWidth: '40%',
    minHeight: 500,

    [theme.breakpoints.down('sm')]: {
      width: '95%',
      maxWidth: '95%',
      margin: 0,
      minHeight: '50%',
    },
  },
  dialogTitleWrapper: {
    background: Colors.rgb.black_light,
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,

    '& > h2': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
  },
  dialogTitle: {
    fontFamily: theme.fontFamily.light,
    fontSize: theme.fontSize.XL,
    color: Colors.rgb.white,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: theme.fontSize.M,
    },
  },
  dialogContentWrapper: {
    padding: theme.spacing(4),
  },
  dialogContent: {
    fontFamily: theme.fontFamily.light,
    fontSize: theme.fontSize.L,
    color: Colors.rgb.contentText,

    '& strong': {
      fontFamily: theme.fontFamily.bold,
    },

    '& a': {
      color: Colors.rgb.primary,
    },
  },
  dialogActionsWrapper: {
    padding: `${theme.spacing(2)} 0`,
    margin: `0 ${theme.spacing(4)}`,
    borderTop: `1px solid ${Colors.rgb.contentLineSoft}`,

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: theme.spacing(2),

      "& > div": {
        width: "100%",
        justifyContent: "center",
      },
    },
  },
}));

export default makeClasses;
