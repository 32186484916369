import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = ({ displayAsSales }) =>
  makeStyles((theme) => ({
    tableCellDateTimeWrapper: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        alignItems: 'center',

        '& > div': {
          '&:nth-child(2)': {
            marginLeft: theme.spacing(1),
            fontSize: `${theme.fontSize.M} !important`,
          },
        },
      },
    },
    tableCellDateTime: {
      fontSize: theme.fontSize.M,
      color: Colors.rgba(Colors.rgb.black, 50),
    },
    tableHeadWithLineSeparator: {
      borderBottom: `1px solid ${Colors.rgb.contentLineSoft}`,
    },
    tableHeadCellWrapper: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      borderBottom: 'none',
    },
    tableHeadCell: {
      display: 'flex',
      flexDirection: 'row',
      justifyItems: 'center',
      alignItems: 'center',
      color: Colors.rgb.contentTextHighlighted,
      fontSize: theme.fontSize.M,
      fontFamily: theme.fontFamily.semibold,
    },
    tableHeadCellRightAlign: {
      justifyContent: 'flex-end',
    },
    tableHeadCellSortWrapper: {
      height: 18,
      width: 18,
      marginLeft: 3,
      display: 'flex',
      justifyItems: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'relative',
    },
    tableHeadCellSort: {
      height: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 12,
      cursor: 'pointer',
      position: 'absolute',

      '&:hover': {
        '& svg': {
          color: Colors.rgb.black,
        },
      },
    },
    tableHeadCellSortAsc: {
      top: -2,
    },
    tableHeadCellSortDesc: {
      bottom: -2,
    },
    tableBodyCellWrapper: {
      padding: theme.spacing(2),
      fontFamily: theme.fontFamily.regular,
      fontSize: theme.fontSize.M,
      color: Colors.rgb.contentText,
      borderBottom: 'none',
    },
    tableBodyCellWrapperFlex: {
      display: 'flex',
    },
    pagination: {
      marginTop: 20,
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
      alignitems: 'center',
    },
    paginationList: {
      '& li > button': {
        background: Colors.rgb.white,
      },
    },
    paginationSimple: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(3),

      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(3),
        borderTop: `1px solid ${Colors.rgb.contentLine}`,
      },
    },
    exportButtonWrapper: {
      width: 100,
      position: 'absolute',
      right: 15,
      marginTop: -58,
    },
    exportButton: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '&> div': {
        paddingTop: 3,
        marginRight: 5,
      },

      '&:hover': {
        opacity: 0.7,
      },
    },
    tableBodyCellInlineDetailsWrapper: {
      padding: theme.spacing(2),
    },
    boxesContainer: {
      marginTop: displayAsSales ? `-${theme.spacing(2)}` : null,
    },
    boxContainer: {
      paddingTop: displayAsSales ? theme.spacing(3) : null,
      paddingBottom: displayAsSales ? theme.spacing(3) : null,
      paddingLeft: displayAsSales ? theme.spacing(4) : null,
      paddingRight: displayAsSales ? theme.spacing(4) : null,
      marginLeft: displayAsSales ? `-${theme.spacing(2)}` : null,
      marginRight: displayAsSales ? `-${theme.spacing(2)}` : null,
      position: 'relative',
      background: Colors.rgb.grey,

      [theme.breakpoints.down('sm')]: {
        '&:first-child': {
          marginTop: `-${theme.spacing(2)} !important`,
        },
      },
    },
    boxContainerClickable: {
      cursor: 'pointer',
      '&:hover': {
        background: Colors.rgb.contentLineSoft,
      },
    },
    boxContainerHighlighted: {
      position: 'relative',

      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        width: 4,
        backgroundColor: Colors.rgb.primary,
      },
    },
    rowClickable: {
      cursor: 'pointer',
      '&:hover': {
        background: Colors.rgb.contentLineSoft,
      },
    },
    rowLineSeparator: {
      backgroundColor: `${Colors.rgb.white} !important`,
      borderBottom: `1px solid ${Colors.rgb.contentLineSoft}`,

      '&:last-child': {
        borderBottom: 0,
      }
    },
    rowHighlighted: {
      position: 'relative',
      paddingLeft: theme.spacing(4),

      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        width: 4,
        backgroundColor: Colors.rgb.primary,
      },
    },
    boxContainerSm: {
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
      margin: `0 -${theme.spacing(4)}`,
      borderBottom: `1px solid ${Colors.rgb.contentLineSoft}`,

      '&:last-child': {
        borderBottom: 0,
      },

      [theme.breakpoints.down('sm')]: {
        margin: `0 -${theme.spacing(2)}`,
        padding: theme.spacing(2),
      },
    },
    boxContainerEven: {
      background: Colors.rgb.white,
    },
    boxItemSeparator: {
      height: 1,
      width: '100%',
      background: displayAsSales ? Colors.rgb.contentLineSoft : Colors.rgb.contentLine,
      margin: displayAsSales ? null : `${theme.spacing(3)} 0`,
    },
    boxItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),

      '& > span': {
        fontFamily: theme.fontFamily.regular,
        color: Colors.rgb.contentTextSoft,

        '&:last-child': {
          marginLeft: theme.spacing(2),
          color: Colors.rgb.contentText,
        },
      },

      '&:last-child': {
        marginBottom: 0,
      },

      [theme.breakpoints.down('sm')]: {
        '& > span': {
          fontSize: theme.fontSize.M,
        },
      },
    },
    boxItemCompact: {
      fontSize: theme.fontSize.M,
    },
    boxItemAsSales: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',

      '& > div': {
        fontSize: theme.fontSize.S,
        color: Colors.rgb.contentText,

        '&:nth-child(1)': {
          width: '80%',
          display: 'flex',

          '& > span': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
          },
        },
        '&:nth-child(2)': {
          width: '20%',
          fontSize: theme.fontSize.M,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',

          '& > div:nth-child(1)': {
            marginBottom: theme.spacing(1),
          },
        },
      },
    },
    small: {
      fontSize: theme.fontSize.XS,
    },
    green: {
      color: `${Colors.rgb.number_green} !important`,
    },
    red: {
      color: `${Colors.rgb.number_red} !important`,
    },
    tableCellStatus: {
      display: 'inline-block',
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      borderRadius: 5,
      fontSize: theme.fontSize.S,
    },
    tableCellStatusGreen: {
      background: Colors.rgb.alertSuccessBackground,
      border: `1px solid ${Colors.rgb.alertSuccessBorder}`,
      color: `${Colors.rgb.alertSuccessText} !important`,
    },
    tableCellStatusRed: {
      background: Colors.rgb.alertDangerBackground,
      border: `1px solid ${Colors.rgb.alertDangerBorder}`,
      color: `${Colors.rgb.alertDangerText} !important`,
    },
    tableCellStatusYellow: {
      background: Colors.rgb.alertWarningBackground,
      border: `1px solid ${Colors.rgb.alertWarningBorder}`,
      color: `${Colors.rgb.alertWarningText} !important`,
    },
    tableCellStatusGrey: {
      background: Colors.rgb.alertIdleBackground,
      border: `1px solid ${Colors.rgb.alertIdleBorder}`,
      color: `${Colors.rgb.alertIdleText} !important`,
    },
    boxDetails: {
      position: 'absolute',
      top: 20,
      right: 20,
    }
  }));

export default makeClasses;
