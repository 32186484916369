import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { Language } from '../../utils';

import makeClasses from './styles';

const StatusPill = ({ data, small = false, children, female = false }) => {
  const classes = makeClasses();

  const { languageTexts } = useSelector((state) => ({
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  let statusClassName = classes.statusGrey;

  if (typeof data === 'boolean' && data) {
    statusClassName = classes.statusGreen;
  } else if (['ACTIVE', 'APPROVED', 'COMPLETED', 'CHARGED', 'PAYED', 'ASSIGNED', 'UP_TO_DATE'].includes(data)) {
    statusClassName = classes.statusGreen;
  } else if (['FAILED', 'INACTIVE', 'RENOVATION_FAILED', 'ERROR', 'REJECTED', 'INVALID', 'BLOCKED'].includes(data)) {
    statusClassName = classes.statusRed;
  } else if (
    [
      'PENDING',
      'IN_PROGRESS',
      'PARTIAL_EXECUTED',
      'PENDING_MANUAL_APPROVAL',
      'PENDING_APPROVAL',
      'PENDING_CHARGE',
      'PENDING_ASSIGNMENT',
      'OUTDATED',
    ].includes(data)
  ) {
    statusClassName = classes.statusYellow;
  }

  let label = i18n.get(`Statuses.${data}`);

  if (female && !i18n.get(`Statuses.${data}_F`).startsWith('MISSING_TEXT_')) {
    label = i18n.get(`Statuses.${data}_F`);
  }

  return (
    <div className={clsx(classes.status, small ? classes.statusSmall : null, statusClassName)}>
      {children || label}
    </div>
  );
};

export default StatusPill;
