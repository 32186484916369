const ROUTES = {
  LOGIN: {
    id: 'login',
    path: '/login',
  },
  REGISTER: {
    id: 'central_register',
    path: '/registro',
  },
  PHYSICAL_PERSON_REGISTRATION: {
    id: 'register',
    path: '/registro-persona',
  },
  LEGAL_ENTITY_REGISTRATION: {
    id: 'legal_entity_registration',
    path: '/registro-empresa',
  },
  HOME: {
    id: 'home',
    path: '/inicio',
  },
  PAYMENT_DETAILS_BY_DAY: {
    id: 'details_by_day',
    path: '/pagos/detalles/:date/:branchId?',
  },
  YOUR_MONEY: {
    id: 'your_money',
    path: '/tu-dinero',
  },
  OPERATION_DETAILS: {
    id: 'operation_details',
    path: '/operacion/:operationId',
  },
  ACTIVITY: {
    id: 'activity',
    path: '/actividad',
  },
  ACCOUNT: {
    id: 'account',
    path: '/cuenta-handy',
  },
  ACCOUNT_DETAILS: {
    id: 'account_details',
    path: '/cuenta-handy/:accountTraceId',
  },
  REVERSALS: {
    id: 'reversals',
    path: '/contracargos-rechazos',
  },
  VOUCHERS: {
    id: 'vouchers',
    path: '/comprobantes',
  },
  LOANS: {
    id: 'loans',
    path: '/prestamos-pym',
  },
  MOBILE_BALANCE: {
    id: 'mobile_balance',
    path: '/recarga-celulares',
  },
  LOAN_DETAILS: {
    id: 'loan_details',
    path: '/prestamos-pym/:loanId/cuotas',
  },
  HISTORY: {
    id: 'history',
    path: '/historial-de-retiros',
  },
  PAYMENT_LINK: {
    id: 'payment_link',
    path: '/handy-link',
  },
  PAYMENT_LINK_NEW: {
    id: 'payment_link',
    path: '/handy-link/nuevo',
  },
  PAYMENT_LINK_MODIFY: {
    id: 'payment_link',
    path: '/handy-link/:traceId/:modificator',
  },
  PAYMENT_LINK_VIEW: {
    id: 'payment_link',
    path: '/handy-link/:traceId',
  },
  PAYMENT_LINK_SHARE: {
    id: 'payment_link',
    path: '/handy-link/:traceId/compartir',
  },
  HANDY_QR: {
    id: 'qr',
    path: '/handy-qr',
  },
  HANDY_TAP: {
    id: 'handy-tap',
    path: '/handy-tap',
  },
  PROFILE: {
    id: 'profile',
    path: '/perfil',
  },
  ITAU: {
    id: 'itau',
    path: '/financiacion-itau',
  },
  ITAU_SALE: {
    id: 'itau-sale',
    path: '/nueva-venta-itau',
  },
  MANAGE_USERS: {
    id: 'manage-user',
    path: '/administrador-usuarios',
  },
  MANAGE_USERS_ADD: {
    id: 'manage-user-add',
    path: '/administrador-usuarios/nuevo',
  },
  MANAGE_USERS_EDIT: {
    id: 'manage-user-edit',
    path: '/administrador-usuarios/editar/:traceId',
  },
  MANAGE_USERS_CHANGE_PASSWORD: {
    id: 'manage-user-change-password',
    path: '/administrador-usuarios/cambiar-contrasena/:traceId',
  },
  MANAGE_PAYMENT_METHODS: {
    id: 'manage-payment-method',
    path: '/metodos-de-pago',
  },
  MANAGE_PAYMENT_METHODS_ADD: {
    id: 'manage-payment-method-add',
    path: '/metodos-de-pago/nuevo',
  },
  MANAGE_POS_REQUESTS: {
    id: 'manage-pos-requests',
    path: '/solicitudes-de-pos',
  },
  MANAGE_POS_REQUESTS_ADD: {
    id: 'manage-pos-requests-add',
    path: '/solicitudes-de-pos/nuevo',
  },
  MANAGE_HANDY_TAP: {
    id: 'manage-handy-tap',
    path: '/administracion-handy-tap',
  },
  MANAGE_HANDY_TAP_ADD: {
    id: 'manage-handy-tap-add',
    path: '/administracion-handy-tap/nuevo',
  },
  MANAGE_PROVIDERS_PAYMENTS: {
    id: 'manage-providers-payments',
    path: '/pago-proveedores/suscripciones',
  },
  MANAGE_PROVIDERS_PAYMENTS_SUBSCRIPTIONS: {
    id: 'manage-providers-payments-subscriptions',
    path: '/pago-proveedores/suscripciones',
  },
  MANAGE_PROVIDERS_PAYMENTS_SUBSCRIPTIONS_ADD: {
    id: 'manage-providers-payments-subscriptions-add',
    path: '/pago-proveedores/suscripciones/nueva',
  },
  MANAGE_PROVIDERS_PAYMENTS_SUBSCRIPTIONS_EDIT: {
    id: 'manage-providers-payments-subscriptions-edit',
    path: '/pago-proveedores/suscripciones/:traceId/editar',
  },
  MANAGE_PROVIDERS_PAYMENTS_SUBSCRIPTIONS_VIEW: {
    id: 'manage-providers-payments-subscriptions-view',
    path: '/pago-proveedores/suscripciones/:traceId',
  },
  MANAGE_PROVIDERS_PAYMENTS_SUBSCRIPTIONS_PAYMENT_DETAILS: {
    id: 'manage-providers-payments-subscriptions-payment-details',
    path: '/pago-proveedores/suscripciones/:traceId/payment/:paymentTraceId',
  },
  MANAGE_PROVIDERS_PAYMENTS_PAYMENTS: {
    id: 'manage-providers-payments-payments',
    path: '/pago-proveedores/pagos',
  },
  MANAGE_PROVIDERS: {
    id: 'manage-providers',
    path: '/cobranza-proveedores/servicios',
  },
  MANAGE_PROVIDERS_SUBSCRIPTIONS: {
    id: 'manage-providers-subscriptions',
    path: '/cobranza-suscripciones/planes',
  },
  MANAGE_PROVIDERS_SUBSCRIPTIONS_PLANS: {
    id: 'manage-providers-subscriptions-plans',
    path: '/cobranza-suscripciones/planes',
  },
  MANAGE_PROVIDERS_SUBSCRIPTIONS_PLANS_ADD: {
    id: 'manage-providers-subscriptions-plans-add',
    path: '/cobranza-suscripciones/planes/nuevo',
  },
  MANAGE_PROVIDERS_SUBSCRIPTIONS_PLAN_EDIT: {
    id: 'manage-providers-subscriptions-plans-edit',
    path: '/cobranza-suscripciones/planes/:traceId/editar',
  },
  MANAGE_PROVIDERS_SUBSCRIPTIONS_PLAN_VIEW: {
    id: 'manage-providers-subscriptions-plans-view',
    path: '/cobranza-suscripciones/planes/:traceId',
  },
  MANAGE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS: {
    id: 'manage-providers-subscriptions-subscriptions',
    path: '/cobranza-suscripciones/suscripciones',
  },
  MANAGE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS_VIEW: {
    id: 'manage-providers-subscriptions-subscriptions-view',
    path: '/cobranza-suscripciones/suscripciones/:traceId',
  },
  MANAGE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS_EDIT: {
    id: 'manage-providers-subscriptions-subscriptions-edit',
    path: '/cobranza-suscripciones/suscripciones/:traceId/editar',
  },
  MANAGE_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS: {
    id: 'manage-providers-subscriptions-transactions',
    path: '/cobranza-suscripciones/cobros',
  },
  MANAGE_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS_VIEW: {
    id: 'manage-providers-subscriptions-transactions-view',
    path: '/cobranza-suscripciones/cobros/:traceId',
  },
  MANAGE_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS: {
    id: 'manage-providers-subscriptions-promotions',
    path: '/cobranza-suscripciones/promociones',
  },
  MANAGE_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS_ADD: {
    id: 'manage-providers-subscriptions-promotions-add',
    path: '/cobranza-suscripciones/promociones/nueva',
  },
  MANAGE_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS_VIEW: {
    id: 'manage-providers-subscriptions-promotions-view',
    path: '/cobranza-suscripciones/promociones/:traceId',
  },
  MANAGE_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS: {
    id: 'manage-providers-subscriptions-terms',
    path: '/cobranza-suscripciones/terminos-y-condiciones',
  },
  MANAGE_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS_ADD: {
    id: 'manage-providers-subscriptions-terms-add',
    path: '/cobranza-suscripciones/terminos-y-condiciones/nuevo',
  },
  MANAGE_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS_EDIT: {
    id: 'manage-providers-subscriptions-terms-edit',
    path: '/cobranza-suscripciones/terminos-y-condiciones/:traceId/editar',
  },
  MANAGE_PROVIDERS_SERVICES: {
    id: 'manage-providers-services',
    path: '/cobranza-proveedores/servicios',
  },
  MANAGE_PROVIDERS_SERVICES_EDIT: {
    id: 'manage-providers',
    path: '/cobranza-proveedores/servicios/:traceId/editar',
  },
  MANAGE_PROVIDERS_SERVICES_VIEW: {
    id: 'manage-providers',
    path: '/cobranza-proveedores/servicios/:traceId',
  },
  MANAGE_PROVIDERS_SUBSCRIBERS: {
    id: 'manage-providers-subscribers',
    path: '/cobranza-proveedores/suscriptores',
  },
  MANAGE_PROVIDERS_SUBSCRIBERS_VIEW: {
    id: 'manage-providers-subscribers-view',
    path: '/cobranza-proveedores/suscriptores/:traceId',
  },
  MANAGE_PROVIDERS_SUBSCRIBERS_NEW_CHARGE: {
    id: 'manage-providers-subscribers-charge-new',
    path: '/cobranza-proveedores/suscriptores/:traceId/nueva-solicitud-de-cobro',
  },
  MANAGE_PROVIDERS_SUBSCRIBERS_CHARGE_DETAILS: {
    id: 'manage-providers-subscribers-charge-details',
    path: '/cobranza-proveedores/suscriptores/:traceId/cobro/:chargeTraceId',
  },
  MANAGE_PROVIDERS_CHARGES: {
    id: 'manage-providers-charges',
    path: '/cobranza-proveedores/cobros',
  },
  ECOMMERCE: {
    id: 'ecommerce',
  },
  NOTIFICATIONS: {
    id: 'notifications',
    path: '/notificaciones',
  },
  NOTIFICATION_DETAILS: {
    id: 'notification-details',
    path: '/notificaciones/:notificationId',
  }
};

export const ROUTES_IDS = Object.keys(ROUTES).map((key) => ROUTES[key].id);

export default ROUTES;
