import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import makeClasses from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { Language } from '../../utils';
import Markdown from 'markdown-to-jsx';
import Button from '../Button';
import { push } from 'connected-react-router';
import ROUTES from '../../routes';
import { business as businessActions } from '../../actions';
import { toast } from 'react-toastify';

const NoTCModal = ({ open, onClose, branchTraceId }) => {
  const classes = makeClasses();
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);

  const { languageTexts } = useSelector(({ language }) => ({
    languageTexts: language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const handleOnClickUploadTermsAndConditions = () => {
    dispatch(
      push(
        ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS_ADD.path + '?branchTraceId=' + branchTraceId + '&redirectTo=' + ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PLANS.path
      )
    );
  };

  const handleOnClickUseHandyTermsAndConditions = async () => {
    setLoading(true);
    try {
      const response = await fetch('/handy_tc.pdf');
      const blob = await response.blob();
      const file = new File([blob], 'handy_tc.pdf', { type: 'application/pdf' });

      dispatch(
        businessActions.providersSubscriptionsAddTermsAndConditions(branchTraceId, file, (result) => {
          setLoading(false);
          if (result) {
            toast.success(i18n.get('Components.NoTCModal.SuccessAddMessage'));
            onClose();
            setTimeout(() => dispatch(push(ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PLANS.path)), 400);
          }
        })
      );
    } catch (error) {
      toast.error(i18n.get('Components.NoTCModal.ErrorAddMessage'));
    }
  }

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitleWrapper}>
        <div className={classes.dialogTitle}>
          {i18n.get('Components.NoTCModal.HeaderTitle')}
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContentWrapper}>
        <div className={classes.dialogContent}>
          <Markdown>
            {i18n.get('Components.NoTCModal.BodyText')}
          </Markdown>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActionsWrapper}>
        <Button disabled={loading} onClick={handleOnClickUploadTermsAndConditions}>{i18n.get('Components.NoTCModal.ButtonOwnTC')}</Button>
        <Button disabled={loading} loading={loading} onClick={handleOnClickUseHandyTermsAndConditions}>{i18n.get('Components.NoTCModal.ButtonHandyTC')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NoTCModal;
