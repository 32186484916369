import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
  viewFilterWrapper: {
    display: 'flex',
    alignItems: 'center',

    '& > div:nth-child(1)': {
      color: Colors.rgb.contentTextHighlighted,
      fontSize: theme.fontSize.XL,
      fontFamily: theme.fontFamily.bold,
      marginRight: theme.spacing(4),
    },

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(3),

      '& > div': {
        width: '100%',

        '&:first-child': {
          marginBottom: theme.spacing(1),
        },
      }
    }
  },
  boxContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  gridWrapper: {
    marginTop: theme.spacing(3),
    borderTop: `1px solid ${Colors.rgb.contentLineSoft}`,
  },
  notificationItemWrapper: {
    position: 'relative',

    '& p': {
      '&:first-child': {
        marginTop: 0
      },
      '&:last-child': {
        marginBottom: 0
      }
    },
  },
  notificationItemRead: {
    '& > div': {
      paddingLeft: theme.spacing(2),
    },

    [theme.breakpoints.down('sm')]: {
      '& > div': {
        paddingLeft: 0
      },
    }
  },
  notificationItemTitle: {
    marginBottom: theme.spacing(1),

    '& *': {
      fontSize: theme.fontSize.M,
      fontFamily: theme.fontFamily.bold,
      color: Colors.rgb.contentTextHighlighted,

      '&:not(ul):not(ol)': {
        margin: 0,
        padding: 0
      }
    }
  },
  notificationItemSummary: {
    '& *': {
      fontSize: theme.fontSize.S,
      color: Colors.rgb.contentText,

      '&:not(ul):not(ol)': {
        margin: 0,
        padding: 0
      }
    }
  },
  notificationDateTimeWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      fontSize: `${theme.fontSize.S} !important`,

      '& > div': {
        '&:nth-child(2)': {
          marginLeft: theme.spacing(2),
          fontSize: `${theme.fontSize.S} !important`,
        },
      },
    },
  },
  notificationDateTime: {
    fontSize: theme.fontSize.M,
    color: Colors.rgba(Colors.rgb.black, 50),
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      fill: Colors.rgb.red_dark,
      marginLeft: theme.spacing(1),
    }
  },
  listItemActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    '& > button': {
      width: 40,
      padding: 6
    },

    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  },
  alert: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    width: '100%',
    boxSizing: 'border-box',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },

    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1)} !important`,
      '& > div': {
        alignItems: 'flex-start',
      },
    },
  },
  alertTitle: {
    fontFamily: theme.fontFamily.bold,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.M,
    },
  },
  alertMessage: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.S,
    },
  },
  alertIcon: {
    fontSize: 50,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
    },
  },
  alertDanger: {
    background: Colors.rgb.alertDangerBackground,
    border: `1px solid ${Colors.rgb.alertDangerBorder}`,
    color: `${Colors.rgb.alertDangerText} !important`,
  },
}));

export default makeClasses;