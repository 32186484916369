import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push, replace } from "connected-react-router";
import qs from "query-string";
import _ from "lodash";

import ROUTES from "../../routes";
import {
  BoxSeparator,
  Confirm,
  ContentBox,
  FiltersToggle,
  ForbiddenSection,
  OperationsTable,
  Page,
  TitleBox,
} from "../../components";
import { user as userActions } from "../../actions";
import { Language } from "../../utils";

import ListItemText from "@material-ui/core/ListItemText";
import { Add as AddIcon, Delete as DeleteIcon } from "@material-ui/icons";
import MoreVerticalIcon from "../../static/images/icons/more_vertical.svg";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import makeClasses from "./styles";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Policies } from "../../utils/Policies";
import { toast } from "react-toastify";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

const STATE_OPTIONS = [
  {
    id: "PENDING_APPROVAL",
    label: "ManagePosRequests.StatusFilter_PENDING_APPROVAL",
  },
  {
    id: "PENDING_CHARGE",
    label: "ManagePosRequests.StatusFilter_PENDING_CHARGE",
  },
  {
    id: "PENDING_ASSIGNMENT",
    label: "ManagePosRequests.StatusFilter_PENDING_ASSIGNMENT",
  },
  { id: "ASSIGNED", label: "ManagePosRequests.StatusFilter_ASSIGNED" },
  { id: "REJECTED", label: "ManagePosRequests.StatusFilter_REJECTED" },
  { id: "CANCELLED", label: "ManagePosRequests.StatusFilter_CANCELLED" },
];

const VIEW_FILTER_OPTIONS = [
  { id: 0, label: "ManagePosRequests.ViewFilter.APPROVED_REQUESTS" },
  { id: 1, label: "ManagePosRequests.ViewFilter.NOT_APPROVED_REQUESTS" },
  { id: 2, label: "ManagePosRequests.ViewFilter.PENDING_REQUESTS" },
];

const ManagePosRequestsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const searchParams = qs.parse(history.location.search);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedView, setSelectedView] = useState(
    searchParams.view ? parseInt(searchParams.view) : 0
  );
  const [posRequests, setPosRequests] = useState([]);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(-1);
  const [paginationPage, setPaginationPage] = useState(
    searchParams.page ? parseInt(searchParams.page) : 1
  );
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);
  const [showDeletePosDialog, setShowDeletePosDialog] = useState(false);
  const [posToDelete, setPosToDelete] = useState(null);
  const { policies, authTime, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    authTime: state.user.userData.authTime || Date.now(),
    languageTexts: state.language.texts || {},
  }));
  const canManagePosRequests = policies.includes(Policies.types.SENSITIVE_DATA);

  const i18n = Language(languageTexts);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!policies || !policies.includes(Policies.types.PROFILE)) {
      setForbbidenSection(true);
    }
  }, [policies]);

  useEffect(() => {
    if (!forbiddenSection) {
      loadData();
    }
  }, [forbiddenSection, selectedView, paginationPage]);

  const loadData = () => {
    const states =
      selectedView === 0
        ? ["ASSIGNED"]
        : selectedView === 1
        ? ["CANCELLED", "REJECTED"]
        : ["PENDING_ASSIGNMENT", "PENDING_APPROVAL", "PENDING_CHARGE"];
    dispatch(
      userActions.getPosRequests(states, paginationPage, (data) => {
        if (data) {
          setPosRequests(data);
        }
      })
    );
  };

  const handleOnViewChange = (view) => {
    setSelectedView(view);
    handleOnPaginationChange(1)

    const searchParams = {};
    searchParams.view = view;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnClickNewPaymentMethod = () => {
    const exiredSession =
      Math.floor((new Date() - new Date(authTime * 1000)) / (1000 * 60)) >= 15;

    if (exiredSession) {
      return _toggleSessionExpiredModal();
    }

    dispatch(push(ROUTES.MANAGE_POS_REQUESTS_ADD.path));
  };

  const toggleMenu = (index) => (e) => {
    if (menuAnchorEl) {
      setMenuAnchorEl(null);
      setMenuOpenIndex(-1);
    } else {
      setMenuAnchorEl(e.target);
      setMenuOpenIndex(index);
    }
  };

  const handleOnPaginationChange = (e, nextPage) => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _toggleSessionExpiredModal = () => {
    setShowSessionExpiredModal(!showSessionExpiredModal);
  };

  const _logOut = () => {
    dispatch(userActions.logOut());
  };

  const handleOnDeletePosRequest = (pos) => () => {
    toggleMenu()();
    toggleDeletePosDialog();
    setPosToDelete(pos.traceId);
  };

  const toggleDeletePosDialog = () => {
    setShowDeletePosDialog(!showDeletePosDialog);
  };

  const confirmDeletePos = () => {
    toggleDeletePosDialog();
    dispatch(
      userActions.cancelPosRequest(posToDelete, (success) => {
        if (success) {
          toast.success(i18n.get("ManagePosRequests.SuccessCancelPosReques"));
          loadData();
        }
      })
    );
  };

  const renderPosRequestsHistory = () => {
    if (!posRequests || !posRequests.elements) {
      return null;
    }

    const headers = _.compact([
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get("ManagePosRequests.Grid.Column1"),
        sortable: false,
        type: "date-time",
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get("ManagePosRequests.Grid.Column2"),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get("ManagePosRequests.Grid.Column3"),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 4,
        name: i18n.get("ManagePosRequests.Grid.Column4"),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 5,
        name: i18n.get("ManagePosRequests.Grid.Column5"),
        sortable: false,
        type: "status",
      },
      selectedView !== 2
        ? null
        : {
            canHide: false,
            columnNumber: 6,
            name: i18n.get("ManagePosRequests.Grid.Column6"),
            sortable: false,
            type: "custom",
            align: "right",
            show: !isSmallScreen
          },
    ]);
    const body = (posRequests.elements || []).map((item, index) => ({
      rowId: item.traceId,
      columns: _.compact([
        {
          columnNumber: 1,
          data: item.creationDateTime,
        },
        {
          columnNumber: 2,
          data: item.branchName,
        },
        {
          columnNumber: 3,
          data: item.physicalPOSType?.name,
        },
        {
          columnNumber: 4,
          data: `${item.address?.city?.name || ""}, ${
            item.address?.street || ""
          } ${item.address?.number || ""}`.trim(),
        },
        {
          columnNumber: 5,
          data: item.state,
          customData: STATE_OPTIONS.map((o) => ({
            ...o,
            label: i18n.get(o.label),
          })),
        },
        selectedView !== 2
          ? null
          : {
              columnNumber: 6,
              data: () => {
                return (
                  <div className={classes.listItemActions}>
                    <IconButton onClick={toggleMenu(index)} disabled={!canManagePosRequests || item.state === 'PENDING_CHARGE' || item.state === 'PENDING_ASSIGNMENT'}>
                      <img src={MoreVerticalIcon} alt="More" />
                    </IconButton>
                    <Menu
                      anchorEl={menuAnchorEl}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      keepMounted
                      transformOrigin={{ vertical: "top", horizontal: "right" }}
                      onClose={toggleMenu()}
                      open={menuOpenIndex === index}
                      className={classes.listItemMenu}
                    >
                      <MenuItem
                        onClick={handleOnDeletePosRequest(item)}
                        className={classes.listItemMenuItem}
                      >
                        <ListItemIcon className={classes.menuItemIcon}>
                          <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={i18n.get(
                            "ManagePosRequests.ActionDeletePosRequest"
                          )}
                          className={classes.menuItemText}
                        />
                      </MenuItem>
                    </Menu>
                  </div>
                );
              },
            },
      ]),
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={posRequests.hasNext}
        onPaginationChange={handleOnPaginationChange}
        emptyMessage={i18n.get("ManagePayments.GridEmptyMessage")}
        showDetails={false}
      />
    );
  };

  const renderGridTitle = () => {
    return (
      <div className={classes.gridBoxTitleContainer}>
        <div className={classes.gridBoxTitleWrapper}>
          {i18n.get("ManagePosRequests.BoxUsersTitle")}
        </div>
        <div className={classes.gridBoxTitleFilterWrapper}>
          <FiltersToggle
            showLabel={false}
            showDefault={false}
            selected={selectedView}
            options={VIEW_FILTER_OPTIONS}
            onChange={handleOnViewChange}
            showAsColumnSm
          />
        </div>
      </div>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get("ManagePosRequests.Title")}
        withActivePage={ROUTES.MANAGE_POS_REQUESTS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get("ManagePosRequests.Title")}
      withActivePage={ROUTES.MANAGE_POS_REQUESTS.id}
    >
      <TitleBox
        title={i18n.get("ManagePosRequests.BoxTitle")}
        buttonsRight={_.compact([
          {
            text: isSmallScreen
              ? null
              : i18n.get("ManagePosRequests.BoxTitleButtonText"),
            icon: () => <AddIcon fontSize="small" />,
            onClick: _handleOnClickNewPaymentMethod,
          },
        ])}
      />
      <BoxSeparator />
      <ContentBox
        title={renderGridTitle}
        titleBold
        headerVertical={isSmallScreen}
      >
        {renderPosRequestsHistory()}
      </ContentBox>
      <Confirm
        open={showSessionExpiredModal}
        onClose={_toggleSessionExpiredModal}
        title={i18n.get("ManagePosRequests.ExpiredSessionDialogTitle")}
        onConfirm={_logOut}
        confirmText={i18n.get(
          "ManagePosRequests.ExpiredSessionDialogActionConfirm"
        )}
        cancelText={i18n.get(
          "ManagePosRequests.ExpiredSessionDialogActionCancel"
        )}
        onCancel={_toggleSessionExpiredModal}
      >
        <Alert
          severity="info"
          icon={false}
          className={clsx(classes.alert, classes.alertInfo)}
        >
          <div>
            <InfoOutlinedIcon className={classes.alertIcon} />
          </div>
          <div className={classes.alertMessage}>
            <AlertTitle className={classes.alertTitle}>
              {i18n.get("ManagePosRequests.ExpiredSessionDialogContentTitle")}
            </AlertTitle>
            {i18n.get("ManagePosRequests.ExpiredSessionDialogContentMessage")}
          </div>
        </Alert>
      </Confirm>
      <Confirm
        open={showDeletePosDialog}
        onClose={toggleDeletePosDialog}
        title={i18n.get('ManagePosRequests.DeletePosDialogTitle')}
        confirmText={i18n.get('ManagePosRequests.DeletePosDialogActionConfirm')}
        onConfirm={confirmDeletePos}
        cancelText={i18n.get('ManagePosRequests.DeletePosDialogActionCancel')}
        onCancel={toggleDeletePosDialog}
      >
        <Alert severity="error" icon={false} className={clsx(classes.alert, classes.alertDanger)}>
          <div>
            <ErrorOutlineOutlinedIcon className={classes.alertIcon} />
          </div>
          <div>
            <AlertTitle className={classes.alertTitle}>
              {i18n.get('ManagePosRequests.DeletePosDialogContentTitle')}
            </AlertTitle>
            {i18n.get('ManagePosRequests.DeletePosDialogContentText')}
          </div>
        </Alert>
      </Confirm>
    </Page>
  );
};

ManagePosRequestsScreen.id = "com.Handy.ManagePosRequests";

export default ManagePosRequestsScreen;
