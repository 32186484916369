import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  BoxSeparator,
  Button,
  Confirm,
  ContentBox,
  EmptyMessage,
  FiltersContainer,
  FiltersDateSelector,
  FiltersSelector,
  FiltersSeparator,
  FiltersToggle,
  ForbiddenSection,
  OperationsTable,
  Page,
  StatusPill,
  TitleBox,
} from '../../../../../components';
import ROUTES from '../../../../../routes';
import { Language, Utils } from '../../../../../utils';
import { business as businessActions } from '../../../../../actions';
import { push, replace } from 'connected-react-router';
import qs from 'query-string';
import { CUSTOM_FILTER, getDateRangeForFilter } from '../../../../../components/FiltersDateSelector';
import makeClasses from './styles';
import moment from 'moment';
import {
  Grid,
  Hidden,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { Visibility as VisisbilityIcon, Check as CheckIcon, Clear as ClearIcon } from '@material-ui/icons';
import MoreVerticalIcon from '../../../../../static/images/icons/more_vertical.svg';
import clsx from 'clsx';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { Policies } from '../../../../../utils/Policies';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { Alert, AlertTitle } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { toISOString } from '../../../../../utils/Utils';

const STATUS_FILTER_OPTIONS = [
  { id: 'CANCELED', label: 'Cancelado' },
  { id: 'COMPLETED', label: 'Pagado' },
  { id: 'ERROR', label: 'Error' },
  { id: 'PARTIAL_EXECUTED', label: 'Parcialmente pagado' },
  { id: 'IN_PROGRESS', label: 'Procesando pago' },
  { id: 'MANUAL_REJECTED', label: 'Rechazado por usuario' },
  { id: 'PENDING_MANUAL_APPROVAL', label: 'Pendiente de aprobación' },
];

const VIEW_FILTER_OPTIONS = [
  { id: 0, label: 'ManageSubscriptionsView.ViewFilter.PENDING_PAYMENTS' },
  { id: 1, label: 'ManageSubscriptionsView.ViewFilter.PAYMENTS_HISTORY' },
];

const ViewSubscriptionScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const searchParams = qs.parse(history.location.search);
  const classes = makeClasses();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const subscriptionTraceId = match && match.params ? match.params.traceId : null;

  const [subscription, setSubscription] = useState(null);
  const [showCancelSubscriptionDialog, setShowCancelSubscriptionDialog] = useState(false);
  const [subscriptionToCancel, setSubscriptionToCancel] = useState(null);
  const [pendingPayments, setPendingPayments] = useState({});
  const [paymentsHistory, setPaymentsHistory] = useState({});
  const [paginationPage, setPaginationPage] = useState(searchParams.page ? parseInt(searchParams.page) : 1);
  const [orderByField, setOrderByField] = useState(searchParams.orderByField ? searchParams.orderByField : 'CreatedAt');
  const [orderByDesc, setOrderByDesc] = useState(searchParams.orderByDesc ? searchParams.orderByDesc === 'desc' : true);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(-1);

  const [selectedFilter, setSelectedFilter] = useState(searchParams.fid ? searchParams.fid === 'custom' ? searchParams.fid : parseInt(searchParams.fid) : 0);
  const [selectedCustomFilter, setSelectedCustomFilter] = useState(
    searchParams.fid === CUSTOM_FILTER ? { startDate: searchParams.fsd, endDate: searchParams.fed } : {}
  );
  const [selectedView, setSelectedView] = useState(searchParams.view ? parseInt(searchParams.view) : 0);
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(searchParams.pid || 'all');
  const [selectedService, setSelectedService] = useState(searchParams.sid || 'all');
  const [subscriptionProvidersOptions, setSubscriptionProvidersOptions] = useState([]);
  const [subscriptionServicesOptions, setSubscriptionServicesOptions] = useState([]);
  const canExport = policies.includes(Policies.types.SUBSCRIBER) && policies.includes(Policies.types.SUPER_READ);

  useEffect(() => {
    if (!policies || !policies.includes(Policies.types.SUBSCRIBER)) {
      setForbbidenSection(true);
    }
  }, [policies]);

  useEffect(() => {
    if (!forbiddenSection) {
      loadData();
      loadFiltersData();
    }
  }, [forbiddenSection]);

  useEffect(() => {
    if (selectedView === 0) {
      loadPendingPayments();
    } else {
      loadPaymentsHistory();
    }
  }, [
    selectedView,
    paginationPage,
    orderByField,
    orderByDesc,
    selectedProvider,
    selectedService,
    selectedFilter,
    selectedCustomFilter,
  ]);

  const loadData = () => {
    if (subscriptionTraceId) {
      dispatch(
        businessActions.getSubscription(subscriptionTraceId, (data) => {
          setSubscription(data);
        })
      );
    }
  };

  const loadFiltersData = () => {
    dispatch(
      businessActions.getSubscriptionProviders((data) => {
        const options = data.elements
          ? data.elements.filter((e) => e).map((e) => ({ id: e.traceId, name: e.name }))
          : [];
        setSubscriptionProvidersOptions(options);
      })
    );
    dispatch(
      businessActions.getSubscriptionServices((data) => {
        const options = data.elements
          ? data.elements.filter((e) => e).map((e) => ({ id: e.traceId, name: e.name }))
          : [];
        setSubscriptionServicesOptions(options);
      })
    );
  };

  const loadPendingPayments = () => {
    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null,
      true
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.getPendingPayments(
        null,
        subscriptionTraceId,
        orderByField,
        orderByDesc,
        paginationPage,
        startDate,
        endDate,
        selectedProvider !== 'all' ? selectedProvider : null,
        selectedService !== 'all' ? selectedService : null,
        (data) => {
          setPendingPayments(data);
        }
      )
    );
  };

  const loadPaymentsHistory = () => {
    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null,
      true
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.getPaymentsHistory(
        null,
        subscriptionTraceId,
        orderByField,
        orderByDesc,
        paginationPage,
        startDate,
        endDate,
        selectedProvider !== 'all' ? selectedProvider : null,
        selectedService !== 'all' ? selectedService : null,
        null,
        (data) => {
          setPaymentsHistory(data);
        }
      )
    );
  };

  const toggleMenu = (index) => (e) => {
    if (menuAnchorEl) {
      setMenuAnchorEl(null);
      setMenuOpenIndex(-1);
    } else {
      setMenuAnchorEl(e.target);
      setMenuOpenIndex(index);
    }
  };

  const handleOnGoBackClick = () => {
    dispatch(push(ROUTES.MANAGE_PROVIDERS_PAYMENTS.path));
  };

  const handleOnPaymentsHistoryPaginationChange = (nextPage) => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnPaymentsHistorySortChange = (column, direction) => {
    let orderByField = '';
    let orderByDesc = false;

    if (column === 1) {
      orderByField = 'CreatedAt';
      orderByDesc = direction === 'desc';
    }

    setOrderByField(orderByField);
    setOrderByDesc(orderByDesc);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.orderByField = orderByField;
    searchParams.orderByDesc = direction;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnFilterSelected = (filter, startDate, endDate) => {
    setSelectedFilter(filter);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.fid = filter;

    if (filter === CUSTOM_FILTER) {
      setSelectedCustomFilter({ startDate, endDate });
      searchParams.fsd = startDate;
      searchParams.fed = endDate;
    }

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnViewChange = (view) => {
    setSelectedView(view);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.view = view;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnGridExport = () => {
    if (selectedFilter === 0) {
      toast.error(i18n.get('ManageSubscriptions.ExportErrorNoDateFilterSelected'));
      return;
    }
    
    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null,
      true
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    const fileName = selectedView === 0 ? 'PagoProveedores_PagosPendientes_' : 'PagoProveedores_HistorialPagos_';

    dispatch(
      businessActions.exportSubscriptionPayments(
        null,
        subscriptionTraceId,
        orderByField,
        orderByDesc,
        paginationPage,
        startDate,
        endDate,
        selectedProvider !== 'all' ? selectedProvider : null,
        selectedService !== 'all' ? selectedService : null,
        selectedView === 0,
        true,
        (file) =>
          Utils.downloadFile(
            file,
            fileName +
              dateFilter.startDate.format(i18n.get('ExportDateFormat')) +
              '_' +
              dateFilter.endDate.format(i18n.get('ExportDateFormat')) +
              '.xlsx'
          )
      )
    );
  };

  const handleOnCancelSubscription = (subscription) => () => {
    toggleCancelSubscriptionDialog();
    setSubscriptionToCancel(subscription.traceId);
  };

  const toggleCancelSubscriptionDialog = () => {
    setShowCancelSubscriptionDialog(!showCancelSubscriptionDialog);
  };

  const confirmCancelSubscription = () => {
    toggleCancelSubscriptionDialog();
    dispatch(
      businessActions.cancelSubscription(subscriptionToCancel, (result) => {
        if (result) {
          toast.success(i18n.get('ManageSubscriptions.SuccessCancelMessage'));
          loadData();
        } else {
          toast.error(i18n.get('ManageSubscriptions.ErrorCancelMessage'));
        }
      })
    );
  };

  const goToPaymentDetails = (traceId) => (e) => {
    e && e.preventDefault();

    dispatch(
      push(
        ROUTES.MANAGE_PROVIDERS_PAYMENTS_SUBSCRIPTIONS_PAYMENT_DETAILS.path
          .replace(':traceId', subscription.traceId)
          .replace(':paymentTraceId', traceId)
      )
    );
  };

  const handleOnViewPayment = (payment) => () => {
    toggleMenu()();
    goToPaymentDetails(payment.traceId)();
  };

  const handleOnApprovePayment = (payment) => () => {
    toggleMenu()();
    dispatch(
      businessActions.subscriptionApprovePayment(payment.traceId, (result) => {
        if (result) {
          toast.success(i18n.get('ManageSubscriptionsView.SuccessApproveMessage'));
          loadPendingPayments();
        } else {
          toast.error(i18n.get('ManageSubscriptionsView.ErrorApproveMessage'));
        }
      })
    );
  };

  const handleOnRejectPayment = (payment) => () => {
    toggleMenu()();
    dispatch(
      businessActions.subscriptionRejectPayment(payment.traceId, (result) => {
        if (result) {
          toast.success(i18n.get('ManageSubscriptionsView.SuccessRejectMessage'));
          loadPaymentsHistory();
        } else {
          toast.error(i18n.get('ManageSubscriptionsView.ErrorRejectMessage'));
        }
      })
    );
  };

  const handleOnProviderSelected = (provider) => {
    setSelectedProvider(provider);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.pid = provider;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnServiceSelected = (service) => {
    setSelectedService(service);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.sid = service;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const renderFiltersDateSelector = () => (
    <FiltersDateSelector
      selectedFilter={selectedFilter}
      startDate={selectedCustomFilter.startDate}
      endDate={selectedCustomFilter.endDate}
      onFilterSelected={handleOnFilterSelected}
      align="right"
      dateFormat={i18n.get('DateFormat', false)}
      showNoFilterOption
    />
  );

  const renderProvidersSelector = () => {
    return (
      <FiltersSelector
        options={subscriptionProvidersOptions}
        selected={selectedProvider}
        onSelected={handleOnProviderSelected}
        defaultOption={i18n.get('ManageSubscriptionsView.FilterProvidersDefaultOption')}
      />
    );
  };

  const renderServicesSelector = () => {
    return (
      <FiltersSelector
        options={subscriptionServicesOptions}
        selected={selectedService}
        onSelected={handleOnServiceSelected}
        defaultOption={i18n.get('ManageSubscriptionsView.FilterServicesDefaultOption')}
      />
    );
  };

  const renderViewToggle = () => (
    <FiltersToggle
      showLabel={false}
      showDefault={false}
      options={VIEW_FILTER_OPTIONS}
      onChange={handleOnViewChange}
      selected={selectedView}
    />
  );

  const renderPendingPayments = () => {
    if (!pendingPayments || !pendingPayments.elements || pendingPayments.elements.length === 0) {
      return <EmptyMessage textOnly>{i18n.get('ManageSubscriptions.GridEmptyMessage')}</EmptyMessage>;
    }

    if (isSmallScreen) {
      return (
        <div>
          {pendingPayments.elements.map((payment, index) => (
            <React.Fragment key={index}>
              {index === 0 ? null : <div className={classes.boxItemSeparator} />}
              <div className={clsx(classes.boxContainer, index % 2 === 0 ? classes.boxContainerEven : null)}>
                <div className={classes.boxItemTitle}>
                  {payment.productName} - {payment.providerName}
                </div>
                {payment.description ? (
                  <div className={classes.boxItemDescription}>
                    <span>{payment.description}</span>
                  </div>
                ) : null}
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get('ManageSubscriptions.LabelBranch')}:</span>
                  <span>{payment.branchName}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get('ManageSubscriptions.LabelRequestDate')}:</span>
                  <span>{moment(payment.createdAt).format(i18n.get('DateFormat'))}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get('ManageSubscriptions.LabelLimitDate')}:</span>
                  <span>{moment(payment.limitDate).format(i18n.get('DateFormat'))}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get('ManageSubscriptions.LabelAmount')}:</span>
                  <span>
                    {payment.currency} {Utils.formatCurrency(payment.amount)}
                  </span>
                </div>
                <div className={classes.listItemActions}>
                  <IconButton onClick={toggleMenu(index)}>
                    <img src={MoreVerticalIcon} alt="More" />
                  </IconButton>
                </div>
                <Menu
                  anchorEl={menuAnchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={menuOpenIndex === index}
                  onClose={toggleMenu()}
                  className={classes.listItemMenu}
                >
                  <MenuItem onClick={handleOnViewPayment(payment)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <VisisbilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('ManageSubscriptionsView.ActionViewPaymentDetails')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                  <MenuItem onClick={handleOnApprovePayment(payment)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <CheckIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('ManageSubscriptionsView.ActionApprovePayment')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                  <MenuItem onClick={handleOnRejectPayment(payment)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <ClearIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('ManageSubscriptionsView.ActionRejectPayment')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                </Menu>
              </div>
            </React.Fragment>
          ))}
        </div>
      );
    }

    return pendingPayments.elements.map((payment, index) => (
      <React.Fragment key={payment.traceId}>
        {index !== 0 ? <div className={classes.subscriptionItemSeparator} /> : null}
        <div className={classes.subscriptionItemWrapper}>
          <div className={classes.subscriptionItemDetails}>
            <div>
              {payment.productName} <span>|</span> {payment.providerName}{' '}
              {payment.description ? <span>| {payment.description}</span> : null}
            </div>
            <div className={classes.subscriptionItemExtraDetails}>
              <div>
                <span>{i18n.get('ManageSubscriptions.LabelBranch')}:</span> {payment.branchName}
              </div>
              <div>|</div>
              <div>
                <span>{i18n.get('ManageSubscriptions.LabelRequestDate')}:</span>
                {moment(payment.createdAt).format(i18n.get('DateFormat'))}
              </div>
              <div>|</div>
              <div>
                <span>{i18n.get('ManageSubscriptions.LabelLimitDate')}:</span>
                {moment(payment.limitDate).format(i18n.get('DateFormat'))}
              </div>
              <div>|</div>
              <div>
                <span>{i18n.get('ManageSubscriptions.LabelAmount')}:</span>
                {payment.currency} {Utils.formatCurrency(payment.amount)}
              </div>
            </div>
          </div>
          <div>
            <div className={classes.listItemActions}>
              <IconButton onClick={toggleMenu(index)}>
                <img src={MoreVerticalIcon} alt="More" />
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={menuOpenIndex === index}
                onClose={toggleMenu()}
                className={classes.listItemMenu}
              >
                <MenuItem onClick={handleOnViewPayment(payment)} className={classes.listItemMenuItem}>
                  <ListItemIcon className={classes.menuItemIcon}>
                    <VisisbilityIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={i18n.get('ManageSubscriptionsView.ActionViewPaymentDetails')}
                    className={classes.menuItemText}
                  />
                </MenuItem>
                <MenuItem onClick={handleOnApprovePayment(payment)} className={classes.listItemMenuItem}>
                  <ListItemIcon className={classes.menuItemIcon}>
                    <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={i18n.get('ManageSubscriptionsView.ActionApprovePayment')}
                    className={classes.menuItemText}
                  />
                </MenuItem>
                <MenuItem onClick={handleOnRejectPayment(payment)} className={classes.listItemMenuItem}>
                  <ListItemIcon className={classes.menuItemIcon}>
                    <ClearIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={i18n.get('ManageSubscriptionsView.ActionRejectPayment')}
                    className={classes.menuItemText}
                  />
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </React.Fragment>
    ));
  };

  const renderPaymentsHistory = () => {
    if (!paymentsHistory || !paymentsHistory.elements) {
      return null;
    }

    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get('ManageSubscriptionsView.PaymentsHistoryGrid.Column1'),
        sortable: true,
        type: 'date-time',
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get('ManageSubscriptionsView.PaymentsHistoryGrid.Column2'),
        sortable: false,
        type: 'status',
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get('ManageSubscriptionsView.PaymentsHistoryGrid.Column3'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 4,
        name: i18n.get('ManageSubscriptionsView.PaymentsHistoryGrid.Column4'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 5,
        name: i18n.get('ManageSubscriptionsView.PaymentsHistoryGrid.Column5'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 6,
        name: i18n.get('ManageSubscriptionsView.PaymentsHistoryGrid.Column6'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 7,
        name: i18n.get('ManageSubscriptionsView.PaymentsHistoryGrid.Column7'),
        sortable: false,
        type: 'text',
      },
    ];
    const body = (paymentsHistory.elements || []).map((item) => ({
      rowId: item.traceId,
      columns: [
        {
          columnNumber: 1,
          data: item.createdAt,
        },
        {
          columnNumber: 2,
          data: item.status === 'APPROVED' ? 'IN_PROGRESS' : item.status,
          customData: STATUS_FILTER_OPTIONS,
        },
        {
          columnNumber: 3,
          data: item.providerName,
        },
        {
          columnNumber: 4,
          data: item.productName,
        },
        {
          columnNumber: 5,
          data: item.branchName,
        },
        {
          columnNumber: 6,
          data: `${item.currency} ${Utils.formatCurrency(item.amount)}`,
        },
        {
          columnNumber: 7,
          data: `${item.currency} ${Utils.formatCurrency(item.dueAmount)}`,
        },
      ],
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={paymentsHistory.hasNext}
        onPaginationChange={handleOnPaymentsHistoryPaginationChange}
        emptyMessage={i18n.get('ManageSubscriptionsView.GridEmptyMessage')}
        showDetails
        onClick={goToPaymentDetails}
        onTableSortChange={handleOnPaymentsHistorySortChange}
      />
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ManageSubscriptionsView.Title')}
        withActivePage={ROUTES.MANAGE_PROVIDERS_PAYMENTS.id}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ManageSubscriptionsView.Title')}
      withActivePage={ROUTES.MANAGE_PROVIDERS_PAYMENTS.id}
    >
      <TitleBox
        title={i18n.get('ManageSubscriptionsView.BoxTitle')}
        back
        backTitle={i18n.get('ManageSubscriptionsView.BackButton')}
        onBackClick={handleOnGoBackClick}
        backButtonVariant="outlined"
      />
      <BoxSeparator type="line" width="full" noPadding />
      <ContentBox
        title={i18n.get('ManageSubscriptionsView.SubscriptionBoxTitle')}
        titleWithLeftIndicator
        button={subscription && subscription.canMerchantUnsubscribe}
        buttonText={i18n.get('ManageSubscriptionsView.SubscriptionBoxButton')}
        buttonOnClick={handleOnCancelSubscription(subscription)}
      >
        {subscription ? (
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>{i18n.get('ManageSubscriptionsView.SubscriptionBoxDateLabel')}:</div>
                {moment(subscription.createdAt).format(i18n.get('DateFormat'))}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>{i18n.get('ManageSubscriptionsView.SubscriptionBoxBranchLabel')}:</div>
                {subscription.branchName || '-'}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>{i18n.get('ManageSubscriptionsView.SubscriptionBoxProviderLabel')}:</div>
                {subscription.providerName || '-'}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>{i18n.get('ManageSubscriptionsView.SubscriptionBoxProductLabel')}:</div>
                {subscription.productName || '-'}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>{i18n.get('ManageSubscriptionsView.SubscriptionBoxDescriptionLabel')}:</div>
                {subscription.description || '-'}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>{i18n.get('ManageSubscriptionsView.SubscriptionBoxAutoApprovalLabel')}:</div>
                {subscription.autoApprovalAllowed ? 'Habilitada' : 'Inhabilitada'}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>{i18n.get('ManageSubscriptionsView.SubscriptionBoxAutoApprovalLimitLabel')}:</div>
                {subscription.autoApprovalAllowedLimit > 0
                  ? `UYU ${Utils.formatCurrency(subscription.autoApprovalAllowedLimit)}`
                  : '-'}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.suscriptionDetailsItem}>
                <div>{i18n.get('ManageSubscriptionsView.SubscriptionBoxStatusLabel')}:</div>
                <StatusPill data={subscription.isActive}>
                  {subscription.isActive
                    ? i18n.get('ManageSubscriptionsView.SubscriptionBoxStatusValueActive')
                    : i18n.get('ManageSubscriptionsView.SubscriptionBoxStatusValueInactive')}
                </StatusPill>
              </div>
            </Grid>
          </Grid>
        ) : null}
      </ContentBox>
      <BoxSeparator size="small" />
      <Hidden xsDown>
        <Box>
          <div className={classes.viewFilterWrapper}>
            <div>{i18n.get('ManageSubscriptionsView.BoxViewTitle')}</div>
            {renderViewToggle()}
          </div>
        </Box>
      </Hidden>
      <Hidden smUp>
        <ContentBox title={i18n.get('ManageSubscriptionsView.BoxViewTitle')} titleBold>
          <FiltersContainer>{renderViewToggle()}</FiltersContainer>
        </ContentBox>
      </Hidden>
      <BoxSeparator size="small" />
      <ContentBox title={i18n.get('ManageSubscriptionsView.BoxFiltersTitle')} titleBold>
        <FiltersContainer showLabel={!isSmallScreen}>
          {renderProvidersSelector()}
          <FiltersSeparator />
          {renderServicesSelector()}
          <FiltersSeparator />
          {renderFiltersDateSelector()}
        </FiltersContainer>
      </ContentBox>
      {!isSmallScreen ? <BoxSeparator type="line" /> : null}
      {selectedView === 0 ? (
        <Box direction="column">
          <div className={classes.gridTitleWrapper}>
            <div>
              <div>{i18n.get('ManageSubscriptionsView.GridTitle')}</div>
            </div>
            {canExport ? (
              <Button
                onClick={handleOnGridExport}
                rightIcon={() => <SaveAltIcon />}
                disabled={!pendingPayments || !pendingPayments.elements || pendingPayments.elements.length === 0}
              >
                {isSmallScreen ? null : i18n.get('ManageSubscriptionsView.GridExportButton')}
              </Button>
            ) : null}
          </div>
          <div className={classes.gridWrapper}>
            <div className={classes.gridSeparator} />
            {renderPendingPayments()}
          </div>
        </Box>
      ) : (
        <Box direction="column">
          <div className={classes.gridTitleWrapper}>
            <div>
              <div>{i18n.get('ManageSubscriptionsView.GridTitle')}</div>
            </div>
            {canExport ? (
              <Button
                onClick={handleOnGridExport}
                rightIcon={() => <SaveAltIcon />}
                disabled={!paymentsHistory || !paymentsHistory.elements || paymentsHistory.elements.length === 0}
              >
                {isSmallScreen ? null : i18n.get('ManageSubscriptionsView.GridExportButton')}
              </Button>
            ) : null}
          </div>
          <div className={classes.gridWrapper}>
            <div className={classes.gridSeparator} />
            {renderPaymentsHistory()}
          </div>
        </Box>
      )}
      <Confirm
        open={showCancelSubscriptionDialog}
        onClose={toggleCancelSubscriptionDialog}
        title={i18n.get('ManageSubscriptions.CancelSubscriptionDialogTitle')}
        confirmText={i18n.get('ManageSubscriptions.CancelSubscriptionDialogActionConfirm')}
        onConfirm={confirmCancelSubscription}
        cancelText={i18n.get('ManageSubscriptions.CancelSubscriptionDialogActionCancel')}
        onCancel={toggleCancelSubscriptionDialog}
      >
        <Alert severity="error" icon={false} className={clsx(classes.alert, classes.alertDanger)}>
          <div>
            <ErrorOutlineOutlinedIcon className={classes.alertIcon} />
          </div>
          <div>
            <AlertTitle className={classes.alertTitle}>
              {i18n.get('ManageSubscriptions.CancelSubscriptionDialogContentTitle')}
            </AlertTitle>
            {i18n.get('ManageSubscriptions.CancelSubscriptionDialogContentText')}
          </div>
        </Alert>
      </Confirm>
    </Page>
  );
};

ViewSubscriptionScreen.id = 'com.Handy.ViewSubscription';

export default ViewSubscriptionScreen;
