import React, { useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import makeClasses from './styles';
import { Utils, Language, Colors } from '../../utils';
import { Popover, withStyles } from '@material-ui/core';
import LocalButton from '../Button';
import DetailsIcon from '../../static/images/icons/details.svg';
import InlineDetailsIcon from '../../static/images/icons/details-inline.svg';
import EmptyMessage from '../EmptyMessage';
import ArrowLeftIcon from '../../static/images/icons/arrow_left_red.svg';
import ArrowRightIcon from '../../static/images/icons/arrow_right_green.svg';
import MoreVerticalIcon from "../../static/images/icons/more_vertical.svg";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PaginationSimple from '../PaginationSimple';
import StatusPill from '../StatusPill';

const CheckIcon = ({ color, width = 20 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={width}>
      <path fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" />
    </svg>
  )
}

const CrossIcon = ({ color, width = 20 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={width}>
      <path fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368" />
    </svg>
  )
}

const StyledTableWhiteRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: Colors.rgb.grey,
    },
    '&:nth-of-type(even)': {
      backgroundColor: Colors.rgb.white,
    },
  },
}))(TableRow);

const OperationsTableHead = ({ headers, i18n, showDetails, showInlineDetails, onSortChange, lineSeparator }) => {
  const classes = makeClasses({})();

  const [sortColumnNumber, setSortColumnNumber] = useState('');
  const [sortType, setSortType] = useState('');

  const _onSortClick = (_sortType, _columnNumber) => () => {
    if (_columnNumber === sortColumnNumber && _sortType === sortType) {
      setSortType('');
      setSortColumnNumber('');
      onSortChange('', '');
    } else {
      setSortType(_sortType);
      setSortColumnNumber(_columnNumber);
      onSortChange(_columnNumber, _sortType);
    }
  };

  const renderTableHeadCell = (header, index) => {
    const isSortAsc = sortColumnNumber === header.columnNumber && sortType === 'asc';
    const isSortDesc = sortColumnNumber === header.columnNumber && sortType === 'desc';
    const alignRight = header.align === 'right';
    const cellWidth = header.width ? header.width : 'auto';

    const tableCell = (
      <TableCell
        key={`${header.columnNumber}_${index}`}
        align={header.alignment ? header.alignment : alignRight ? 'right' : 'left'}
        className={classes.tableHeadCellWrapper}
        width={cellWidth}
      >
        <div className={clsx(classes.tableHeadCell, alignRight ? classes.tableHeadCellRightAlign : null)}>
          {header.name}
          {header.sortable ? (
            <div className={classes.tableHeadCellSortWrapper}>
              <div
                className={clsx(classes.tableHeadCellSort, classes.tableHeadCellSortAsc)}
                onClick={_onSortClick('desc', header.columnNumber)}
              >
                <ArrowDropUpIcon fontSize="small" color={isSortDesc ? 'inherit' : 'disabled'} />
              </div>
              <div
                className={clsx(classes.tableHeadCellSort, classes.tableHeadCellSortDesc)}
                onClick={_onSortClick('asc', header.columnNumber)}
              >
                <ArrowDropDownIcon fontSize="small" color={isSortAsc ? 'inherit' : 'disabled'} />
              </div>
            </div>
          ) : null}
        </div>
      </TableCell>
    );

    return header.canHide ? (
      <Hidden mdDown key={`${header.columnNumber}_${index}`}>
        {tableCell}
      </Hidden>
    ) : (
      tableCell
    );
  };

  return (
    <TableHead className={clsx(classes.tableHead, lineSeparator ? classes.tableHeadWithLineSeparator : null)}>
      <TableRow>
        {headers.map(renderTableHeadCell)}
        {showDetails ? (
          <TableCell className={classes.tableHeadCellWrapper} key={'details'} align="right">
            <div className={clsx(classes.tableHeadCell, classes.tableHeadCellRightAlign)}>
              {i18n.get('Table.DetailColumn')}
            </div>
          </TableCell>
        ) : showInlineDetails ? (
          <TableCell className={classes.tableHeadCellWrapper} key={'details'} align="right">
            <div className={clsx(classes.tableHeadCell, classes.tableHeadCellRightAlign)}>
              {i18n.get('Table.InlineDetailColumn')}
            </div>
          </TableCell>
        ) : null}
      </TableRow>
    </TableHead>
  );
};

const _getCellData = (
  columnNumber,
  cellData,
  cellType,
  cellCustomData,
  i18n,
  classes,
  isMobile,
  handleOnDownload,
  buttonTextOnly,
  buttonSize,
  buttonLabel,
  showTime = false
) => {
  if (cellType === 'date') {
    if (!cellData) {
      return <div>-</div>;
    }

    if (isMobile) {
      return (
        <div>
          {moment(cellData).format(i18n.get('DateFormat', false) || Utils.DEFAULT_DATE_FORMAT)}{' '}
          {showTime ? moment(cellData).format(i18n.get('TimeFormat', false)) : null}
        </div>
      );
    }

    return (
      <div>
        <div>{moment(cellData).format(i18n.get('DateFormat', false) || Utils.DEFAULT_DATE_FORMAT)}</div>
        {showTime ? (
          <div className={classes.tableCellDateTime}>{moment(cellData).format(i18n.get('TimeFormat', false))}</div>
        ) : null}
      </div>
    );
  } else if (cellType === 'date-time') {
    if (!cellData) {
      return <div>-</div>;
    }

    return (
      <div className={classes.tableCellDateTimeWrapper}>
        <div>{moment(cellData).format(i18n.get('DateFormat', false) || Utils.DEFAULT_DATE_FORMAT)}</div>
        <div className={classes.tableCellDateTime}>{moment(cellData).format(i18n.get('TimeFormat', false))}</div>
      </div>
    );
  } else if (cellType === 'number') {
    return <div>{Utils.formatCurrency(cellData)}</div>;
  } else if (cellType === 'number-empty') {
    return cellData ? <div>{Utils.formatCurrency(cellData)}</div> : null;
  } else if (cellType === 'number-inline') {
    return (
      <div>
        <span className={classes.small}>{i18n.get('Table.DefaultCurrency')}</span> {Utils.formatCurrency(cellData)}
      </div>
    );
  } else if (cellType === 'bin') {
    return <div>{Utils.formatCardNumber(cellData)}</div>;
  } else if (cellType === 'link') {
    return (
      <Link href={cellData} target="_blank">
        {i18n.get('Table.DetailLinkRow') || ''}
      </Link>
    );
  } else if (cellType === 'download') {
    if (isMobile) {
      return (
        <LocalButton
          variant={buttonTextOnly ? 'text-only' : 'primary'}
          size="sm"
          width={buttonTextOnly ? null : 100}
          onClick={() => handleOnDownload(cellData)}
        >
          <SaveAltIcon />
        </LocalButton>
      );
    }
    return (
      <LocalButton
        variant={buttonTextOnly ? 'text-only' : 'primary'}
        size={buttonSize || 'sm'}
        width={100}
        onClick={() => handleOnDownload(cellData)}
        rightIcon={buttonTextOnly ? null : () => <SaveAltIcon />}
      >
        {buttonLabel || i18n.get('Table.DownloadButton')}
      </LocalButton>
    );
  } else if (cellType === 'status' || cellType === 'status_f' || cellType === 'status-custom') {
    let cellDataToCheck = typeof cellData === 'boolean' ? (cellData ? 'ACTIVE' : 'INACTIVE') : cellData;
    let cellDataText = null;

    if (_.isArray(cellCustomData)) {
      const value = cellCustomData.find((i) => i.id === cellDataToCheck);
      cellDataText = value?.label || null;
    }

    let label = '';

    if (cellType === 'status-custom') {
      label = cellData;
    } else if (typeof cellData === 'boolean') {
      if (cellType === 'status_f') {
        label = i18n.get(`Statuses.${cellData ? 'ACTIVE_F' : 'INACTIVE_F'}`);
        if (label.startsWith('MISSING_TEXT_')) {
          label = i18n.get(`Statuses.${cellData ? 'ACTIVE' : 'INACTIVE'}`);
        }
      } else {
        label = i18n.get(`Statuses.${cellData ? 'ACTIVE' : 'INACTIVE'}`);
      }
    } else if (cellDataText) {
      label = cellDataText;
    } else {
      if (cellType === 'status_f') {
        label = i18n.get(`Statuses.${cellData}_F`)
        if (label.startsWith('MISSING_TEXT_')) {
          label = i18n.get(`Statuses.${cellData}`)
        }
      } else {
        label = i18n.get(`Statuses.${cellData}`)
      }
    }

    return (
      <StatusPill data={cellDataToCheck}>
        {label}
      </StatusPill>
    );
  } else if (cellType === 'custom' && typeof cellData === 'function') {
    return cellData();
  } else if (cellType === 'check') {
    return (
      <div>
        {cellData === true ? (
          <CheckIcon color="#155724" />
        ) : (
          <CrossIcon color="#721c24" />
        )}
      </div>
    );
  } else {
    return <div>{cellData}</div>;
  }
};

const TableBodyCell = ({
  row,
  index,
  i18n,
  headers,
  classes,
  showDetails,
  showInlineDetails,
  onClick,
  renderInlineDetails,
  showDates,
  lineSeparator
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverOpen = Boolean(anchorEl);

  const togglePopover = (show) => (event) => {
    event.preventDefault();

    if (show) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleOnDownload = (data) => {
    window.open(data, '_blank');
  };

  const handleOnClickRow = () => {
    onClick?.(row.rowId);
  }

  return (
    <StyledTableWhiteRow key={`${row.rowId}_${index}`} onClick={handleOnClickRow} className={clsx(typeof onClick === 'function' ? classes.rowClickable : null, lineSeparator ? classes.rowLineSeparator : null)}>
      {row.columns.map((cell, index) => {
        const cellHeader = headers.filter((header) => header.columnNumber === cell.columnNumber)[0];
        const formattedCell = _getCellData(
          cellHeader.columnNumber,
          cell.data,
          cellHeader.type,
          cell.customData || null,
          i18n,
          classes,
          false,
          handleOnDownload,
          cell.buttonTextOnly,
          cell.buttonSize,
          cell.buttonLabel,
          showDates && cellHeader.type === 'date' && cell.columnNumber === 1
        );

        if (cellHeader.canHide) {
          return (
            <Hidden key={`${row.rowId}-${index}`} mdDown>
              <TableCell className={classes.tableBodyCellWrapper} align={'left'}>
                {formattedCell}
              </TableCell>
            </Hidden>
          );
        }

        return (
          <TableCell
            className={clsx(
              classes.tableBodyCellWrapper,
              row.highlight && index === 0 ? classes.rowHighlighted : null
            )}
            key={`${row.rowId}-${index}`}
            align={cellHeader.align || 'left'}
          >
            {formattedCell}
          </TableCell>
        );
      })}
      {showInlineDetails ? (
        <TableCell className={classes.tableBodyCellWrapper} align="right">
          <Link href="#" onClick={togglePopover(true)}>
            <img src={InlineDetailsIcon} alt="Details" />
          </Link>
          <Popover
            id={row.rowId}
            open={popoverOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClose={togglePopover(false)}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <div className={classes.tableBodyCellInlineDetailsWrapper}>{renderInlineDetails(row.inlineDetails)}</div>
          </Popover>
        </TableCell>
      ) : null}
      {!row.refundable && showDetails ? (
        <TableCell className={classes.tableBodyCellWrapper} align="right">
          <Link href="#" onClick={onClick(row.rowId)}>
            <img src={DetailsIcon} alt="Details" />
          </Link>
        </TableCell>
      ) : null}
      {row.refundable ? (
        <TableCell className={classes.tableBodyCellWrapper} align="right">
          <Link href="#" onClick={() => alert(`La operación #${row.columns[1].data} ha sido marcada para devolución.`)}>
            Devolver
          </Link>
        </TableCell>
      ) : null}
    </StyledTableWhiteRow>
  );
};

const OperationstableBody = ({
  headers,
  body,
  i18n,
  onClick,
  showDetails,
  showInlineDetails,
  renderInlineDetails,
  showDates,
  lineSeparator
}) => {
  const classes = makeClasses({})();
  return (
    <TableBody>
      {body.map((item, index) => (
        <TableBodyCell
          key={index}
          i18n={i18n}
          row={item}
          headers={headers}
          classes={classes}
          showDetails={showDetails}
          showInlineDetails={showInlineDetails}
          renderInlineDetails={renderInlineDetails}
          onClick={onClick}
          showDates={showDates}
          lineSeparator={lineSeparator}
        />
      ))}
    </TableBody>
  );
};

const OperationsTable = ({
  headers = [],
  body = [],
  currentPage = 1,
  totalPages = 1,
  paginationSimple = false,
  hasMorePages = false,
  onClick,
  onPaginationChange,
  onTableSortChange,
  showDetails = true,
  showInlineDetails = false,
  renderInlineDetails,
  emptyMessage = null,
  displayAsSales = false,
  compact = false,
  sm = false,
  showDates = true,
  lineSeparator = false
}) => {
  const classes = makeClasses({ displayAsSales })();

  const { languageTexts } = useSelector((state) => ({
    languageTexts: state.language.texts || {},
    loading: state.user.loading || state.business.loading,
  }));
  const i18n = Language(languageTexts);

  headers = _.orderBy(headers, ['columnNumber'], ['asc']);
  body = _.orderBy(body, ['columnNumber'], ['asc']);

  const _handleOnSortChange = (columnNumber, sortType) => {
    if (typeof onTableSortChange === 'function') {
      onTableSortChange(columnNumber, sortType);
    }
  };

  const _handleOnPaginationChange = (e, value) => {
    if (typeof onPaginationChange === 'function') {
      onPaginationChange(value);
    }
  };

  const _handleOnSimplePaginationChange = (page) => () => {
    onPaginationChange(page);
  };

  const renderBoxes = () => {
    if (displayAsSales) {
      return body.map((row, index) => (
        <React.Fragment key={index}>
          {index === 0 ? null : <div className={classes.boxItemSeparator} />}
          <div className={clsx(classes.boxContainer, index % 2 === 0 ? classes.boxContainerEven : null)}>
            <div className={classes.boxItemAsSales}>
              <div>
                {_getCellData(1, row.columns[0].data, 'date', null, i18n, classes, true, null, null, null, null, true)}
                <span>|</span>
                {_getCellData(2, row.columns[1].data, 'text', null, i18n, classes, true)}
              </div>
              <div className={(row.columns[2].data || row.columns[3].data) > 0 ? classes.green : classes.red}>
                <div>
                  {(row.columns[2].data || row.columns[3].data) > 0 ? (
                    <img src={ArrowRightIcon} alt="Arrow right" />
                  ) : (
                    <img src={ArrowLeftIcon} alt="Arrow left" />
                  )}
                </div>
                {_getCellData(2, row.columns[2].data || row.columns[3].data, 'number', null, i18n, classes, true)}
              </div>
            </div>
          </div>
        </React.Fragment>
      ));
    }

    const visibleHeaders = headers.filter((h) => !h.hidden);
    const visibleHeadersColumnNumbers = visibleHeaders.map((h) => h.columnNumber);
    body.forEach((row) => {
      row.columns = row.columns.filter((c) => visibleHeadersColumnNumbers.includes(c.columnNumber));
    });
    
    const handleOnClick = (row) => (e) => {
      e.preventDefault();
      e.stopPropagation();
      
      onClick?.(row.rowId);
    }

    return body.map((row, index) => (
      <React.Fragment key={index}>
        <div
          className={clsx(
            classes.boxContainer,
            classes.boxContainerSm,
            index % 2 === 0 ? classes.boxContainerEven : null,
            typeof onClick === 'function' ? classes.boxContainerClickable : null,
            row.highlight ? classes.boxContainerHighlighted : null
          )}
        >
          {row.columns.map((col, key) => {
            const header = visibleHeaders.find((h) => h.columnNumber === col.columnNumber);
            const cellData = _getCellData(
              header.columnNumber,
              col.data,
              header.type,
              col.customData || null,
              i18n,
              classes,
              true,
              null,
              col.buttonTextOnly,
              col.buttonSize,
              col.buttonLabel,
              showDates && header.type === 'date' && col.columnNumber === 1
            );
            return cellData ? (
              <div key={key} className={clsx(classes.boxItem, compact ? classes.boxItemCompact : null)} onClick={handleOnClick(row)}>
                {typeof header.show === 'undefined' || header.show ? <span>{header.name}:</span> : null}
                <span>{cellData}</span>
                {showDetails ? (
                  <div className={classes.boxDetails} align="right">
                    <Link href="#" onClick={handleOnClick(row)}>
                      <img src={MoreVerticalIcon} alt="Details" />
                    </Link>
                  </div>
                ) : null}
              </div>
            ) : null;
          })}
        </div>
      </React.Fragment>
    ));
  };

  return (
    <React.Fragment>
      {(paginationSimple && body.length === 0 && currentPage === 1) ||
      (!paginationSimple && totalPages > 1 && body.length === 0) ? (
        <EmptyMessage textOnly>{emptyMessage}</EmptyMessage>
      ) : (
        <React.Fragment>
          {body.length > 0 ? (
            <React.Fragment>
              <Hidden xsDown>
                {compact ? (
                  <div className={classes.boxesContainer}>{renderBoxes()}</div>
                ) : (
                  <TableContainer>
                    <Table>
                      <OperationsTableHead
                        headers={headers}
                        i18n={i18n}
                        showDetails={showDetails}
                        showInlineDetails={showInlineDetails}
                        onSortChange={_handleOnSortChange}
                        lineSeparator={lineSeparator}
                      />     
                      <OperationstableBody
                        headers={headers}
                        body={body}
                        i18n={i18n}
                        onClick={onClick}
                        showDetails={showDetails}
                        showInlineDetails={showInlineDetails}
                        renderInlineDetails={renderInlineDetails}
                        showDates={showDates}
                        lineSeparator={lineSeparator}
                      />
                    </Table>
                  </TableContainer>
                )}
              </Hidden>
              <Hidden smUp>
                <div className={classes.boxesContainer}>{renderBoxes()}</div>
              </Hidden>
            </React.Fragment>
          ) : body.length === 0 ? (
            <EmptyMessage textOnly>{emptyMessage}</EmptyMessage>
          ) : null}
          {paginationSimple ? (
            <PaginationSimple
              currentPage={currentPage}
              hasMorePages={hasMorePages}
              onPressNext={_handleOnSimplePaginationChange(currentPage + 1)}
              onPressPrevious={_handleOnSimplePaginationChange(currentPage - 1)}
            />
          ) : totalPages > 1 ? (
            <Pagination
              classes={{
                root: classes.pagination,
                ul: classes.paginationList,
              }}
              count={totalPages}
              variant="outlined"
              shape="rounded"
              page={currentPage}
              onChange={_handleOnPaginationChange}
            />
          ) : null}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default OperationsTable;
