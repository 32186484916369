import React, { useEffect, useState } from 'react';
import { BoxSeparator, Box, Page, TitleBox, FiltersToggle, OperationsTable, Confirm, ForbiddenSection } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { Language } from '../../utils';
import makeClasses from './styles';
import { push, replace } from 'react-router-redux';
import qs from 'query-string';
import { user as userActions } from '../../actions';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import MoreVerticalIcon from '../../static/images/icons/more_vertical.svg';
import { Visibility as VisisbilityIcon, Delete as DeleteIcon, PriorityHigh as PriorityHighIcon  } from '@material-ui/icons';
import Markdown from 'react-markdown';
import moment from 'moment';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { Policies } from '../../utils/Policies';
import { Alert, AlertTitle } from '@material-ui/lab';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

const VIEW_FILTER_OPTIONS = [
  { id: 'all', label: 'Notifications.ViewFilter.ALL' },
  { id: 'unread', label: 'Notifications.ViewFilter.UNREAD' },
  { id: 'read', label: 'Notifications.ViewFilter.READ' },
];

const NotificationsScreen = ({ history }) => {
  const classes = makeClasses();
  const dispatch = useDispatch();
  const searchParams = qs.parse(history.location.search);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [selectedView, setSelectedView] = useState(searchParams.view || 'all');
  const [paginationPage, setPaginationPage] = useState(searchParams.page ? parseInt(searchParams.page) : 1);
  const [notifications, setNotifications] = useState([]);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(-1);
  const [notificationToDelete, setNotificationToDelete] = useState(null);
  const [showDeleteNotificationDialog, setShowDeleteNotificationDialog] = useState(false);
  const [forbiddenSection, setForbiddenSection] = useState(false);

  const { languageTexts, policies } = useSelector(state => ({
    languageTexts: state.language.texts || {},
    policies: state.user.userData.policies || [],
  }));
  const i18n = Language(languageTexts);

  useEffect(() => {
    if (policies?.includes(Policies.types.NOTIFICATIONS)) {
      loadNotifications();
    } else {
      setForbiddenSection(true);
    }
  }, [policies, selectedView, paginationPage]);

  const loadNotifications = () => {
    dispatch(userActions.getNotifications(
      paginationPage,
      selectedView === 'all' ? null : selectedView === 'unread' ? false : true,
      null,
      null,
      null,
      (notifications) => {
        setNotifications(notifications);
      }));
  };

  const handleOnViewChange = (view) => {
    setSelectedView(view);

    const searchParams = {};
    searchParams.view = view;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const renderViewToggle = () => (
    <FiltersToggle
      showLabel={false}
      showDefault={false}
      options={VIEW_FILTER_OPTIONS}
      onChange={handleOnViewChange}
      selected={selectedView}
      showAsColumnSm
    />
  );

  const handleOnPaginationChange = (nextPage) => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const goToNotificationDetails = (notification) => () => {
    dispatch(
      push({
        pathname: `/notificaciones/${notification.id}`,
      })
    );
  };

  const handleOnDeleteNotification = (notification) => () => {
    toggleMenu()();
    toggleDeleteNotificationDialog();
    setNotificationToDelete(notification.id);
  }

  const toggleDeleteNotificationDialog = () => {
    setShowDeleteNotificationDialog(!showDeleteNotificationDialog);
  };

  const confirmDeleteNotification = () => {
    toggleDeleteNotificationDialog();
    dispatch(
      userActions.deleteNotification(notificationToDelete, (result) => {
        if (result) {
          toast.success(i18n.get('Notifications.SuccessCancelMessage'));
          loadNotifications();
        } else {
          toast.error(i18n.get('Notifications.ErrorCancelMessage'));
        }
      })
    );
  };

  const toggleMenu = (index) => (e) => {
    if (menuAnchorEl) {
      setMenuAnchorEl(null);
      setMenuOpenIndex(-1);
    } else {
      setMenuAnchorEl(e.target);
      setMenuOpenIndex(index);
    }
  };

  const renderNotifications = () => {
    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get('Notifications.Grid.Column1'),
        sortable: false,
        type: 'custom',
        width: '80%',
        show: !isSmallScreen
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get('Notifications.Grid.Column2'),
        sortable: false,
        type: 'custom',
        width: '10%',
        show: !isSmallScreen
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get('Notifications.Grid.Column3'),
        sortable: false,
        type: 'custom',
        align: 'right',
        show: !isSmallScreen,
        width: '10%'
      },
    ];
    const body = (notifications.elements || []).map((item, index) => ({
      rowId: item.id,
      highlight: !item.wasRead,
      columns: [
        {
          columnNumber: 1,
          data: () => {
            return (
              <div className={clsx(classes.notificationItemWrapper, item.wasRead ? classes.notificationItemRead : null)}>
                <Markdown className={classes.notificationItemTitle}>{item.enrichedTitle}</Markdown>
                <Markdown className={classes.notificationItemSummary}>{item.summary}</Markdown>
              </div>
            )
          },
        },
        {
          columnNumber: 2,
          data: () => {
            return (
              <div className={clsx(classes.notificationDateTimeWrapper, isSmallScreen && item.wasRead ? classes.notificationItemRead : null)}>
                <div>{moment(item.createDate).format('DD/MM/YYYY')}</div>
                <div className={classes.notificationDateTime}>
                  {moment(item.createDate).format(i18n.get('TimeFormat', false))}{item.priority === 'IMPORTANT' ? (<PriorityHighIcon />) : null}
                </div>
              </div>
            );
          }
        },
        {
          columnNumber: 3,
          data: () => {
            return (
              <div className={classes.listItemActions}>
                <IconButton onClick={toggleMenu(index)}>
                  <img src={MoreVerticalIcon} alt="More" />
                </IconButton>
                <Menu
                  anchorEl={menuAnchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  onClose={toggleMenu()}
                  open={menuOpenIndex === index}
                  className={classes.listItemMenu}
                >
                  <MenuItem onClick={goToNotificationDetails(item)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <VisisbilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('Notifications.GridActionDetails')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                  <MenuItem onClick={handleOnDeleteNotification(item)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('Notifications.GridActionDelete')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                </Menu>
              </div>
            );
          },
        },
      ],
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={notifications.hasNext}
        onPaginationChange={handleOnPaginationChange}
        emptyMessage={i18n.get('Notifications.GridEmptyMessage')}
        showDetails={false}
        lineSeparator
      />
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('Notifications.Title')}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('Notifications.Title')}
    >
      <TitleBox title={i18n.get('Notifications.BoxTitle')} />
      <BoxSeparator />
        <Box>
          <div className={classes.boxContentWrapper}>
            <div className={classes.viewFilterWrapper}>
              <div>{i18n.get('Notifications.BoxViewTitle')}</div>
              {renderViewToggle()}
            </div>
            <div className={classes.gridWrapper}>
              {renderNotifications()}
            </div>
          </div>
        </Box>
        <Confirm
          open={showDeleteNotificationDialog}
          onClose={toggleDeleteNotificationDialog}
          title={i18n.get('Notifications.DeleteNotificactionDialogTitle')}
          confirmText={i18n.get('Notifications.DeleteNotificactionDialogActionConfirm')}
          onConfirm={confirmDeleteNotification}
          cancelText={i18n.get('Notifications.DeleteNotificactionDialogActionCancel')}
          onCancel={toggleDeleteNotificationDialog}
        >
          <Alert
            severity="error"
            icon={false}
            className={clsx(classes.alert, classes.alertDanger)}
          >
            <div>
              <ErrorOutlineOutlinedIcon className={classes.alertIcon} />
            </div>
            <div className={classes.alertMessage}>
              <AlertTitle className={classes.alertTitle}>
                {i18n.get('Notifications.DeleteNotificactionDialogContentTitle')}
              </AlertTitle>
              {i18n.get('Notifications.DeleteNotificactionDialogContentText')}
            </div>
          </Alert>
        </Confirm>
    </Page>
  );
};

NotificationsScreen.id = "com.Handy.Notifications";

export default NotificationsScreen;