import { makeStyles } from '@material-ui/core';
import { THEME_SIDEMENU_WIDTH } from '../../theme';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  appBar: {
    height: 66,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),

    width: `calc(100% - ${THEME_SIDEMENU_WIDTH}px)`,
    marginLeft: THEME_SIDEMENU_WIDTH,
    background: Colors.rgb.headerBackground,

    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 0,
    },
  },
  withBanner: {
    top: 55,
  },
  withBoxBanner: {
    top: 60,
  },
  withBoxBannerMd: {
    top: 110,

    [theme.breakpoints.down('md')]: {
      top: 60,
    },
  },
  toolbar: {
    padding: 0,
  },
  toolbarTitle: {
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.L,
    color: Colors.rgb.headerText,
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fontSize.L,
    fontFamily: theme.fontFamily.regular,
    color: Colors.rgb.headerText,
  },
  userName: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  notificationsContainer: {
    borderRight: `1px solid ${Colors.rgb.headerText}`,
    marginRight: theme.spacing(2),
    paddingRight: theme.spacing(2),
    cursor: 'pointer',
    position: 'relative',

    '&:hover': {
      '& > svg': {
        opacity: 0.8,
      }
    }
  },
  notificationsContainerWithCount: {
    paddingRight: theme.spacing(3),
  },
  notificationsCount: {
    position: 'absolute',
    top: -3,
    right: 6,
    background: Colors.rgb.red_dark,
    color: Colors.rgb.white,
    borderRadius: '50%',
    minWidth: 15,
    height: 15,
    padding: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.fontSize.XS,
  },
  logout: {
    cursor: 'pointer',
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    borderLeft: `1px solid ${Colors.rgb.headerText}`,

    [theme.breakpoints.down('md')]: {
      borderLeft: 'none',
      marginLeft: 0,
    },


    '&:hover': {
      '& > img': {
        opacity: 0.8,
      }
    }
  },
  logoutIcon: {
    width: 16,
  },
}));

export default makeClasses;
