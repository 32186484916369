import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push, replace } from 'connected-react-router';
import qs from 'query-string';

import ROUTES from '../../../routes';
import {
  BoxMenu,
  BoxSeparator,
  BranchesSelector,
  Button,
  Confirm,
  ContentBox,
  EmptyMessage,
  FiltersContainer,
  FiltersDateSelector,
  FiltersSelector,
  FiltersSeparator,
  ForbiddenSection,
  OperationsTable,
  Page,
  ShareModal,
  TitleBox,
} from '../../../components';
import { business as businessActions } from '../../../actions';
import { Language, Utils } from '../../../utils';

import ListItemText from '@material-ui/core/ListItemText';
import {
  Visibility as VisisbilityIcon,
  Close as CancelIcon,
  Edit as EditIcon,
  Share as ShareIcon,
  Pause as PauseIcon,
  PlayArrow as PlayIcon,
} from '@material-ui/icons';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import MoreVerticalIcon from '../../../static/images/icons/more_vertical.svg';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import makeClasses from './styles';
import { IconButton, ListItemIcon, Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import { Policies } from '../../../utils/Policies';
import clsx from 'clsx';
import { Alert, AlertTitle } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { formatCurrency, toISOString } from '../../../utils/Utils';
import { CUSTOM_FILTER, getDateRangeForFilter } from '../../../components/FiltersDateSelector';
import _ from 'lodash';

const STATUS_FILTER_OPTIONS = [
  { id: 'ACTIVE', name: 'Statuses.ACTIVE_F' },
  { id: 'INACTIVE', name: 'Statuses.INACTIVE_F' },
  { id: 'PAUSED', name: 'Statuses.PAUSED_F' },
  { id: 'CANCELLED', name: 'Statuses.CANCELLED_F' },
  { id: 'RENOVATION_FAILED', name: 'Statuses.RENOVATION_FAILED' },
  { id: 'COMPLETED', name: 'Statuses.COMPLETED' },
];

const CURRENCY_FILTER_OPTIONS = [
  { id: 'URUGUAYAN_PESO', name: 'Currency.UYU' },
  { id: 'DOLLAR', name: 'Currency.USD' },
];

const ProvidersSubscriptionsManageSubscriptionsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const searchParams = qs.parse(history.location.search);

  const [subscriptions, setSubscriptions] = useState({});

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(-1);
  const [paginationPage, setPaginationPage] = useState(searchParams.page ? parseInt(searchParams.page) : 1);
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(searchParams.bid || 'all');
  const [selectedStatus, setSelectedStatus] = useState(searchParams.tid || 'all');
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [plansOptions, setPlansOptions] = useState([]);
  const [subscribersOptions, setSubscribersOptions] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(searchParams.pid || 'all');
  const [selectedSubscriber, setSelectedSubscriber] = useState(searchParams.sid || 'all');
  const [showToggleSubscriptionStatusDialog, setShowToggleSubscriptionStatusDialog] = useState(false);
  const [subscriptionToToggleStatus, setSubscriptionToToggleStatus] = useState(null);
  const [shareModalData, setShareModalData] = useState(null);
  const [showShareModal, setShowShareModal] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(searchParams.fid ? searchParams.fid === 'custom' ? searchParams.fid : parseInt(searchParams.fid) : 0);
  const [selectedCustomFilter, setSelectedCustomFilter] = useState(
    searchParams.fid === CUSTOM_FILTER ? { startDate: searchParams.fsd, endDate: searchParams.fed } : {}
  );
  const [branches, setBranches] = useState([]);

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const hasSubscriptions = subscriptions && subscriptions.elements && subscriptions.elements.length > 0;
  const canExport = policies.includes(Policies.types.SUPER_READ);
  const canShare = policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS_MANAGE);
  const canManage = policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS_WRITE);
  const canSeeTransactions = policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS);
  const canSeePromotions = policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_PROMOTIONS);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (policies && policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS)) {
      loadFiltersData();
      loadSubscriptions();
    } else {
      setForbbidenSection(true);
    }
  }, [policies, paginationPage, selectedBranch, selectedStatus, selectedFilter, selectedPlan, selectedSubscriber]);

  const loadFiltersData = () => {
    if (filtersLoaded) {
      return;
    }

    dispatch(businessActions.getProvidersSubscriptionsMerchant((branches) => {
      setBranches(branches.branches?.filter(b => b.serviceStatus === 'ACTIVE')?.map(b => ({ traceId: b.branchId, name: b.name })) || []);
    }));
    dispatch(
      businessActions.getProvidersSubscriptionsPlans(null, null, null, null, null, 100, null, (data) => {
        if (data) {
          const options = data.elements
            ? data.elements
                .filter((e) => e)
                .map((e) => ({
                  id: e.subscriptionPlanTraceId,
                  name: e.name,
                }))
            : [];
          setPlansOptions(options);
        }
      })
    );
    dispatch(
      businessActions.getProvidersSubscriptionsSubscribers(1, 100, (data) => {
        if (data) {
          const options = data.elements
            ? data.elements
                .filter((e) => e)
                .map((e) => ({
                  id: e.subscriberTraceId,
                  name: `${e.document} - ${e.firstName} ${e.lastName}`,
                }))
            : [];
          setSubscribersOptions(options);
        }
      })
    );

    setFiltersLoaded(true);
  };

  const loadSubscriptions = () => {
    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null,
      true
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.getProvidersSubscriptionsSubscriptions(
        selectedBranch !== 'all' ? selectedBranch : null,
        selectedSubscriber !== 'all' ? selectedSubscriber : null,
        selectedPlan !== 'all' ? selectedPlan : null,
        selectedStatus !== 'all' ? selectedStatus : null,
        startDate,
        endDate,
        paginationPage,
        15,
        (data) => {
          setSubscriptions(data);
        }
      )
    );
  };

  const toggleMenu = (index) => (e) => {
    if (menuAnchorEl) {
      setMenuAnchorEl(null);
      setMenuOpenIndex(-1);
    } else {
      setMenuAnchorEl(e.target);
      setMenuOpenIndex(index);
    }
  };

  const toggleShareModal = () => {
    if (showShareModal) {
      setShowShareModal(false);
      setShareModalData(null);
    } else {
      setShowShareModal(true);
    }
  };

  const handleOnViewSubscription = (subscription) => () => {
    toggleMenu()();
    dispatch(
      push(
        ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS_VIEW.path.replace(
          ':traceId',
          subscription.subscriptionTraceId
        )
      )
    );
  };

  const handleOnEditSubscription = (subscription) => () => {
    toggleMenu()();
    dispatch(
      push(
        ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS_EDIT.path.replace(
          ':traceId',
          subscription.subscriptionTraceId
        )
      )
    );
  };

  const handleOnShareSubscription = (subscription) => () => {
    toggleMenu()();
    dispatch(
      businessActions.getProvidersSubscriptionsSubscriptionShareLink(subscription.subscriptionTraceId, (link) => {
        if (link) {
          setShareModalData(link);
          toggleShareModal();
        }
      })
    );
  };

  const handleOnPaginationChange = (nextPage) => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const renderSubscriptions = () => {
    if (!subscriptions || !subscriptions.elements || subscriptions.elements.length === 0) {
      return (
        <EmptyMessage textOnly>{i18n.get('ProvidersSubscriptionsManageSubscriptions.GridEmptyMessage')}</EmptyMessage>
      );
    }

    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get('ProvidersSubscriptionsManageSubscriptions.SubscriptionsGrid.Column1'),
        sortable: false,
        type: 'date',
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get('ProvidersSubscriptionsManageSubscriptions.SubscriptionsGrid.Column2'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get('ProvidersSubscriptionsManageSubscriptions.SubscriptionsGrid.Column3'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 4,
        name: i18n.get('ProvidersSubscriptionsManageSubscriptions.SubscriptionsGrid.Column4'),
        sortable: false,
        type: 'status_f',
      },
      {
        canHide: false,
        columnNumber: 5,
        name: i18n.get('ProvidersSubscriptionsManageSubscriptions.SubscriptionsGrid.Column5'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 6,
        name: i18n.get('ProvidersSubscriptionsManageSubscriptions.SubscriptionsGrid.Column6'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 7,
        name: i18n.get('ProvidersSubscriptionsManageSubscriptions.SubscriptionsGrid.Column7'),
        sortable: false,
        type: 'status_f',
      },
      {
        canHide: false,
        columnNumber: 8,
        name: i18n.get('ProvidersSubscriptionsManageSubscriptions.SubscriptionsGrid.Column8'),
        sortable: false,
        type: 'custom',
        show: !isSm,
        align: 'right'
      },
    ];
    const body = (subscriptions.elements || []).map((item, index) => ({
      rowId: item.traceId,
      columns: [
        {
          columnNumber: 1,
          data: item.createdAt,
        },
        {
          columnNumber: 2,
          data: item.subscriptionPlan?.code ? `${item.subscriptionPlan?.name} - ${item.subscriptionPlan?.code}` : item.subscriptionPlan?.name,
        },
        {
          columnNumber: 3,
          data: `${item.subscriber?.document} - ${item.subscriber?.firstName} ${item.subscriber?.lastName}`,
        },
        {
          columnNumber: 4,
          data: item.subscriptionStatus,
        },
        {
          columnNumber: 5,
          data: `${i18n.get(CURRENCY_FILTER_OPTIONS.find((c) => c.id === item.currency)?.name)} ${formatCurrency(
            item.amount
          )}`,
        },
        {
          columnNumber: 6,
          data: `${i18n.get(CURRENCY_FILTER_OPTIONS.find((c) => c.id === item.currency)?.name)} ${formatCurrency(
            item.collectedAmount
          )}`,
        },
        {
          columnNumber: 7,
          data: item.lastPaymentStatus,
        },
        {
          columnNumber: 8,
          data: () => {
            const isCancelled = item.subscriptionStatus === 'CANCELLED';
            return (
              <div className={classes.listItemActions}>
                <IconButton onClick={toggleMenu(index)}>
                  <img src={MoreVerticalIcon} alt="More" />
                </IconButton>
                <Menu
                  anchorEl={menuAnchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  onClose={toggleMenu()}
                  open={menuOpenIndex === index}
                  className={classes.listItemMenu}
                >
                  <MenuItem onClick={handleOnViewSubscription(item)} className={classes.listItemMenuItem}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <VisisbilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get('ProvidersSubscriptionsManageSubscriptions.ActionViewDetails')}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                  {canManage && item.subscriptionStatus === 'ACTIVE' ? (
                    <MenuItem
                      onClick={handleOnToggleSubscriptionStatus(item, 'PAUSED')}
                      className={classes.listItemMenuItem}
                    >
                      <ListItemIcon className={classes.menuItemIcon}>
                        <PauseIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={i18n.get('ProvidersSubscriptionsManageSubscriptions.ActionPause')}
                        className={classes.menuItemText}
                      />
                    </MenuItem>
                  ) : canManage && item.subscriptionStatus === 'PAUSED' ? (
                    <MenuItem
                      onClick={handleOnToggleSubscriptionStatus(item, 'ACTIVE')}
                      className={classes.listItemMenuItem}
                    >
                      <ListItemIcon className={classes.menuItemIcon}>
                        <PlayIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={i18n.get('ProvidersSubscriptionsManageSubscriptions.ActionResume')}
                        className={classes.menuItemText}
                      />
                    </MenuItem>
                  ) : null}
                  {canManage && !isCancelled ? (
                    <MenuItem
                      onClick={handleOnToggleSubscriptionStatus(item, 'CANCELLED')}
                      className={classes.listItemMenuItem}
                    >
                      <ListItemIcon className={classes.menuItemIcon}>
                        <CancelIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={i18n.get('ProvidersSubscriptionsManageSubscriptions.ActionCancel')}
                        className={classes.menuItemText}
                      />
                    </MenuItem>
                  ) : null}
                  {!isCancelled && canManage ? (
                    <React.Fragment>
                      <MenuItem onClick={handleOnEditSubscription(item)} className={classes.listItemMenuItem}>
                        <ListItemIcon className={classes.menuItemIcon}>
                          <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={i18n.get('ProvidersSubscriptionsManageSubscriptions.ActionEdit')}
                          className={classes.menuItemText}
                        />
                      </MenuItem>
                      {item.subscriptionStatus !== 'PAUSED' && canShare ? <MenuItem onClick={handleOnShareSubscription(item)} className={classes.listItemMenuItem}>
                        <ListItemIcon className={classes.menuItemIcon}>
                          <ShareIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={i18n.get('ProvidersSubscriptionsManageSubscriptions.ActionShare')}
                          className={classes.menuItemText}
                        />
                      </MenuItem> : null}
                    </React.Fragment>
                  ) : null}
                </Menu>
              </div>
            );
          },
        },
      ],
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={subscriptions.hasNext}
        onPaginationChange={handleOnPaginationChange}
        emptyMessage={i18n.get('ProvidersSubscriptionsManageSubscriptions.GridEmptyMessage')}
        showDetails={false}
        showDates={false}
      />
    );
  };

  const handleOnExport = () => {
    if (selectedFilter === 0) {
      toast.error(i18n.get('ProvidersSubscriptionsManageSubscriptions.ExportErrorNoDateFilterSelected'));
      return;
    }

    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null,
      true
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.exportProvidersSubscriptionsSubscriptions(
        selectedBranch !== 'all' ? selectedBranch : null,
        selectedSubscriber !== 'all' ? selectedSubscriber : null,
        selectedPlan !== 'all' ? selectedPlan : null,
        selectedStatus !== 'all' ? selectedStatus : null,
        startDate,
        endDate,
        paginationPage,
        (file) =>
          Utils.downloadFile(
            file,
            'Cobranzas_Suscripciones_Subscripciones_' +
              dateFilter.startDate.format(i18n.get('ExportDateFormat')) +
              '_' +
              dateFilter.endDate.format(i18n.get('ExportDateFormat')) +
              '.xlsx'
          )
      )
    );
  };

  const handleOnBranchSelected = (branch) => {
    setSelectedBranch(branch);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.bid = branch;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnPlanSelected = (plan) => {
    setSelectedPlan(plan);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.pid = plan;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnSubscriberSelected = (subscriber) => {
    setSelectedSubscriber(subscriber);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.sid = subscriber;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnStatusSelected = (status) => {
    setSelectedStatus(status);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.tid = status;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnFilterSelected = (filter, startDate, endDate) => {
    setSelectedFilter(filter);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.fid = filter;

    if (filter === CUSTOM_FILTER) {
      setSelectedCustomFilter({ startDate, endDate });
      searchParams.fsd = startDate;
      searchParams.fed = endDate;
    }

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnMenuItemClick = (item) => {
    dispatch(push(item.path));
  };

  const handleOnToggleSubscriptionStatus = (subscrption, status) => () => {
    toggleMenu()();
    toggleToggleSubscriptionStatusDialog();
    setSubscriptionToToggleStatus({ ...subscrption, subscriptionStatus: status });
  };

  const toggleToggleSubscriptionStatusDialog = () => {
    setShowToggleSubscriptionStatusDialog(!showToggleSubscriptionStatusDialog);
  };

  const confirmToggleSubscriptionStatus = () => {
    toggleToggleSubscriptionStatusDialog();
    dispatch(
      businessActions.toggleProvidersSubscriptionsSubscriptionStatus(
        subscriptionToToggleStatus.subscriptionTraceId,
        subscriptionToToggleStatus.subscriptionStatus === 'ACTIVE'
          ? 'REACTIVATE'
          : subscriptionToToggleStatus.subscriptionStatus,
        (result) => {
          if (result) {
            toast.success(
              subscriptionToToggleStatus.subscriptionStatus === 'ACTIVE'
                ? i18n.get('ProvidersSubscriptionsManageSubscriptions.SuccessToggleSubscriptionStatusMessageActive')
                : subscriptionToToggleStatus.subscriptionStatus === 'PAUSED'
                ? i18n.get('ProvidersSubscriptionsManageSubscriptions.SuccessToggleSubscriptionStatusMessagePause')
                : i18n.get('ProvidersSubscriptionsManageSubscriptions.SuccessToggleSubscriptionStatusMessageCancel')
            );
            loadSubscriptions();
          } else {
            toast.error(
              subscriptionToToggleStatus.subscriptionStatus === 'ACTIVE'
                ? i18n.get('ProvidersSubscriptionsManageSubscriptions.ErrorToggleSubscriptionStatusMessageActive')
                : subscriptionToToggleStatus.subscriptionStatus === 'PAUSED'
                ? i18n.get('ProvidersSubscriptionsManageSubscriptions.ErrorToggleSubscriptionStatusMessagePause')
                : i18n.get('ProvidersSubscriptionsManageSubscriptions.ErrorToggleSubscriptionStatusMessageCancel')
            );
          }
        }
      )
    );
  };

  const renderBranchesSelector = () => (
    <BranchesSelector branches={branches} selectedBranch={selectedBranch} onBranchSelected={handleOnBranchSelected} />
  );

  const renderPlansFilter = () => {
    return (
      <FiltersSelector
        options={plansOptions}
        selected={selectedPlan}
        onSelected={handleOnPlanSelected}
        defaultOption={i18n.get('ProvidersSubscriptionsManageSubscriptions.FilterPlansDefaultOption')}
      />
    );
  };

  const renderSubscribersFilter = () => {
    return (
      <FiltersSelector
        options={subscribersOptions}
        selected={selectedSubscriber}
        onSelected={handleOnSubscriberSelected}
        defaultOption={i18n.get('ProvidersSubscriptionsManageSubscriptions.FilterSusbcribersDefaultOption')}
      />
    );
  };

  const renderStatusesSelector = () => {
    return (
      <FiltersSelector
        options={STATUS_FILTER_OPTIONS.map((s) => ({ ...s, name: i18n.get(s.name) }))}
        selected={selectedStatus}
        onSelected={handleOnStatusSelected}
        defaultOption={i18n.get('ProvidersSubscriptionsManageSubscriptions.FilterStatusesDefaultOption')}
      />
    );
  };

  const renderFiltersDateSelector = () => (
    <FiltersDateSelector
      selectedFilter={selectedFilter}
      startDate={selectedCustomFilter.startDate}
      endDate={selectedCustomFilter.endDate}
      onFilterSelected={handleOnFilterSelected}
      align="right"
      dateFormat={i18n.get('DateFormat', false)}
      showNoFilterOption
    />
  );

  const renderExportButton = () => {
    if (!canExport) {
      return null;
    }
    return (
      <Button
        rightIcon={() => <SaveAltIcon fontSize="small" />}
        onClick={handleOnExport}
        disabled={!subscriptions || !subscriptions.elements || subscriptions.elements.length === 0}
      >
        {isSm ? null : i18n.get('ProvidersSubscriptionsManageSubscriptions.ExportButton')}
      </Button>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ProvidersSubscriptionsManageSubscriptions.Title')}
        withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ProvidersSubscriptionsManageSubscriptions.Title')}
      withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
    >
      <BoxMenu
        items={_.compact([
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PLANS,
            label: i18n.get('ProvidersSubscriptionsManageSubscriptions.BoxMenu.Item1'),
          },
          canSeePromotions ? {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS,
            label: i18n.get('ProvidersSubscriptionsManageSubscriptions.BoxMenu.Item3'),
          } : null,
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS,
            label: i18n.get('ProvidersSubscriptionsManageSubscriptions.BoxMenu.Item2'),
          },
          canSeeTransactions ? {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS,
            label: i18n.get('ProvidersSubscriptionsManageSubscriptions.BoxMenu.Item4'),
          } : null,
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS,
            label: i18n.get('ProvidersSubscriptionsManageSubscriptions.BoxMenu.Item5'),
          },
        ])}
        selectedItem={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS.id}
        onClickItem={handleOnMenuItemClick}
      />
      <TitleBox title={i18n.get('ProvidersSubscriptionsManageSubscriptions.BoxTitle')} />
      <BoxSeparator size="small" />
      <ContentBox title={i18n.get('ProvidersSubscriptionsManageSubscriptions.BoxFiltersTitle')}>
        <FiltersContainer>
          {renderBranchesSelector()}
          <FiltersSeparator />
          {renderPlansFilter()}
          <FiltersSeparator />
          {renderSubscribersFilter()}
          <FiltersSeparator />
          {renderStatusesSelector()}
          <FiltersSeparator />
          {renderFiltersDateSelector()}
        </FiltersContainer>
      </ContentBox>
      <BoxSeparator size="small" />
      <ContentBox
        title={i18n.get('ProvidersSubscriptionsManageSubscriptions.SubscribersGridTitle')}
        titleBold
        customRightAction={renderExportButton}
      >
        {hasSubscriptions ? (
          renderSubscriptions()
        ) : (
          <div className={classes.noDataMessage}>
            {i18n.get('ProvidersSubscriptionsManageSubscriptions.NoSubscribersMessage')}
          </div>
        )}
      </ContentBox>
      <Confirm
        open={showToggleSubscriptionStatusDialog}
        onClose={toggleToggleSubscriptionStatusDialog}
        title={
          subscriptionToToggleStatus && subscriptionToToggleStatus.subscriptionStatus === 'PAUSED'
            ? i18n.get('ProvidersSubscriptionsManageSubscriptions.ToggleSubscriptionStatusDialogTitlePause')
            : subscriptionToToggleStatus && subscriptionToToggleStatus.subscriptionStatus === 'ACTIVE'
            ? i18n.get('ProvidersSubscriptionsManageSubscriptions.ToggleSubscriptionStatusDialogTitleActivate')
            : i18n.get('ProvidersSubscriptionsManageSubscriptions.ToggleSubscriptionStatusDialogTitleCancel')
        }
        confirmText={i18n.get('ProvidersSubscriptionsManageSubscriptions.ToggleSubscriptionStatusDialogActionConfirm')}
        onConfirm={confirmToggleSubscriptionStatus}
        cancelText={i18n.get('ProvidersSubscriptionsManageSubscriptions.ToggleSubscriptionStatusDialogActionCancel')}
        onCancel={toggleToggleSubscriptionStatusDialog}
      >
        <Alert severity="error" icon={false} className={clsx(classes.alert, classes.alertDanger)}>
          <div>
            <ErrorOutlineOutlinedIcon className={classes.alertIcon} />
          </div>
          <div>
            <AlertTitle className={classes.alertTitle}>
              {subscriptionToToggleStatus && subscriptionToToggleStatus.subscriptionStatus === 'PAUSED'
                ? i18n.get('ProvidersSubscriptionsManageSubscriptions.ToggleSubscriptionStatusDialogContentTitlePause')
                : subscriptionToToggleStatus && subscriptionToToggleStatus.subscriptionStatus === 'ACTIVE'
                ? i18n.get(
                    'ProvidersSubscriptionsManageSubscriptions.ToggleSubscriptionStatusDialogContentTitleActivate'
                  )
                : i18n.get(
                    'ProvidersSubscriptionsManageSubscriptions.ToggleSubscriptionStatusDialogContentTitleCancel'
                  )}
            </AlertTitle>
            {i18n.get('ProvidersSubscriptionsManageSubscriptions.ToggleSubscriptionStatusDialogContentText')}
          </div>
        </Alert>
      </Confirm>
      <ShareModal
        open={showShareModal}
        link={shareModalData}
        onClose={toggleShareModal}
        headerTitle={i18n.get('ProvidersSubscriptionsManageSubscriptions.ShareModalHeaderTitle')}
        bodyTitle={i18n.get('ProvidersSubscriptionsManageSubscriptions.ShareModalBodyTitle')}
        shareText={i18n.get('ProvidersSubscriptionsManageSubscriptions.ShareModalShareText')}
      />
    </Page>
  );
};

ProvidersSubscriptionsManageSubscriptionsScreen.id = 'com.Handy.ProvidersSubscriptionsManageSubscriptions';

export default ProvidersSubscriptionsManageSubscriptionsScreen;
