import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppHeader from '../AppHeader';
import SideMenu from '../SideMenu';
import { business as businessActions } from '../../actions';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import makeClasses from './styles';
import NewFeatureBanner from '../NewFeatureBanner';
import AlertBranchesWithNoPaymentMethod from '../AlertBranchesWithNoPaymentMethod';
import { Policies } from '../../utils/Policies';
import bannerBoxSm from '../../static/images/banners/Referidos-320x50.jpg';
import bannerBoxMd from '../../static/images/banners/Referidos-728x90.jpg';
import HelpBranchesWithNoPaymentMethod from '../HelpBranchesWithNoPaymentMethod';

function Page({
  withBanner = false,
  withBoxBanner = false,
  withBoxOnly = false,
  boxBannerSize = 'md',
  boxBannerLink = null,
  bannerTheme = 'default',
  children,
  withSideMenu = true,
  withHeader = true,
  withHeaderTitle = null,
  withActivePage = null,
  showBranchWithNoPaymentMethodWarning = true,
  renderNotifications
}) {
  const classes = makeClasses();
  const dispatch = useDispatch();

  const { policies, loading, branchesWithNoPaymentMethod } = useSelector(({ user, business }) => ({
    policies: user.userData ? user.userData.policies : [],
    loading: user.loading || business.loading,
    branchesWithNoPaymentMethod: business.branchesWithNoPaymentMethod || []
  }));

  const [showHelpDrawer, setShowHelpDrawer] = useState(false);
  const hasBranchesWithNoPaymentMethod = branchesWithNoPaymentMethod && branchesWithNoPaymentMethod.length > 0;

  const toggleHelpDrawer = () => {
    setShowHelpDrawer(!showHelpDrawer);
  };

  useEffect(() => {
    dispatch(businessActions.getBranchesWithNoPaymentMethod());
  }, []);

  return (
    <React.Fragment>
      {withBanner ? (
        <NewFeatureBanner
          box={withBoxBanner}
          boxImages={{ sm: bannerBoxSm, md: bannerBoxMd }}
          boxSize="md"
          boxOnly={withBoxOnly}
          theme={bannerTheme}
          link={'https://bit.ly/HandyReferidos'}
        />
      ) : null}
      <div className={classes.root}>
        {withHeader ? (
          <AppHeader
            title={withHeaderTitle}
            withBanner={withBanner}
            withBoxBanner={withBoxBanner}
            withBoxBannerMd={withBoxBanner && boxBannerSize === 'md'}
          />
        ) : null}
        {withSideMenu ? (
          <SideMenu
            activePage={withActivePage}
            withBanner={withBanner}
            withBoxBanner={withBoxBanner}
            withBoxBannerMd={withBoxBanner && boxBannerSize === 'md'}
          />
        ) : null}
        <div
          className={clsx(
            classes.contentContainer,
            withHeader ? classes.contentContainerWithHeader : null,
            withBanner ? classes.contentContainerWithHeaderWithBanner : null,
            withBoxBanner ? classes.contentContainerWithHeaderWithBoxBanner : null,
            withBoxBanner && boxBannerSize === 'md' ? classes.contentContainerWithHeaderWithBoxBannerMd : null,
            withSideMenu ? classes.contentContainerWithSideMenu : null
          )}
        >
          <div className={classes.notificationsWrapper}>
            {hasBranchesWithNoPaymentMethod &&
              policies &&
              policies.includes(Policies.types.SENSITIVE_DATA) &&
              showBranchWithNoPaymentMethodWarning ? (
              <AlertBranchesWithNoPaymentMethod onToggleHelp={toggleHelpDrawer} />
            ) : null}
            {renderNotifications?.()}
            {/*userSignedIn ? (
              <AlertWithAction
                type="info"
                text={i18n.get('Components.AlertWithAction.Title')}
                actionText={i18n.get('Components.AlertWithAction.Message')}
                onActionClick={handleOnActionClick}
              />
            ) : null*/}
          </div>
          {children}
        </div>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <ToastContainer
          bodyClassName={classes.toastBody}
          className={classes.toast}
          newestOnTop
          hideProgressBar
          autoClose={5000}
          closeOnClick
          pauseOnFocusLoss={false}
          transition={Slide}
          draggable={false}
          pauseOnHover={false}
          position="top-center"
          closeButton={false}
        />
        {showBranchWithNoPaymentMethodWarning ? (
          <HelpBranchesWithNoPaymentMethod open={showHelpDrawer} onToggleHelp={toggleHelpDrawer} />
        ) : null}
      </div>
    </React.Fragment>
  );
}

export default Page;
