import React, { useEffect, useState } from 'react';
import { BoxSeparator, Confirm, Page, TitleBox, Box, ForbiddenSection } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { Language } from '../../../../utils';
import makeClasses from './styles';
import { user as userActions } from '../../../../actions';
import { toast } from 'react-toastify';
import { goBack } from 'react-router-redux';
import Markdown from 'react-markdown';
import { Delete as DeleteIcon, PriorityHigh as PriorityHighIcon  } from '@material-ui/icons';
import moment from 'moment';
import { Policies } from '../../../../utils/Policies';
import { Alert, AlertTitle } from '@material-ui/lab';
import clsx from 'clsx';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

const NotificationDetails = ({ match }) => {
  const classes = makeClasses();
  const dispatch = useDispatch();
  const notificationId = match && match.params ? match.params.notificationId : null;

  const [notificationToDelete, setNotificationToDelete] = useState(null);
  const [showDeleteNotificationDialog, setShowDeleteNotificationDialog] = useState(false);
  const [notification, setNotification] = useState(null);
  const [forbiddenSection, setForbiddenSection] = useState(false);

  const { languageTexts, policies } = useSelector(state => ({
    languageTexts: state.language.texts || {},
    policies: state.user.userData.policies || []
  }));
  const i18n = Language(languageTexts);

  useEffect(() => {
    if (policies?.includes(Policies.types.NOTIFICATIONS)) {
      loadNotificationData();
    } else {
      setForbiddenSection(true);
    }
  }, [policies, notificationId]);

  const loadNotificationData = () => {
    dispatch(userActions.getNotification(notificationId, notification => {
      if (notification) {
        setNotification(notification);
        if (!notification.wasRead && policies?.includes(Policies.types.NOTIFICATIONS_WRITE)) {
          dispatch(userActions.markNotificationAsRead(notification.id));
        }
      }
    }));
  };

  const handleOnDeleteNotification = () => {
    toggleDeleteNotificationDialog();
    setNotificationToDelete(notification.id);
  }

  const toggleDeleteNotificationDialog = () => {
    setShowDeleteNotificationDialog(!showDeleteNotificationDialog);
  };

  const confirmDeleteNotification = () => {
    toggleDeleteNotificationDialog();
    dispatch(
      userActions.deleteNotification(notificationToDelete, (result) => {
        if (result) {
          toast.success(i18n.get('Notifications.SuccessCancelMessage'));
          dispatch(goBack());
        } else {
          toast.error(i18n.get('Notifications.ErrorCancelMessage'));
        }
      })
    );
  };

  const handleOnGoBack = () => {
    dispatch(goBack());
  };

  const renderNotification = () => {
    return (
      <div className={classes.notificationContainer}>
        <div className={classes.notificationDateWrapper}>
          <div>{moment(notification?.createDate).format(`DD/MM/YYYY, HH:mm`)}</div>
          <div onClick={handleOnDeleteNotification}><DeleteIcon fontSize="small" /></div>
        </div>
        <div className={classes.notificationMessageContainer}>
          <Markdown>{notification?.enrichedMessage}</Markdown>
        </div>
      </div>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('NotificationDetails.Title')}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }
 
  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('NotificationDetails.Title')}
    >
      <TitleBox
        title={(
          <div className={classes.notificationPageTitle}>
            <Markdown>{notification?.enrichedTitle}</Markdown>
          </div>
        )}
        back
        backTitle={i18n.get('NotificationDetails.BoxBackButtonTitle')}
        onBackClick={handleOnGoBack}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      <Box>
        <div className={classes.boxContentWrapper}>
          <div className={classes.boxTitleWrapper}>
            <div className={classes.notificationTitle}>
              <Markdown>{notification?.summary}</Markdown>
            </div>
            <div className={classes.boxTitlePriority}>
              {notification?.priority === 'IMPORTANT' ? (<PriorityHighIcon />) : null}
            </div>
          </div>
          {renderNotification()}
        </div>
      </Box>
      <Confirm
        open={showDeleteNotificationDialog}
        onClose={toggleDeleteNotificationDialog}
        title={i18n.get('Notifications.DeleteNotificactionDialogTitle')}
        confirmText={i18n.get('Notifications.DeleteNotificactionDialogActionConfirm')}
        onConfirm={confirmDeleteNotification}
        cancelText={i18n.get('Notifications.DeleteNotificactionDialogActionCancel')}
        onCancel={toggleDeleteNotificationDialog}
      >
        <Alert
          severity="error"
          icon={false}
          className={clsx(classes.alert, classes.alertDanger)}
        >
          <div>
            <ErrorOutlineOutlinedIcon className={classes.alertIcon} />
          </div>
          <div className={classes.alertMessage}>
            <AlertTitle className={classes.alertTitle}>
              {i18n.get('Notifications.DeleteNotificactionDialogContentTitle')}
            </AlertTitle>
            {i18n.get('Notifications.DeleteNotificactionDialogContentText')}
          </div>
        </Alert>
      </Confirm>
    </Page>
  );
}

NotificationDetails.id = 'com.Handy.NotificationDetails'

export default NotificationDetails;