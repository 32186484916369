import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import qs from 'query-string';
import ROUTES from '../../routes';
import {
  Page,
  OperationsTable,
  BranchesSelector,
  FiltersDateSelector,
  TitleBox,
  BoxSeparator,
  ContentBox,
  FiltersContainer,
  FiltersSeparator,
  ForbiddenSection,
} from '../../components';
import { CUSTOM_FILTER, getDateRangeForFilter } from '../../components/FiltersDateSelector';
import { business as businessActions } from '../../actions';
import { Utils, Language } from '../../utils';
import AddIcon from '@material-ui/icons/Add';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Policies } from '../../utils/Policies';
import { toISOString } from '../../utils/Utils';

const ItauScreen = ({ history }) => {
  const dispatch = useDispatch();
  const searchParams = qs.parse(history.location.search);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [ itauBranches, setItauBranches ] = useState([]);
  const [ sales, setSales ] = useState({});
  const [ selectedBranch, setSelectedBranch ] = useState(searchParams.bid || 'all');
  const [selectedFilter, setSelectedFilter] = useState(searchParams.fid ? searchParams.fid === 'custom' ? searchParams.fid : parseInt(searchParams.fid) : 3);
  const [ selectedCustomFilter, setSelectedCustomFilter ] = useState(
    searchParams.fid === CUSTOM_FILTER ? { startDate: searchParams.fsd, endDate: searchParams.fed } : {}
  );
  const [ paginationPageSize ] = useState(10);
  const [ paginationPage, setPaginationPage ] = useState(searchParams.pid ? parseInt(searchParams.pid) : 1);
  const [ forbiddenSection, setForbbidenSection ] = useState(false);
  const prevPaginationPage = useRef(null);
  const { policies, languageTexts, branches } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    branches: state.business.branches || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(
    () => {
      if (!policies || !policies.includes(Policies.types.ITAU)) {
        setForbbidenSection(true);
      } else if (policies.includes(Policies.types.ITAU)) {
        businessActions.getItauBranches(_itauBranches => {
          const itauBranchesTraceIds = _itauBranches.map(b => b.traceId);
          const _branches = branches.filter(b => itauBranchesTraceIds.includes(b.traceId));
          if (_branches.length > 0) {
            setItauBranches(_branches);
          }
        });
      }
    },
    [ policies ]
  );

  useEffect(
    () => {
      if (!forbiddenSection) {
        if (paginationPage > 1 && prevPaginationPage.current === paginationPage) {
          _handleOnPaginationChange(1);
          prevPaginationPage.current = null;
        } else {
          loadData();
          prevPaginationPage.current = paginationPage;
        }
      }
    },
    [ forbiddenSection, selectedBranch, selectedFilter, selectedCustomFilter, itauBranches, paginationPage ]
  );

  const loadData = () => {
    if (itauBranches.length > 0) {
      const branch = selectedBranch !== 'all' ? itauBranches.find(b => b.branchId === parseInt(selectedBranch)) : null;
      const dateFilter = getDateRangeForFilter(
        selectedFilter,
        selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null
      );

      dispatch(
        businessActions.getItauSales(
          branch ? branch.traceId : null,
          toISOString(dateFilter.startDate),
          toISOString(dateFilter.endDate),
          paginationPage,
          sales => {
            setSales(sales);
          }
        )
      );
    }
  };

  const _handleOnBranchSelected = branch => {
    setSelectedBranch(branch);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.bid = branch;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnFilterSelected = (filter, startDate, endDate) => {
    setSelectedFilter(filter);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.fid = filter;

    if (filter === CUSTOM_FILTER) {
      setSelectedCustomFilter({ startDate, endDate });
      searchParams.fsd = startDate;
      searchParams.fed = endDate;
    }

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnPaginationChange = nextPage => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.pid = nextPage;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _goToNewItauSale = () => {
    dispatch(push(ROUTES.ITAU_SALE.path));
  };

  const renderBranchesSelector = () => (
    <BranchesSelector
      idValue="traceId"
      branches={itauBranches}
      selectedBranch={selectedBranch}
      onBranchSelected={_handleOnBranchSelected}
    />
  );

  const renderFiltersDateSelector = () => (
    <FiltersDateSelector
      selectedFilter={selectedFilter}
      onFilterSelected={_handleOnFilterSelected}
      align="right"
      dateFormat={i18n.get('DateFormat', false)}
    />
  );

  const renderOperationsTable = () => {
    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get('Itau.Grid.Column1'),
        sortable: false,
        type: 'date',
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get('Itau.Grid.Column2'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get('Itau.Grid.Column3'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 4,
        name: i18n.get('Itau.Grid.Column4'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 5,
        name: i18n.get('Itau.Grid.Column5'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 6,
        name: i18n.get('Itau.Grid.Column6'),
        sortable: false,
        type: 'number',
      },
    ];
    const body = (sales.elements || []).map(item => ({
      rowId: item.id,
      columns: [
        {
          columnNumber: 1,
          data: item.lastModificationDateTime,
        },
        {
          columnNumber: 2,
          data: Utils.formatLegalId(item.document),
        },
        {
          columnNumber: 3,
          data: i18n.get(`Itau.Grid.Status_${item.currentState}`),
        },
        {
          columnNumber: 4,
          data: item.maskedCard,
        },
        {
          columnNumber: 5,
          data: item.currency,
        },
        {
          columnNumber: 6,
          data: item.amount,
        },
      ],
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={(sales.elements || []).length === paginationPageSize}
        onPaginationChange={_handleOnPaginationChange}
        emptyMessage={i18n.get('Itau.GridEmptyMessage')}
        showDetails={false}
      />
    );
  };

  if (forbiddenSection) {
    return (
      <Page withHeader withSidebar withHeaderTitle={i18n.get('Itau.Title')} withActivePage={ROUTES.ITAU.id}>
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page withHeader withSidebar withHeaderTitle={i18n.get('Itau.Title')} withActivePage={ROUTES.ITAU.id}>
      <TitleBox
        title={i18n.get('Itau.BoxTitle')}
        buttonRight={policies.includes(Policies.types.ITAU_WRITE)}
        buttonRightText={isSmallScreen ? null : i18n.get('Itau.NewItauSaleButton')}
        buttonRightIcon={() => <AddIcon fontSize="small" />}
        buttonRightOnClick={_goToNewItauSale}
      />
      <BoxSeparator />
      <ContentBox title={i18n.get('Itau.BoxFiltersTitle')} titleBold>
        <FiltersContainer>
          {renderBranchesSelector()}
          <FiltersSeparator />
          {renderFiltersDateSelector()}
        </FiltersContainer>
      </ContentBox>
      <BoxSeparator size="small" />
      <ContentBox title={i18n.get('Itau.GridTitle')} titleBold>
        {renderOperationsTable()}
      </ContentBox>
    </Page>
  );
};

ItauScreen.id = 'com.Handy.Itau';

export default ItauScreen;
