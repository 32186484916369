import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push, replace } from "connected-react-router";
import qs from "query-string";

import ROUTES from "../../../routes";
import {
  BoxMenu,
  BoxSeparator,
  Button,
  ContentBox,
  EmptyMessage,
  FiltersContainer,
  FiltersDateSelector,
  FiltersSelector,
  FiltersSeparator,
  ForbiddenSection,
  OperationsTable,
  Page,
  TitleBox,
} from "../../../components";
import { business as businessActions } from "../../../actions";
import { Language, Utils } from "../../../utils";

import ListItemText from "@material-ui/core/ListItemText";
import { Visibility as VisisbilityIcon } from "@material-ui/icons";
import MoreVerticalIcon from "../../../static/images/icons/more_vertical.svg";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

import makeClasses from "./styles";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Policies } from "../../../utils/Policies";
import {
  CUSTOM_FILTER,
  getDateRangeForFilter,
} from "../../../components/FiltersDateSelector";
import { toISOString } from "../../../utils/Utils";
import moment from "moment";
import { toast } from "react-toastify";

const STATUS_FILTER_OPTIONS = [
  { id: "APPROVED", name: "Statuses.APPROVED" },
  { id: "FAILED", name: "Statuses.FAILED" },
  { id: "CANCELLED", name: "Statuses.CANCELLED" },
];

const CURRENCY_FILTER_OPTIONS = [
  { id: "URUGUAYAN_PESO", name: "Currency.UYU" },
  { id: "DOLLAR", name: "Currency.USD" },
];

const ProvidersSubscriptionsManageTransactionsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const searchParams = qs.parse(history.location.search);

  const [transactions, setTransactions] = useState({});

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(-1);
  const [paginationPage, setPaginationPage] = useState(
    searchParams.page ? parseInt(searchParams.page) : 1
  );
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(
    searchParams.tid ? searchParams.tid : "all"
  );
  const [selectedFilter, setSelectedFilter] = useState(searchParams.fid ? searchParams.fid === 'custom' ? searchParams.fid : parseInt(searchParams.fid) : 0);
  const [selectedCustomFilter, setSelectedCustomFilter] = useState(
    searchParams.fid === CUSTOM_FILTER
      ? { startDate: searchParams.fsd, endDate: searchParams.fed }
      : {}
  );
  const [subscribersOptions, setSubscriptionsOptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(
    searchParams.sid || "all"
  );
  const [filtersLoaded, setFiltersLoaded] = useState(false);

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const hasTransactions =
    transactions && transactions.elements && transactions.elements.length > 0;
  const canExport = policies.includes(Policies.types.SUPER_READ);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (policies && policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS)) {
      loadFiltersData();
      loadTransactions();
    } else {
      setForbbidenSection(true);
    }
  }, [
    policies,
    paginationPage,
    selectedStatus,
    selectedSubscription,
    selectedFilter,
  ]);

  const loadFiltersData = () => {
    if (filtersLoaded) {
      return;
    }

    dispatch(
      businessActions.getProvidersSubscriptionsSubscriptions(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        (data) => {
          if (data) {
            const options = data.elements
              ? data.elements
                  .filter((e) => e)
                  .map((e) => ({
                    id: e.subscriptionTraceId,
                    name: `${e.subscriber?.document} - ${e.subscriber?.firstName} ${e.subscriber?.lastName}`,
                  }))
              : [];
            setSubscriptionsOptions(options);
          }
        }
      )
    );

    setFiltersLoaded(true);
  };

  const loadTransactions = () => {
    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null,
      true
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.getProvidersSubscriptionsTransactions(
        null,
        selectedSubscription !== "all" ? selectedSubscription : null,
        selectedStatus !== "all" ? selectedStatus : null,
        startDate,
        endDate,
        paginationPage,
        15,
        (data) => {
          setTransactions(data);
        }
      )
    );
  };

  const toggleMenu = (index) => (e) => {
    if (menuAnchorEl) {
      setMenuAnchorEl(null);
      setMenuOpenIndex(-1);
    } else {
      setMenuAnchorEl(e.target);
      setMenuOpenIndex(index);
    }
  };

  const handleOnViewTransaction = (transaction) => () => {
    toggleMenu()();
    dispatch(
      push(
        ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS_VIEW.path.replace(
          ":traceId",
          transaction.transactionTraceId
        )
      )
    );
  };

  const handleOnPaginationChange = (nextPage) => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnFilterSelected = (filter, startDate, endDate) => {
    setSelectedFilter(filter);
    handleOnPaginationChange(1)

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.fid = filter;

    if (filter === CUSTOM_FILTER) {
      setSelectedCustomFilter({ startDate, endDate });
      searchParams.fsd = startDate;
      searchParams.fed = endDate;
    }

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const renderTransactions = () => {
    if (
      !transactions ||
      !transactions.elements ||
      transactions.elements.length === 0
    ) {
      return (
        <EmptyMessage textOnly>
          {i18n.get(
            "ProvidersSubscriptionsManageTransactions.GridEmptyMessage"
          )}
        </EmptyMessage>
      );
    }

    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get(
          "ProvidersSubscriptionsManageTransactions.TransactionsGrid.Column1"
        ),
        sortable: false,
        type: "date",
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get(
          "ProvidersSubscriptionsManageTransactions.TransactionsGrid.Column2"
        ),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get(
          "ProvidersSubscriptionsManageTransactions.TransactionsGrid.Column3"
        ),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 4,
        name: i18n.get(
          "ProvidersSubscriptionsManageTransactions.TransactionsGrid.Column4"
        ),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 5,
        name: i18n.get(
          "ProvidersSubscriptionsManageTransactions.TransactionsGrid.Column5"
        ),
        sortable: false,
        type: "status",
      },
      {
        canHide: false,
        columnNumber: 6,
        name: i18n.get(
          "ProvidersSubscriptionsManageTransactions.TransactionsGrid.Column6"
        ),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 7,
        name: i18n.get(
          "ProvidersSubscriptionsManageTransactions.TransactionsGrid.Column7"
        ),
        sortable: false,
        type: "custom",
        show: !isSm,
        align: 'right'
      },
    ];

    const body = (transactions.elements || []).map((item, index) => ({
      rowId: item.traceId,
      columns: [
        {
          columnNumber: 1,
          data: item.transactionDate,
        },
        {
          columnNumber: 2,
          data: item.subscriptionPlan?.code ? `${item.subscriptionPlan?.name} - ${item.subscriptionPlan?.code}` : item.subscriptionPlan?.name,
        },
        {
          columnNumber: 3,
          data: `${item.subscriber?.document} - ${item.subscriber?.firstName} ${item.subscriber?.lastName}`.trim(),
        },
        {
          columnNumber: 4,
          data: item.instrument?.maskedPan,
        },
        {
          columnNumber: 5,
          data: item.transactionStatus,
        },
        {
          columnNumber: 6,
          data: `${i18n.get(
            CURRENCY_FILTER_OPTIONS.find((c) => c.id === item.currency)?.name ||
              ""
          )} ${Utils.formatCurrency(item.amount)}`,
        },
        {
          columnNumber: 7,
          data: () => {
            return (
              <div className={classes.listItemActions}>
                <IconButton onClick={toggleMenu(index)}>
                  <img src={MoreVerticalIcon} alt="More" />
                </IconButton>
                <Menu
                  anchorEl={menuAnchorEl}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  onClose={toggleMenu()}
                  open={menuOpenIndex === index}
                  className={classes.listItemMenu}
                >
                  <MenuItem
                    onClick={handleOnViewTransaction(item)}
                    className={classes.listItemMenuItem}
                  >
                    <ListItemIcon className={classes.menuItemIcon}>
                      <VisisbilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.get(
                        "ProvidersSubscriptionsManageTransactions.ActionViewDetails"
                      )}
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                </Menu>
              </div>
            );
          },
        },
      ],
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={transactions.hasNext}
        onPaginationChange={handleOnPaginationChange}
        emptyMessage={i18n.get(
          "ProvidersSubscriptionsManageTransactions.GridEmptyMessage"
        )}
        showDetails={false}
        showDates={false}
      />
    );
  };

  const handleOnExport = () => {
    if (selectedFilter === 0) {
      toast.error(i18n.get('ProvidersSubscriptionsManageTransactions.ExportErrorNoDateFilterSelected'));
      return;
    }

    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null,
      true
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.exportProvidersSubscriptionsTransactions(
        selectedSubscription !== "all" ? selectedSubscription : null,
        selectedStatus !== "all" ? selectedStatus : null,
        startDate,
        endDate,
        paginationPage,
        15,
        (file) =>
          Utils.downloadFile(
            file,
            "Cobranzas_Suscripciones_Transactiones_" +
              moment().format(i18n.get("ExportDateFormat")) +
              ".xlsx"
          )
      )
    );
  };

  const handleOnStatusSelected = (status) => {
    setSelectedStatus(status);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.tid = status;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnSubscriptionSelected = (subscriber) => {
    setSelectedSubscription(subscriber);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.sid = subscriber;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleOnMenuItemClick = (item) => {
    dispatch(push(item.path));
  };

  const renderSubscriptionsSelector = () => {
    return (
      <FiltersSelector
        options={subscribersOptions}
        selected={selectedSubscription}
        onSelected={handleOnSubscriptionSelected}
        defaultOption={i18n.get(
          "ProvidersSubscriptionsManageTransactions.FilterSubscriptionsDefaultOption"
        )}
      />
    );
  };

  const renderStatusesSelector = () => {
    return (
      <FiltersSelector
        options={STATUS_FILTER_OPTIONS.map((s) => ({
          ...s,
          name: i18n.get(s.name),
        }))}
        selected={selectedStatus}
        onSelected={handleOnStatusSelected}
        defaultOption={i18n.get(
          "ProvidersSubscriptionsManageTransactions.FilterStatusesDefaultOption"
        )}
      />
    );
  };

  const renderFiltersDateSelector = () => (
    <FiltersDateSelector
      selectedFilter={selectedFilter}
      startDate={selectedCustomFilter.startDate}
      endDate={selectedCustomFilter.endDate}
      onFilterSelected={handleOnFilterSelected}
      align="right"
      dateFormat={i18n.get("DateFormat", false)}
      showNoFilterOption
    />
  );

  const renderExportButton = () => {
    if (!canExport) {
      return null;
    }
    return (
      <Button
        rightIcon={() => <SaveAltIcon fontSize="small" />}
        onClick={handleOnExport}
        disabled={
          !transactions ||
          !transactions.elements ||
          transactions.elements.length === 0
        }
      >
        {isSm
          ? null
          : i18n.get("ProvidersSubscriptionsManageTransactions.ExportButton")}
      </Button>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get(
          "ProvidersSubscriptionsManageTransactions.Title"
        )}
        withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get(
        "ProvidersSubscriptionsManageTransactions.Title"
      )}
      withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
    >
      <BoxMenu
        items={[
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PLANS,
            label: i18n.get(
              "ProvidersSubscriptionsManageTransactions.BoxMenu.Item1"
            ),
          },
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS,
            label: i18n.get(
              "ProvidersSubscriptionsManageTransactions.BoxMenu.Item3"
            ),
          },
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS,
            label: i18n.get(
              "ProvidersSubscriptionsManageTransactions.BoxMenu.Item2"
            ),
          },
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS,
            label: i18n.get(
              "ProvidersSubscriptionsManageTransactions.BoxMenu.Item4"
            ),
          },
          {
            ...ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS,
            label: i18n.get(
              "ProvidersSubscriptionsManageTransactions.BoxMenu.Item5"
            ),
          },
        ]}
        selectedItem={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS.id}
        onClickItem={handleOnMenuItemClick}
      />
      <TitleBox
        title={i18n.get("ProvidersSubscriptionsManageTransactions.BoxTitle")}
      />
      <BoxSeparator size="small" />
      <ContentBox
        title={i18n.get(
          "ProvidersSubscriptionsManageTransactions.BoxFiltersTitle"
        )}
      >
        <FiltersContainer>
          {renderSubscriptionsSelector()}
          <FiltersSeparator />
          {renderStatusesSelector()}
          <FiltersSeparator />
          {renderFiltersDateSelector()}
        </FiltersContainer>
      </ContentBox>
      <BoxSeparator size="small" />
      <ContentBox
        title={i18n.get(
          "ProvidersSubscriptionsManageTransactions.TransactionsGridTitle"
        )}
        titleBold
        customRightAction={renderExportButton}
      >
        {hasTransactions ? (
          renderTransactions()
        ) : (
          <div className={classes.noDataMessage}>
            {i18n.get(
              "ProvidersSubscriptionsManageTransactions.NoTransactionsMessage"
            )}
          </div>
        )}
      </ContentBox>
    </Page>
  );
};

ProvidersSubscriptionsManageTransactionsScreen.id =
  "com.Handy.ProvidersSubscriptionsManageTransactions";

export default ProvidersSubscriptionsManageTransactionsScreen;
