import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(3),
    background: Colors.rgb.notification_background,
    boxSizing: 'border-box',
    color: Colors.rgb.white,
    fontSize: theme.fontSize.M,
    marginBottom: theme.spacing(2),
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.8
    },

    '& > div': {
      marginTop: -3,
    },
  },
  contentContainer: {
    '& p': {
      '&:first-child': {
        marginTop: 0,
        marginBottom: 0
      },
      '&:last-child': {
        marginTop: theme.spacing(1),
        marginBottom: 0
      }
    },
  },
  contentTitle: {
    '& *': {
      fontSize: theme.fontSize.XL,
      fontFamily: theme.fontFamily.bold,
      color: Colors.rgb.contentTextHighlighted,

      '&:not(ul):not(ol)': {
        margin: 0,
        padding: 0
      }
    }
  },
  contentSummary: {
    '& *': {
      fontSize: theme.fontSize.M,
      color: Colors.rgb.contentText,

      '&:not(ul):not(ol)': {
        margin: 0,
        padding: 0
      }
    }
  },
  rootInfo: {
    background: '#cce5ff',
    border: '1px solid #B8DAFF',
    color: '#004185',
  },
  rootWarning: {
    background: '#FFF3CD',
    border: '1px solid #FFEEBA',
    color: '#856404',
  },
  rootSuccess: {
    background: '#D4EDDA',
    border: '1px solid #C3E6CB',
    color: '#155724',
  },
  rootError: {
    background: '#F8D7DA',
    border: '1px solid #F5C6CB',
    color: '#721C24',
  },
  icon: {
    alignSelf: 'flex-start',
    fontSize: '3rem',
    marginRight: 10,
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(4),
    right: 7,
    cursor: 'pointer',
    zIndex: 10,
    color: '#fff',

    '&:hover': {
      opacity: 0.9,
    },
  },
  closeIcon: {
    fontSize: '1rem',
  },
}));

export default makeClasses;
